import { HomeOutlined } from '@ant-design/icons'

import type { LauncherCommandV2 } from './useLauncherCommands'

export const useMoveCommands = ({ redirect }: { redirect: (path: string) => void }): LauncherCommandV2[] => {
  const commands: Array<LauncherCommandV2 | undefined> = [
    {
      type: `page` as const,
      icon: <HomeOutlined />,
      title: 'ホーム画面',
      searchKeyword: 'home',
      shortcut: ['command', 'h'],
      method: () => {
        redirect(``)
      },
    },
  ]

  return commands.compact()
}

import { flatNodes } from './flatNodes'

export function replace<T extends { name: string; children?: T[] }>(
  tree: T,
  targetNodeName: string,
  f: (oldNode: T) => void,
) {
  const newTree = structuredClone(tree)
  const flattenNodes = flatNodes(newTree)
  const targetNode = flattenNodes.find((x) => x.name === targetNodeName)
  if (targetNode === undefined) {
    return
  }

  f(targetNode) // 破壊的操作を行う
  return newTree
}

//
// やや手間にはなるが、全てのイベントをここで定義することで、どんなイベントを定義しているかを一覧できるようにする
// また、極力イベント名の命名規則が統一されるようにする（統一することに大きな意味はない）
//
export const LoggerEvents = {
  // TODO
  // external
  APOLLO_CLIENT: 'APOLLO_CLIENT', // 全てのHTTPリクエスト・レスポンス、エラーログ、リトライ情報をログに流している

  // 汎用
  VISIT: 'VISIT',

  // sync
  SHEET_COPY: 'SHEET_COPY',
  SHEET_PASTE: 'SHEET_PASTE',

  // duration計測
  RENDER_DURATION: 'RENDER_DURATION',

  // 汎用
  WARN: 'WARN',
  ERROR: 'ERROR',
} as const

export type LoggerEvent = keyof typeof LoggerEvents

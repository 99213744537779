import type { CoreModel } from '../../schemas/model/model'

export const SALESCORE_GOAL_MODEL: CoreModel = {
  icon: 'faUser',
  name: 'salescore_goals_v2',
  label: '目標',
  write: {
    sourceEntityName: 'salescore_goals_v2',
  },
  properties: [
    {
      name: 'goal_config_id',
      type: 'string',
      label: '目標種別ID',
    },
    {
      name: 'date',
      type: 'date',
      label: '日付',
    },
    {
      name: 'kpi_id',
      type: 'string',
      label: 'KPIのID',
    },
    {
      name: 'user_id',
      type: 'string',
      label: 'ユーザーのID',
    },
    {
      name: 'dimension1',
      type: 'string',
      label: '目標軸1',
    },
    {
      name: 'dimension2',
      type: 'string',
      label: '目標軸2',
    },
    {
      name: 'dimension3',
      type: 'string',
      label: '目標軸3',
    },
    {
      name: 'dimension4',
      type: 'string',
      label: '目標軸4',
    },
    {
      name: 'dimension5',
      type: 'string',
      label: '目標軸5',
    },
    {
      name: 'value',
      type: 'numeric',
      label: '目標',
    },
    {
      name: 'created_at',
      type: 'datetime',
      label: '作成時刻',
    },
    {
      name: 'updated_at',
      type: 'datetime',
      label: '更新時刻',
    },
  ],
}

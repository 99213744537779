import { range } from '../../util/util'
import type { AggregateFunction, Expression, Field, GroupBy } from '../parser/grammer_schema'
import { expression, expressionFieldName } from './expression'
import type { BaseTableRecord, ExpressionContext, ResultRecord, TemporaryTable } from './types'

interface RecordsGroup {
  keys: unknown[]
  records: BaseTableRecord[]
}

export function aggregate(groupBy: GroupBy | null, fields: Field[], temporaryTable: TemporaryTable): ResultRecord[] {
  //
  // まず、レコードをグルーピングする
  //
  const initialRecordsGroups: RecordsGroup[] = [
    {
      keys: [],
      records: temporaryTable.records,
    },
  ]
  const recordsGroups = (groupBy?.groups ?? []).reduce((recordsGroups, group): RecordsGroup[] => {
    const { type } = group
    switch (type) {
      case 'expressionGroup': {
        return groupByExpression(recordsGroups, group.expression, { fields })
      }
      case 'rollupGroup': {
        // グルーピングに使う要素を順番に増やしていく
        const xs = range(0, group.expressionGroups.length).map((index) => {
          const nulls = range(0, group.expressionGroups.length - index - 1).map((_) => null)
          return group.expressionGroups
            .slice(0, index)
            .reduce(
              (rs, rollupGroup) =>
                groupByExpression(rs, rollupGroup.expression, {
                  fields,
                }),
              recordsGroups,
            )
            .map((y) => ({
              ...y,
              keys: [...y.keys, ...nulls],
            }))
        })
        return xs.flat()
      }
      // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
      default: {
        throw new Error(type satisfies never)
      }
    }
  }, initialRecordsGroups)

  //
  // それぞれのグループに対して、集約関数を適用
  //
  const flatGroups = (groupBy?.groups ?? []).flatMap((x) => (x.type === 'expressionGroup' ? [x] : x.expressionGroups))
  return recordsGroups.map((recordsGroup): ResultRecord => {
    const resultRecord = fields.map((field, fieldIndex) => {
      if (field.expression.type !== 'aggregateFunction') {
        const keyIndex = flatGroups.findIndex((group) => isSameExpression(group.expression, field))
        const fieldName = field.as ?? expressionFieldName(field.expression)
        if (keyIndex === -1) {
          if (field.expression.type === 'literal') {
            // literalのときのみ、keyに入っていなくてもOK
            return [fieldName, expression(field.expression, {}, { fields: [] })] as [string, unknown]
          }
          throw new Error(
            `column "${fieldIndex}" must appear in the GROUP BY clause or be used in an aggregate function`,
          ) // TODO
        }
        const value = recordsGroup.keys[keyIndex]
        // XXX: 我々の用途に合わせた非常にadhocな実装で、GROUPINGのケースに対応している
        if (value === null && (field.expression.type as unknown) === 'case') {
          const isGrouping = JSON.stringify(field.expression).toLowerCase().includes(`grouping`)
          if (isGrouping) {
            return [fieldName, `_TOTAL_`] as [string, unknown]
          }
        }
        if (value === undefined) {
          throw new Error(`key must not be undefined`)
        }
        return [fieldName, value] as [string, unknown]
      }
      const value = aggregateFunction(field.expression, recordsGroup, {
        fields,
      })

      return [field.as ?? field.expression.name, value] as [string, unknown]
    })

    const res = resultRecord.toObject(([key, value]) => [key, value])
    return res
  })
}

function isSameExpression(groupExpression: Expression, field: Field) {
  // fieldでaliasを定義しており、そのaliasをgroup byで使っているケース
  if (groupExpression.type === 'columnReference' && field.as === groupExpression.columnName) {
    return true
  }
  // TODO
  return JSON.stringify(groupExpression) === JSON.stringify(field.expression)
}

function groupByExpression(recordsGroups: RecordsGroup[], exp: Expression, context: ExpressionContext) {
  return recordsGroups.flatMap((recordsGroup): RecordsGroup[] => {
    const x = recordsGroup.records.groupBy((record) => {
      const key = expression(exp, record, context)
      if (typeof key !== 'string' && typeof key !== 'number' && key !== null) {
        throw new Error(`key must be string or number. typeof key: ${typeof key}`) // TODO
      }
      return key ?? `__NULL__` // nullのとき、値はnullになる
    })
    return x.map(
      (key, newRecordsGroup): RecordsGroup => ({
        keys: [...recordsGroup.keys, key],
        records: newRecordsGroup,
      }),
    )
  })
}

function aggregateFunction(f: AggregateFunction, recordsGroup: RecordsGroup, context: ExpressionContext) {
  const values = recordsGroup.records.map((x) => expression(f.expression, x, context))
  switch (f.name.toLowerCase()) {
    case 'count': {
      return values.length
    }
    case 'sum': {
      // TODO: validation
      return values.sum()
    }
  }
}

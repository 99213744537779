import { z } from 'zod'

import { viewUiComponentTypeSchema } from './enum'
import { kpiAppearanceSchema, kpiSchema } from './kpi'

export const kpiPivotSchema = kpiSchema.omit({ kpi: true }).merge(
  z.object({
    type: z.literal(viewUiComponentTypeSchema.enum.KpiPivotSheet),
    kpis: kpiAppearanceSchema.array(),
  }),
)
export type ViewUiKpiPivot = z.infer<typeof kpiPivotSchema>

import { z } from 'zod'

import { selectOptionSchema } from '../query'
import { viewUiComponentTypeSchema } from './enum'

//
// 通常のデータ入力コンポーネント(Data Entry)
//
export const dataEntryCommonSchema = z.object({
  fieldName: z.string(),
  readonly: z.boolean().optional(),
  required: z.boolean().optional(),
})
export type DataEntryCommon = z.infer<typeof dataEntryCommonSchema>

const inputSchema = dataEntryCommonSchema.merge(
  z.object({
    type: z.literal(viewUiComponentTypeSchema.enum.Input),
    textarea: z.boolean().optional(),
  }),
)
export type ViewUIInput = z.infer<typeof inputSchema>

export type ViewUISelectOption = z.infer<typeof selectOptionSchema>

const selectSchema = dataEntryCommonSchema.merge(
  z.object({
    type: z.literal(viewUiComponentTypeSchema.enum.Select),
    options: selectOptionSchema.omit({ rule: true }).array(),
    multiple: z.boolean().optional(),
  }),
)
export type ViewUISelect = z.infer<typeof selectSchema>

const searchSelectSchema = dataEntryCommonSchema.merge(
  z.object({
    type: z.literal(viewUiComponentTypeSchema.enum.SearchSelect),
    labelFieldName: z.string().optional(),
    searchSql: z.string().optional(),
  }),
)
export type ViewUISearchSelect = z.infer<typeof searchSelectSchema>

const checkboxSchema = dataEntryCommonSchema.merge(
  z.object({
    type: z.literal(viewUiComponentTypeSchema.enum.Checkbox),
  }),
)
export type ViewUICheckbox = z.infer<typeof checkboxSchema>

const inputNumberSchema = dataEntryCommonSchema.merge(
  z.object({
    type: z.literal(viewUiComponentTypeSchema.enum.InputNumber),
  }),
)
export type ViewUIInputNumber = z.infer<typeof inputNumberSchema>

const datePickerSchema = dataEntryCommonSchema.merge(
  z.object({
    type: z.literal(viewUiComponentTypeSchema.enum.DatePicker),
    showTime: z.boolean().optional(),
    minuteStep: z.number().optional(),
  }),
)
export type ViewUIDatePicker = z.infer<typeof datePickerSchema>

export const dataEntrySchema = z.union([
  inputSchema,
  inputNumberSchema,
  checkboxSchema,
  datePickerSchema,
  selectSchema,
  searchSelectSchema,
])
export type ViewUIDataEntry = z.infer<typeof dataEntrySchema>

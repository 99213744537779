import type { Dayjs } from 'dayjs'

import { Quarter } from './quarter'

export function range(startAt: Dayjs, endAt: Dayjs, options?: { filterBy?: 'day' | 'week' | 'month' | 'year' }) {
  const filterBy = options?.filterBy ?? 'day'
  const days = []
  let current =
    filterBy === 'week'
      ? startAt.startOf(filterBy).add(1, 'day') // dayjsのstartOf('week')は日曜始まりだが、月曜始まりに統一する
      : startAt.startOf(filterBy)
  while (current.isBefore(endAt, filterBy)) {
    days.push(current)
    current = current.add(1, filterBy)
  }
  const lastDay =
    filterBy === 'week'
      ? endAt.startOf(filterBy).add(1, 'day') // dayjsのstartOf('week')は日曜始まりだが、月曜始まりに統一する
      : endAt.startOf(filterBy)
  days.push(lastDay)
  return days
}

export function quarterRange(startAt: string, endAt: string): string[] {
  const start = Quarter.buildFromString(startAt)
  const end = Quarter.buildFromString(endAt)
  if (start === undefined || end === undefined) {
    return []
  }

  let current = start
  const results: Quarter[] = []
  while (current.isBeforeOrEqual(end)) {
    results.push(current)
    current = current.next()
  }

  return results.map((x) => x.toString())
}

import { add } from './tree/add'
import { createTreesFromItems } from './tree/createTreesFromItems'
import { exec } from './tree/exec'
import { findNodeFromTrees } from './tree/findNodeFromTrees'
import { flatNodes } from './tree/flatNodes'
import { flatNodesWithParents } from './tree/flatNodesWithParents'
import { remove } from './tree/remove'
import { replace } from './tree/replace'

// オブジェクトにまとめた方が分かりやすい気がしてきたので、途中から方向転換した
export const treeUtil = {
  exec,
  remove,
  add,
  replace,
  flatNodesWithParents,
  flatNodes,
  createTreesFromItems,
  findNodeFromTrees,
}

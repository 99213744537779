import { castString, range } from '@salescore/buff-common'

import type { ViewQueryField, ViewQueryList, ViewQueryRecordNode } from '../../../schemas/query'
import type { ViewUiSheetColumn } from '../../../schemas/ui/ui'
import { flatNodes } from '../../query/executeViewQuery/util'
import { convertRecordNodeToSheetRow } from '../convertRecordNodeToSheetRow'

// そのままcsvを生成したいが、csv系のライブラリにこのレイヤーを依存させたくないので、単に配列を返す
export function generateSheetCsv(records: ViewQueryRecordNode[], columns: ViewUiSheetColumn[], query: ViewQueryList) {
  const columnWithFields = columns
    .map((column) => {
      const field = query.fields.find((x) => x.name === column.fieldName)
      if (field === undefined) {
        return // TODO: このケースの処理
      }
      return {
        column,
        field,
      }
    })
    .compact()

  const titleHeader = generateCsvHeader(columnWithFields.map((x) => x.column))
  const nodeMapper = flatNodes(query.tree).groupByUniqueKey((x) => x.name)
  const blockMaxDepth = columnWithFields.map((x) => x.field.nodePath.length).max() ?? 0
  const blockHeaders = range(0, blockMaxDepth - 1).map((depth) =>
    columnWithFields.flatMap((c) => {
      const nodeName = c.field.nodePath[depth] ?? ''
      return nodeMapper[nodeName]?.meta.label ?? ''
    }),
  )
  const headers = [...blockHeaders, titleHeader]
  const body = records.flatMap((record) => generateCsvBody(record, columnWithFields))

  return {
    header: headers[0],
    body: [...headers.slice(1), ...body],
  }
}

function generateCsvHeader(columns: ViewUiSheetColumn[]): string[] {
  return columns.map((column) => column.label)
}

function generateCsvBody(
  record: ViewQueryRecordNode,
  columns: Array<{ column: ViewUiSheetColumn; field: ViewQueryField }>,
): string[][] {
  const sheetRow = convertRecordNodeToSheetRow(
    record,
    columns.map((column) => ({
      nodePath: column.field.nodePath,
      fieldName: column.column.fieldName,
      selectOptions: column.column.selectOptions,
    })),
  )

  const values = sheetRow.columns.map((sheetRowColumn) => {
    const reversed = [...sheetRowColumn.innerCells].reverse() // このあとfindLast()をしたいだけだが、なぜかtsでエラーになるのでreverse()してからfind()している(tsconfigが悪い？)
    return range(record.meta.innerRowIndexStart, record.meta.innerRowIndexEnd - 1).map((index) => {
      const cell = reversed.find((cell) => cell.innerRowIndexStart <= index) // ===ではなく<=にすることで、なければ上の行をそのまま表示
      const value = cell?.label ?? cell?.value
      return castString(value) ?? ''
    })
  })
  // このvaluesは、行がcolumns、列がrowIndexとなっている
  // これを、行がrowIndex、列がcolumnsとなるように変換する
  return transpose(values)
}

function transpose<T>(values: T[][]): T[][] {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  return values[0]?.map((_, colIndex) => values.map((row) => row[colIndex] as T)) ?? []
}

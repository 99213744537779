import { isNull, isPresent, isTruthy } from '@salescore/buff-common'
import { CORE_CONSTANT, type ViewKpiAppearance } from '@salescore/core'
import type { ReactNode } from 'react'

import {
  calculateUnit,
  numberWithDelimiterFilter,
  numberWithFixedDecimal,
  unitLabel as getUnitLabel,
} from '../../misc/filters'
import { type ChartLabelDisplayModeValue, EChart, type EChartProperties } from './EChart'
import { generateTooltip } from './EChartStacked'

interface WaterfallEChartData {
  category: {
    name: string
    label: string
  }
  value: number
}

interface WaterfallDesignSettings {
  axisLabelDisplayMode?: ChartLabelDisplayModeValue
  valueLabelDisplayMode?: ChartLabelDisplayModeValue
  valueLabelUnitType?: ViewKpiAppearance['unitType']
  valueLabelDecimalPlaceType?: ViewKpiAppearance['decimalPlaceType']
}

interface GenerateWaterfallEChartOptionProperties {
  data: WaterfallEChartData[]
  shouldLabelRotate?: boolean
  designSettings?: WaterfallDesignSettings
}

function generateWaterfallEChartOption({
  options,
}: {
  options: GenerateWaterfallEChartOptionProperties
}): EChartProperties['option'] {
  const { data } = options

  // 滝グラフは透明なチャートと色付きのチャートの積み上げ棒グラフとして構成する

  const spacerData = data.reduce((spacerData: number[], current, index) => {
    if (
      current.category.name === CORE_CONSTANT.WATERFALL_FIRST_PERIOD_NODE_SUFFIX ||
      current.category.name === CORE_CONSTANT.WATERFALL_SECOND_PERIOD_NODE_SUFFIX
    ) {
      return [...spacerData, 0]
    }

    const previousData = data[index - 1]?.value ?? 0
    const previousSpacerData = spacerData[index - 1] ?? 0

    // 一つ前のデータが正の値か負の値かで、積み上げグラフの開始位置が異なる
    const baseLine = Math.max(previousSpacerData, previousSpacerData + previousData)
    const currentSpacerData = Math.min(baseLine, baseLine + current.value)
    return [...spacerData, currentSpacerData]
  }, [])

  const option: EChartProperties['option'] = {
    tooltip: {
      trigger: 'item',
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      show: options.designSettings?.axisLabelDisplayMode !== 'none',
      type: 'category',
      data: data.map((x) => x.category.label),
      axisLabel: {
        interval: 0,
        rotate: isTruthy(options.shouldLabelRotate) ? 45 : 0,
      },
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: 'spacerData',
        type: 'bar',
        stack: 'waterfall',
        itemStyle: {
          borderColor: 'transparent',
          color: 'transparent',
        },
        data: spacerData,
        cursor: 'default',
        tooltip: {
          show: false,
        },
      },
      {
        type: 'bar',
        stack: 'waterfall',
        itemStyle: {
          color: ({ dataIndex }) =>
            // eslint-disable-next-line security/detect-object-injection
            getColor(data[dataIndex] ?? { category: { name: '', label: '' }, value: 0 }),
        },
        tooltip: {
          formatter: ({ dataIndex }) => {
            const targetData = data[dataIndex]
            const displayValue = generateDisplayValue({ data, dataIndex, options })
            if (!isPresent(displayValue) || isNull(targetData)) {
              return ''
            }

            return generateTooltip({
              seriesName: '項目',
              name: targetData.category.label,
              displayValue,
              color: getColor(targetData),
            })
          },
        },
        label: {
          show: options.designSettings?.valueLabelDisplayMode !== 'none',
          position: 'top',
          formatter: ({ dataIndex }) => generateDisplayValue({ data, dataIndex, options }),
        },
        data: data.map((x) => ({
          key: x.category.name,
          // データは絶対値で描画する
          value: Math.abs(x.value),
        })),
      },
    ],
  }

  return option
}

export function EChartWaterfall(
  properties: {
    options: GenerateWaterfallEChartOptionProperties
  } & Partial<EChartProperties>,
): ReactNode {
  const { options } = properties
  const option = generateWaterfallEChartOption({ options })
  return (
    <EChart
      // optionsが変わっても再描画されないことがあったので、keyにoptionsを指定している
      key={JSON.stringify(options)}
      {...properties}
      title={'2期間比較'}
      option={option}
    />
  )
}

function generateDisplayValue({
  data,
  dataIndex,
  options,
}: {
  data: WaterfallEChartData[]
  dataIndex: number
  options: GenerateWaterfallEChartOptionProperties
}): string {
  // eslint-disable-next-line security/detect-object-injection
  const value = data[dataIndex]?.value ?? 0
  const defaultLabel = value >= 0 ? `${value}` : `-${value}`

  const { designSettings } = options
  if (isNull(designSettings)) {
    return defaultLabel
  }

  const { valueLabelUnitType, valueLabelDecimalPlaceType } = designSettings

  // eslint-disable-next-line unicorn/no-useless-undefined
  const valueConvertedInUnit = calculateUnit(value, valueLabelUnitType, undefined)
  const valueRoundedToDecimal = numberWithFixedDecimal(
    valueConvertedInUnit,
    valueLabelDecimalPlaceType,
    // eslint-disable-next-line unicorn/no-useless-undefined
    undefined,
  )
  // eslint-disable-next-line unicorn/no-useless-undefined
  const unitLabel = getUnitLabel(value, valueLabelUnitType, undefined)
  const displayUnitLabel = isTruthy(unitLabel) ? ` ${unitLabel}` : ''
  const valueWithUnit = `${numberWithDelimiterFilter(valueRoundedToDecimal)}${displayUnitLabel}`

  return valueWithUnit
}

function getColor(data: WaterfallEChartData): string {
  if (
    data.category.name === CORE_CONSTANT.WATERFALL_FIRST_PERIOD_NODE_SUFFIX ||
    data.category.name === CORE_CONSTANT.WATERFALL_SECOND_PERIOD_NODE_SUFFIX
  ) {
    return '#d1d5db'
  }
  return data.value >= 0 ? '#2563eb' : '#dc2626'
}

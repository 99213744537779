import { EditOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Form, type FormInstance, message, Modal, Row, Table } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { t } from 'i18next'
import { useState } from 'react'

import { useBooleanState } from '../../hooks/useBooleanState'
import { useModal } from '../../hooks/useModal'
import { SqlSyntaxHighlighter } from '../SqlSyntaxHighlighter'
import { SqlEditor } from './SqlEditor'

interface Result {
  rows: Array<Record<string, string>>
  fields: string[]
}

export function SqlEditorWithExecuteModal({
  sql,
  onFinish,
  onExecute,
}: {
  sql: string
  onFinish: (sql: string) => void
  onExecute: (sql: string) => Promise<Result>
}) {
  const [formInstance] = useForm<{ sql: string }>()
  const modal = useModal()

  return (
    <>
      <div>
        <SqlSyntaxHighlighter sql={sql} onClick={modal.showModal} />
        <div className="flex w-full flex-row-reverse">
          <Button type="text" icon={<EditOutlined />} className="text-blue-500" onClick={modal.showModal}>
            {t(`SQLを編集`)}
          </Button>
        </div>
      </div>
      <Modal
        width={'90%'}
        open={modal.isModalVisible}
        destroyOnClose
        onOk={() => {
          formInstance.submit()
          modal.hideModal()
        }}
        onCancel={modal.hideModal}
      >
        <Form<{ sql: string }>
          form={formInstance}
          initialValues={{ sql }}
          onFinish={(value) => {
            onFinish(value.sql)
          }}
        >
          <ModalContent formInstance={formInstance} onExecute={onExecute} />
        </Form>
      </Modal>
    </>
  )
}

function ModalContent({
  formInstance,
  onExecute,
}: {
  formInstance: FormInstance<{ sql: string }>
  onExecute: (sql: string) => Promise<Result>
}) {
  const [results, setResults] = useState<Result>({ rows: [], fields: [] })
  const loading = useBooleanState()
  const execute = async (sql: string) => {
    try {
      loading.setTrue()
      const xs = await onExecute(sql)
      setResults(xs)
    } catch (error) {
      if (error instanceof Error) {
        void message.error(`${t(`エラーが発生しました。`)}${error.message}`)
      } else {
        void message.error(t(`エラーが発生しました。`))
      }
    } finally {
      loading.setFalse()
    }
  }

  return (
    <Row>
      <Col span={12}>
        <div>
          <SqlEditor
            onExecute={async () => {
              const values = formInstance.getFieldsValue()
              const sql = values.sql
              await execute(sql)
            }}
          />
        </div>
        <Divider />
      </Col>
      <Col span={12}>
        <div className="overflow-scroll">
          <Table
            title={() => <div>{t(`実行結果`)}</div>}
            loading={loading.isTrue}
            dataSource={results.rows}
            columns={results.fields.map((field, index) => ({
              title: field,
              dataIndex: index,
              // ...getColumnSearchProps((record: Record<string, string>): string => {
              //   const value = record[i]
              //   if (isNull(value)) {
              //     return ''
              //   }
              //   if (typeof value === 'object') {
              //     return JSON.stringify(value)
              //   }
              //   return `${value }`
              // }),
              // sorter: compareFunction,
              // render(value, record) {
              //   return (
              //     <span>
              //       {typeof value === 'string'
              //         ? value
              //         : typeof value === 'number'
              //           ? `${value}`
              //           : JSON.stringify(value)}
              //     </span>
              //   )
              // },
            }))}
          />
        </div>
      </Col>
    </Row>
  )
}

import { DownloadOutlined } from '@ant-design/icons'
import { Button, message } from 'antd'
import { t } from 'i18next'

import { useBooleanState } from '../hooks/useBooleanState'

export interface CsvDownloadButtonArgument {
  fetch: () => Promise<string>
  filename: string
  text?: string
}

const csvDownload = function ({ text, filename }: { text: string; filename: string }) {
  const bom = new Uint8Array([0xef, 0xbb, 0xbf])
  const downloadLink = document.createElement('a')
  downloadLink.download = filename + '.csv'
  downloadLink.href = URL.createObjectURL(new Blob([bom, text], { type: 'text/csv' }))
  downloadLink.dataset.downloadurl = ['text/csv', downloadLink.download, downloadLink.href].join(':')
  downloadLink.click()
}

export const CsvDownloadWithTextButton = ({
  fetch,
  filename,
  text,
  disabled,
  type,
  onClick,
}: CsvDownloadButtonArgument & {
  disabled?: boolean
  type?: 'text' | 'default'
  onClick?: () => void
}) => {
  const loading = useBooleanState()
  return (
    <Button
      type={type}
      icon={<DownloadOutlined />}
      onClick={async () => {
        loading.setTrue()
        try {
          const text = await fetch()
          csvDownload({ text, filename })
        } catch {
          void message.error(t(`エラーが発生しました。`))
        } finally {
          loading.setFalse()
        }
        if (onClick === undefined) {
          return
        }
        onClick()
      }}
      loading={loading.isTrue}
      disabled={disabled}
    >
      {text}
    </Button>
  )
}

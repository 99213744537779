import { z } from 'zod'

import type { ExpressionNodeWithDeprecated } from '../../dsl/syntax/expression_ast'
import { fieldMetaSchema, fieldTypeSchema, nodePathSchema, propertyNameWithStreamNameSchema } from './common'

export const conditionalEffectSchema = z.object({
  // XXX: anyではなくunknownにしたいが、recoil-apolloでselectorFamilyを使う関係で、anyの必要がある（unknownだとSerializableParamにひっかかる）
  condition: z.any(), // ExpressionNode。expression_ast.tsを参照。本当はzodにしたいが、循環参照の関係でzodを使えなかった。
  effect: z.enum(['disable', `emphasis`, 'highlight']),
  highlightColor: z.string().optional(), // ハイライトのときのみ使用
})
export type ConditionalEffect = Omit<z.infer<typeof conditionalEffectSchema>, 'condition'> & {
  condition?: ExpressionNodeWithDeprecated // schema側をanyにしたせいで、optionalにするしかない
}

const updateFieldCallbackSchema = z.object({
  type: z.literal('updateFieldCallback'),
  sql: z.string(),
})
const fieldCallbackSchema = updateFieldCallbackSchema
// const fieldCallbackSchema = z.union([updateFieldCallbackSchema])
// export type FieldCallback = z.infer<typeof fieldCallbackSchema>

//
// main
//
export const viewQueryFieldSchema = z.object({
  // 木構造
  name: z.string(), // 親nodeに対してuniqueな葉の名前。SQLのasで使用。(SQL発行に関するフィールドはreadにまとめているが、これだけ例外?) TODO
  nodePath: nodePathSchema, // どのノードに所属するか。ViewQueryFieldは実質nodeに対するleafだが、取り扱う際にツリー構造よりも配列の方が特にフロント側での取り扱いが楽なため、このように別で分けてサーバー側でSQL発行するときのみnodePathを基にツリー構造を組み立てている

  read: z.object({
    sql: z.string(),
    labelSql: z.string().optional(), // 参照項目など、表示と値を分けたい場合に指定
    labelNodePath: nodePathSchema.optional(),
    searchSql: z.string().optional(), // 参照項目など、入力の際に都度APIリクエストをして検索する場合のSQL。value,labelの2つのフィールドが必須。
    additionalFields: z
      .object({
        // fieldに関する操作のために必要な他の値。選択肢の動的選択を実現するために追加。nameが被っても良い（被っている＝sqlが同じだということを期待）
        sql: z.string(),
        name: z.string(),
        dependedPropertyNamesWithStreamName: propertyNameWithStreamNameSchema,
      })
      .array()
      .optional(),
    distinct: z.boolean().optional(), // TODO: ここで定義するので良いだろうか？
  }),

  // writeToSourceのインターフェースの都合で、property.nameではなくproperty.source.nameを指定する
  write: z
    .object({
      streamName: z.string(),
      propertySourceName: z.string(),
    })
    .optional(),

  // @deprecated component側で指定してください
  meta: z.object({
    // meta(UI表示用)
    label: z.string(),
    fieldType: fieldTypeSchema,
    fieldMetaType: fieldMetaSchema.optional(),
    creatable: z.boolean().optional(), // デフォルトでtrue。ただしwriteがない場合は書き込めない
    updatable: z.boolean().optional(), // デフォルトでtrue。ただしwriteがない場合は書き込めない
    deletable: z.boolean().optional(), // デフォルトでtrue。
    required: z.boolean().optional(), // TODO: validationとしてひとまとめにするか検討
    defaultValue: z.union([z.string(), z.number(), z.boolean()]).optional(), // レコードの新規作成時のデフォルト
    searchable: z.boolean().optional(),

    // TODO: 完全にUI側の設定なので、component側に移行
    conditionalEffects: conditionalEffectSchema.array().optional(),
    callbacks: fieldCallbackSchema.array().optional(),

    // propertyとの依存関係。ほぼ使っていないが、UI側での参照関係変更周りで、modelNameとpropertyNameを使って参照判定をすることがある。
    // このロジックを改修し、このプロパティは削除したい。
    dependedPropertyNamesWithStreamName: propertyNameWithStreamNameSchema.array(),
  }),
})
export type ViewQueryField = z.infer<typeof viewQueryFieldSchema>

import type { MRecord } from '../util/record'
import { defineArrayProperties } from './array'
import { defineMapProperties } from './map'
import { defineProperty } from './util'

let isInitialized = false

export function extendProperties() {
  if (isInitialized) {
    return
  }
  if (isAlreadyExtended()) {
    return
  }
  defineArrayProperties()
  defineMapProperties()
  isInitialized = true
}

// isInitializedのロジックだけで問題ないはずだったが、
// モノレポ移行後から動作しなくなってしまったので以下のロジックでもチェックする
function isAlreadyExtended(): boolean {
  try {
    defineProperty(
      Array.prototype,
      '_buffCommonExtendPropertiesCallledChecker',
      () => {
        // empty
      },
      'Array',
    )
    return false
  } catch {
    return true
  }
}

declare global {
  interface Array<T> {
    groupBy: <Key extends string | number>(f: (x: T) => Key) => MRecord<Record<Key, T[]>>
    groupByUniqueKey: <Key extends string | number>(f: (x: T) => Key) => Record<Key, T>
    countBy: <Key extends string | number>(f: (x: T) => Key) => Record<Key, number>
    mySortBy: (f: (x: T) => unknown) => T[]
    sortBy: (f: (x: T) => unknown) => T[]

    toObject: <K extends string | undefined, V>(
      f: (x: T, index: number) => [key: K, value: V],
    ) => Record<Exclude<K, undefined>, V>
    toRecord: <V>(f: (x: T, index: number) => [key: string | undefined, value: V]) => MRecord<Record<string, V>>
    // toMap: () => Map<string, string> // TODO
    // toMap: () => Map<T[0], T[1]>
    unique: () => T[]
    uniqueBy: (f: (x: T) => unknown) => T[]
    zip: <S>(ys: S[]) => Array<[T, S]>
    eachCons: (x: number) => T[][]
    eachSlice: (x: number) => T[][]
    last: () => T | undefined
    first: () => T | undefined
    sum: () => number
    sample: () => T
    compact: () => Array<Exclude<T, null | undefined>>
    min: () => number | undefined // 配列の長さが0のときundefined
    max: () => number | undefined
    minBy: (f: (x: T) => number) => T | undefined
    maxBy: (f: (x: T) => number) => T | undefined
    sliceWhen: (f: (a: T, b: T) => boolean) => T[][]
    mapConsIncremental: () => T[][]
    partition: (f: (x: T) => boolean) => [trues: T[], falses: T[]]
    isPresent: () => boolean
    isBlank: () => boolean
    isEmpty: () => boolean
    isEqual: (xs: T[]) => boolean
    union: (xs: T[]) => T[]
    intersection: (xs: T[]) => T[]
    difference: (xs: T[]) => T[]
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
    intersectionBy: <S>(xs: T[], f: (x: T) => S) => T[]
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
    differenceBy: <S>(xs: T[], f: (x: T) => S) => T[]
    promiseAll: () => Promise<Awaited<T[]>>
    clone: () => T[]
    upsertBy: (x: T, f: (x: T) => string) => T[]
    move: (oldIndex: number, newIndex: number) => T[]
    combination: (n: number) => T[][]
    subsets: () => T[][]
  }

  interface Map<K, V> {
    toArray: () => Array<[K, V]>
    toObject: () => Record<string, V>
    map: <T>(f: (xs: [K, V]) => T) => T[]
    transformProperties: <T>(f: (value: V) => T) => Map<K, T>
  }
}

import { initialize } from './initialize'
import * as utilRecord from './util/record'
import * as v from './validation'

export * from './normalize'
export * from './pg-emulator/PgEmulator'
export type * from './typeUtil'
export * from './util/array'
export * from './util/cartesianProduct'
export * from './util/date'
export * from './util/normalizeZodError'
export * from './util/number'
export * from './util/string'
export * from './util/tree'
export * from './util/util'
export { validationFunctionSchema } from './validation/schema'
export * from './version'
export * from './zod'

export const validate = v.validate
export const ValidationFunctionSchema = v.ValidationFunctionSchema
export type ValidationFunction = v.ValidationFunction
export type ValidationArgument = v.ValidationArgument

export { findValueInRecord, MRecord } from './util/record'
// utilRecord
// export const toRecord = utilRecord.toRecord // TODO: array側のtoRecordを別で置き換える
export const r = utilRecord.toRecord

export { extendProperties } from './extend_properties'

initialize()

import { useRouter } from 'next/router'

export const useRedirect = (callback?: () => void) => {
  const router = useRouter()

  const redirect = (path: string) => {
    if (router.asPath !== path) {
      void router.push(path)
      if (callback !== undefined) {
        callback()
      }
    }
  }

  return redirect
}

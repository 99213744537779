import { isNull, isSome } from '@salescore/buff-common'

import type { ViewQueryList, ViewQueryNode, ViewQueryRecordNode, ViewQueryTableNode } from '../../../schemas/query'
import { makeIdentifiable } from '../../util/makeIdentifiable'
import { POSTGRES_COLUMN_LENGTH_LIMIT, type SqlResultRecord } from '../types'
import { generateAbsoluteFieldAsName, getLabeledName } from './util'

//
// ツリー構造のレスポンスをするため、SQL側ではGROUP BYをせずに単純にJOINし、アプリケーションレイヤでグルーピングを行う
// ORDER BYでid順に並んでいるので基本的にはsliceWhenした上でグルーピングするだけ
//
export function groupByCurrentNode(
  records: SqlResultRecord[],
  node: ViewQueryNode,
  view: ViewQueryList,
): ViewQueryRecordNode[] {
  // 現在のnodeでグルーピングを行う。nodeにidFieldがない場合、すべてのレコードとする
  const idFieldName =
    node.read.idColumn === undefined
      ? undefined
      : makeIdentifiable(generateAbsoluteFieldAsName(node.name, node.read.idColumn))
  const groupedRecords = getGroupedRecords(node, records, idFieldName)
  const fields = view.fields.filter((field) => field.nodePath.join(',') === node.path.join(','))

  const xs: ViewQueryRecordNode[] = groupedRecords.map((groupedRecord): ViewQueryRecordNode => {
    const record = groupedRecord[0]! // sliceWhenでグルーピングしているので、必ず1行は存在する。また、idカラムでグルーピングしているので、fieldsの値はすべてのレコードで同じ値になっているはずであり、最初のレコードを参照すればattribuetsが算出できる
    const attributes = fields
      .flatMap((field): Array<[string, unknown]> => {
        const labelAttributes = isSome(field.read.labelSql)
          ? ([
              getLabeledName(field.name),
              record[getLabeledName(field.name).slice(0, POSTGRES_COLUMN_LENGTH_LIMIT)],
            ] as [string, unknown])
          : undefined
        const additionalAttributes = (field.read.additionalFields ?? []).map(
          (additionalField) => [additionalField.name, record[additionalField.name]] as [string, unknown],
        )
        return [
          [field.name, record[field.name.slice(0, POSTGRES_COLUMN_LENGTH_LIMIT)]] as [string, unknown],
          labelAttributes,
          ...additionalAttributes,
        ].compact()
      })
      .toObject((xs) => [xs[0], xs[1]])

    const children = (node.children ?? []).map(
      (childNode): ViewQueryTableNode => ({
        nodeName: childNode.name,
        children: groupByCurrentNode(groupedRecord, childNode, view),
      }),
    )
    const visibleNodeNames = view.fields
      .flatMap((field) => field.nodePath)
      .compact()
      .unique() // map(x => x.nodePath.last())とかにすると、一列も存在しないノードがあったときにheightの計算がおかしくなるので注意
    const childHeights = children.map((childTableNode) => {
      if (!visibleNodeNames.includes(childTableNode.nodeName)) {
        return 0
      }
      return childTableNode.children.map((x) => x.meta.height).sum()
    })
    return {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
      id: idFieldName === undefined ? undefined : (record[idFieldName] as string),
      attributes: {
        ...attributes,
        ...getJoinAttributes(node, record),
      },
      children,
      meta: {
        height: Math.max(...childHeights, 1),
        innerRowIndexStart: 0,
        innerRowIndexEnd: 0, // 後で更新
      },
    }
  })
  return node.path.length > 1 ? xs.uniqueBy((x) => x.id) : xs
}

function getJoinAttributes(node: ViewQueryNode, record: SqlResultRecord) {
  const joinOn = node.read.join?.joinOn
  if (joinOn === undefined || joinOn.type !== 'id') {
    return {}
  }

  const fieldName =
    joinOn.currentNodeName === node.name
      ? makeIdentifiable(generateAbsoluteFieldAsName(joinOn.currentNodeName, joinOn.currentNodeColumnName))
      : makeIdentifiable(generateAbsoluteFieldAsName(joinOn.parentNodeName, joinOn.parentNodeColumnName))

  return {
    [fieldName]: record[fieldName],
  }
}

function getGroupedRecords(
  node: ViewQueryNode,
  records: SqlResultRecord[],
  idFieldName: string | undefined,
): SqlResultRecord[][] {
  if (isNull(idFieldName)) {
    return records.map((record) => [record])
  }
  const filteredRecords = records.filter((record) => isSome(record[idFieldName]))

  if (node.path.length === 1) {
    return filteredRecords.sliceWhen((x, y) => x[idFieldName] !== y[idFieldName])
  }

  // 子レコードはid順に並んでいない場合がある。極力もとの並び順を維持しつつ、idでグルーピングして返す
  // groupByはkeyの並び順を保持する
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  return group(filteredRecords, (x) => x[idFieldName] as string)
}

interface GroupResult<T> {
  key: string
  values: T[]
}

function group<T>(xs: T[], f: (x: T) => string) {
  // const results = xs.reduce((acc, x) => {
  //   const key = f(x)
  //   const existed = acc.find(a => a.key === key)
  //   if (existed !== undefined) {
  //     existed.values.push(x)
  //   } else {
  //     acc.push({
  //       key,
  //       values: [x]
  //     })
  //   }
  //   return acc
  // }, [] as Array<GroupResult<T>>)
  const results: Array<GroupResult<T>> = []
  for (const x of xs) {
    const key = f(x)
    if (isNull(key)) {
      continue
    }
    const existed = results.find((a) => a.key === key)
    if (existed === undefined) {
      results.push({
        key,
        values: [x],
      })
    } else {
      existed.values.push(x)
    }
  }
  return results.map((x) => x.values)
}

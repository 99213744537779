import { z } from 'zod'

import { goalAggregationFunctionEnum } from '../misc/goal_config'
import { viewUiDimensionCandidateSchema } from './dimension'
import { fieldMetaTypes, viewUiComponentTypeSchema } from './enum'

// zodの循環参照を回避するために、kpiPivot.tsではなくkpi.ts内でスキーマ定義している
export const viewUiKpiPivotColumnSchema = z.object({
  key: z.string(),
  label: z.string(),
  fieldType: z.union([
    z.literal(`string`),
    z.literal(`user`),
    z.literal(`kpi`),
    z.literal(`reference`),
    z.literal(`date`),
  ]), // TODO
  fieldMetaType: fieldMetaTypes.optional(),
  selectOptions: z
    .object({
      label: z.string(),
      value: z.union([z.string(), z.number()]),
      color: z.string().optional().nullable(),
      icon: z.string().optional().nullable(),
    })
    .array()
    .optional(),
})
export type ViewUiKpiPivotColumn = z.infer<typeof viewUiKpiPivotColumnSchema>

// TODO: 名前だけで、コンポーネントなのかコンポーネントのプロパティの一部なのかがわからない。（これはプロパティとしてのKPI）
export const kpiAppearanceSchema = z.object({
  id: z.string(),
  label: z.string(),
  groupName: z.string().optional(),
  icon: z.string().optional(),
  description: z.string().optional(),
  showTransition: z.boolean().optional(),
  aggregationFunction: goalAggregationFunctionEnum.optional(),
  // データ移行後の互換担保のため単位を残しておく
  abbreviationType: z
    .enum([
      'automatic',
      'noAbbreviation',
      'abbreviateThousand',
      'abbreviateMillion',
      'abbreviateTenThousand',
      'abbreviateHandredMillion',
      'abbreviateDecimal',
      'abbreviateSecondDecimalPlace',
      'abbreviateThirdDecimalPlace',
      'abbreviateFourthDecimalPlace',
    ])
    .optional(),
  decimalPlaceType: z
    .enum([
      'automatic',
      'noDecimalPlace',
      'firstDecimalPlace',
      'secondDecimalPlace',
      'thirdDecimalPlace',
      'fourthDecimalPlace',
    ])
    .optional(),
  unitType: z.enum(['noUnit', 'unitThousand', 'unitMillion', 'unitTenThousand', 'unitHandredMillion']).optional(),
  achievementStatusCalculationType: z.enum(['greaterThanOrEqual', 'lessThanOrEqual']).optional(),
  achievementStatusTargetValueType: z.enum(['averageProgress', 'goal', 'zero']).optional(),
  displayFormat: z.enum([`none`, `percent`, `yen`]).optional(), // noneは指定なし(undefined)と同じ意味。なくても良いが、UIでの選択肢の都合上、あると便利
  withDateField: z.boolean(),
})
export type ViewKpiAppearance = z.infer<typeof kpiAppearanceSchema>

// 名前が微妙
export const pivotAppearanceSchema = z.object({
  rows: z
    .object({
      key: z.string(),
      width: z.number(),
    })
    .array()
    .optional(),
  columns: z
    .object({
      key: z.string(),
      keys: z
        .object({
          value: z.string(),
          width: z.number(),
        })
        .array(),
    })
    .array()
    .optional(),
})

export const kpiSchema = z.object({
  type: z.literal(viewUiComponentTypeSchema.enum.KpiSheet),
  kpi: kpiAppearanceSchema,
  pivot: z.object({
    rows: viewUiKpiPivotColumnSchema.array(),
    columns: viewUiKpiPivotColumnSchema.array(),
  }),
  dimensions: viewUiDimensionCandidateSchema.array(),
  appearance: pivotAppearanceSchema.optional(),
})
export type ViewUiKpi = z.infer<typeof kpiSchema>

import { isSome, treeUtil } from '@salescore/buff-common'

import { isSameNodeProperty } from '../../functions/view/compileViewConfig/sheet/isSameNodeProperty'
import type { ReferenceToProperty, ViewConfigTreeNode } from '../../schemas/view_config'

export type ReferenceToPropertyForAddChildNodeMutation = Omit<ReferenceToProperty, 'nodeName'> & {
  nodeName?: string
}

export function addViewConfigChildNode(
  tree: ViewConfigTreeNode, // tree
  node: ViewConfigTreeNode, // 追加したいノード
  referenceToProperty: ReferenceToPropertyForAddChildNodeMutation, // 追加で使うreferenceTo
) {
  // 対象のnodeが既に同じ条件のchildをもっていればそれを返す
  const child = node.children?.find(
    (child) =>
      isSome(child.referenceToProperty) &&
      isSameReferenceToProperty(child.referenceToProperty, referenceToProperty, node.name),
  )
  if (isSome(child)) {
    return {
      newNode: child,
      tree: undefined,
    }
  }

  const newNodeModelName =
    referenceToProperty.modelName === node.modelName
      ? referenceToProperty.referenceTo.modelName
      : referenceToProperty.modelName
  const newNodeName = generateNewNodeName(treeUtil.flatNodes(tree), newNodeModelName)
  const newNode: ViewConfigTreeNode = {
    type: 'model',
    name: newNodeName,
    modelName: newNodeModelName,
    referenceToProperty: {
      ...referenceToProperty,
      nodeName: referenceToProperty.nodeName ?? newNodeName,
    },
  }
  const newTree = treeUtil.add(tree, node.name, newNode)
  return {
    tree: newTree,
    newNode,
  }
}

function generateNewNodeName(nodes: ViewConfigTreeNode[], originalName: string, depth = 0): string {
  const name = depth === 0 ? originalName : `${originalName}_${depth}`
  if (nodes.map((x) => x.name).includes(name)) {
    return generateNewNodeName(nodes, originalName, depth + 1)
  }
  return name
}

function isSameReferenceToProperty(
  a: ReferenceToPropertyForAddChildNodeMutation,
  b: ReferenceToPropertyForAddChildNodeMutation,
  parentNodeName: string,
): boolean {
  return [
    isSameNodeProperty(
      {
        ...a,
        nodeName: a.nodeName === parentNodeName ? 'thisnode' : 'childnode',
      },
      {
        ...b,
        nodeName: b.nodeName === parentNodeName ? 'thisnode' : 'childnode',
      },
      false,
    ),
    a.referenceTo.modelName === b.referenceTo.modelName,
    a.referenceTo.key === b.referenceTo.key,
  ].every(Boolean)
}

import { isTruthy } from '@salescore/buff-common'
import { type Dayjs, isDayjs } from 'dayjs'

import type { Operator } from '../syntax/expression_ast'

export function callOperator(operator: Operator, left: unknown, right: unknown) {
  if (typeof left === 'number' && typeof right === 'number') {
    return callOperatorForNumber(operator, left, right)
  }
  if (isDayjs(left) && isDayjs(right)) {
    return callOperatorForDate(operator, left, right)
  }

  return callOperatorForAny(operator, left, right)
}

function callOperatorForDate(operator: Operator, left: Dayjs, right: Dayjs) {
  switch (operator) {
    case '+': {
      throw new Error(`演算子${operator}はdate,dateに対して定義されていません。`)
    }
    case '-': {
      throw new Error(`演算子${operator}はdate,dateに対して定義されていません。`)
    }
    case '*': {
      throw new Error(`演算子${operator}はdate,dateに対して定義されていません。`)
    }
    case '/': {
      throw new Error(`演算子${operator}はdate,dateに対して定義されていません。`)
    }
    case '>=': {
      return left.isAfter(right) || left.isSame(right)
    }
    case '<=': {
      return left.isBefore(right) || left.isSame(right)
    }
    case '>': {
      return left.isAfter(right)
    }
    case '<': {
      return left.isBefore(right)
    }
    case '=': {
      return left.isSame(right)
    }
    case '!=': {
      return !left.isSame(right)
    }
    case '&&': {
      return true
    }
    case '||': {
      return true
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const x: never = operator
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`演算子${operator as unknown}は${typeof left},${typeof right}に対して定義されていません。`)
    }
  }
}

function callOperatorForNumber(operator: Operator, left: number, right: number) {
  switch (operator) {
    case '+': {
      return left + right
    }
    case '-': {
      return left - right
    }
    case '*': {
      return left * right
    }
    case '/': {
      return left / right
    }
    case '>=': {
      return left >= right
    }
    case '<=': {
      return left <= right
    }
    case '>': {
      return left > right
    }
    case '<': {
      return left < right
    }
    case '=': {
      return left === right
    }
    case '!=': {
      return left !== right
    }
    case '&&': {
      return true
    }
    case '||': {
      return true
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const x: never = operator
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`演算子${operator as unknown}は${typeof left},${typeof right}に対して定義されていません。`)
    }
  }
}

function callOperatorForAny(operator: Operator, left: unknown, right: unknown) {
  switch (operator) {
    case '=': {
      return left === right
    }
    case '!=': {
      return left !== right
    }
    case '&&': {
      return isTruthy(left) && isTruthy(right)
    }
    case '||': {
      return isTruthy(left) || isTruthy(right)
    }
    default: {
      // const x: never = operator // TODO

      throw new Error(
        `演算子${operator}は${typeof left},${typeof right}に対して定義されていません。${JSON.stringify(
          left,
        )}, ${JSON.stringify(right)}`,
      )
    }
  }
}

import { type Dispatch, type SetStateAction, useCallback, useRef, useState } from 'react'

import { useUnmount } from './useUnmount'

// Short for "use requestAnimationFrame state"
// この関数は、ReactのuseStateと同じように動作しますが、値の更新をrequestAnimationFrameを使って行います。
export const useRafState = <S>(initialState: S | (() => S)): [S, Dispatch<SetStateAction<S>>] => {
  const frame = useRef(0)
  const [state, setState] = useState(initialState)

  const setRafState = useCallback((value: S | ((previousState: S) => S)) => {
    cancelAnimationFrame(frame.current)

    frame.current = requestAnimationFrame(() => {
      setState(value)
    })
  }, [])

  useUnmount(() => {
    cancelAnimationFrame(frame.current)
  })

  return [state, setRafState]
}

//
// 処理が重い非同期関数をロジックの都合で何度も呼び出すが、都度実行する必要はなく最新のもののみ更新すれば十分な場合に使うロジック
// （例）並び替え可能なユーザー一覧で、並び替えが発生する度に更新処理を行う、など
// 本来であればTimeWindowExecutorのロジックが理想的であり、各所で使ってきたが、不具合が見つかったのでこちらに移行している。
// XXX: こちらのロジックだと、単にsetIntervalを続けるのでパフォーマンスがやや悪く、またキューが大量にたまったときに処理が遅れるという問題が解消されていない
//

import { r } from '@salescore/buff-common'

import { logger } from './logtail'

interface Queue {
  key: string
  f: () => void | Promise<void> // エラーハンドリングされないので、fの中でcatchすること
}

const queues: Record<string, Queue[]> = {}

setInterval(() => {
  void execute()
}, 300)

async function execute() {
  const keys = r(queues).keys()
  for (const key of keys) {
    await executeKey(key)
  }
}

async function executeKey(key: string) {
  // キューの数をチェック
  const targetQueues = queues[key] ?? []
  if (targetQueues.length === 0) {
    return // 何もしない
  }
  if (targetQueues.length > 1) {
    // 最後だけを残す
    const lastQueue = targetQueues.last()!
    queues[key] = [lastQueue]
    return
  }
  const targetQueue = targetQueues.shift()
  if (targetQueue === undefined) {
    return
  }
  if (targetQueues.length > 0) {
    // 後続のキューがあるときはまだ実行しない
    // TODO: 後続のキューが複数あるときは、1つになるまで削除したい
    return
  }

  try {
    await targetQueue.f()
  } catch (error) {
    logger.debug(error)
  }
}

export function queueTimeWindowExecutorV2(queue: Queue) {
  queues[queue.key] ??= []
  queues[queue.key]?.push(queue)
}

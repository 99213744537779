import type { FieldType } from '.'

const nullFilterTypes = ['null', 'not_null'] as const
const blankFilterTypes = ['blank', 'present'] as const
export const equalFilterTypes = ['equal', 'not_equal'] as const
const inFilterTypes = ['in', 'not_in'] as const
const overlapFilterTypes = ['overlap', 'not_overlap'] as const
// TODO: betweenはUI実装が面倒になるので、一旦除外
export const includeFilterTypes = ['include', 'not_include'] as const
export const startsWithFilterTypes = ['starts_with', 'not_starts_with'] as const
export const comparableFilterTypes = [
  'less_than',
  'less_than_or_equal',
  'greater_than',
  'greater_than_or_equal',
] as const
export const equalMeFilterTypes = ['equal_me'] as const

export const booleanFilterTypes = [...equalFilterTypes, ...nullFilterTypes] as const
export const stringFilterTypes = [
  ...equalFilterTypes,
  ...inFilterTypes,
  ...nullFilterTypes,
  ...includeFilterTypes,
  ...startsWithFilterTypes,
  ...blankFilterTypes,
  ...equalMeFilterTypes,
] as const
export const numericFilterTypes = [
  ...equalFilterTypes,
  ...comparableFilterTypes,
  ...nullFilterTypes,
  ...inFilterTypes,
] as const
export const dateFilterTypes = [...equalFilterTypes, ...comparableFilterTypes, ...nullFilterTypes] as const
export const timeFilterTypes = [...equalFilterTypes, ...comparableFilterTypes, ...nullFilterTypes] as const
export const arrayFilterTypes = [
  ...nullFilterTypes,
  ...overlapFilterTypes,
  ...includeFilterTypes,
  ...inFilterTypes,
] as const
export const filterTypes = [
  ...equalFilterTypes,
  ...nullFilterTypes,
  ...inFilterTypes,
  ...includeFilterTypes,
  ...startsWithFilterTypes,
  ...overlapFilterTypes,
  ...comparableFilterTypes,
  ...blankFilterTypes,
  ...equalMeFilterTypes,
] as const

export type EqualFiltersType = typeof equalFilterTypes
export type ComparableFiltersType = typeof comparableFilterTypes
export type BooleanFiltersType = typeof booleanFilterTypes
export type StringFiltersType = typeof stringFilterTypes
export type NumericFiltersType = typeof numericFilterTypes
export type DateFiltersType = typeof dateFilterTypes
export type TimeFiltersType = typeof timeFilterTypes
export type ArrayFiltersType = typeof arrayFilterTypes
export type EqualMeFiltersType = typeof equalMeFilterTypes
export type FiltersType = typeof filterTypes

export type EqualFilterType = EqualFiltersType[number]
export type ComparableFilterType = ComparableFiltersType[number]
export type BooleanFilterType = BooleanFiltersType[number]
export type StringFilterType = StringFiltersType[number]
export type NumericFilterType = NumericFiltersType[number]
export type DateFilterType = DateFiltersType[number]
export type TimeFilterType = TimeFiltersType[number]
export type ArrayFilterType = ArrayFiltersType[number]
export type FilterType = FiltersType[number]

// export const DEFAULT_TIMEZONE_INTERVAL = '+9 hours'
export type TimezoneInterval = `+9 hours` // これ以外にも対応する必要ができたら、template literal typesを使って対応

// TODO: rename
export interface AlwaysFilter {
  field_name: string // lookerに習ってfield_nameとしたが、実質sql
  field_type: FieldType
  filter_type: FilterType
  filter_expression?: string | number
}

//
// 本当はzodで書きたかったが、zodは循環に対応していないので諦めた

import type { Dayjs } from 'dayjs'
import { z } from 'zod'

interface StringLiteralNode {
  type: 'literal'
  literalType: 'string'
  value: string
}

interface NumberLiteralNode {
  type: 'literal'
  literalType: 'number'
  value: number
}

interface BooleanLiteralNode {
  type: 'literal'
  literalType: 'boolean'
  value: boolean
}

interface NullLiteralNode {
  type: 'literal'
  literalType: 'null'
  value: null
}

interface DateLiteralNode {
  // 内部的にはリテラルだが、現状Date型を表現する文字列は存在しない。date('2023-01-01')のような関数でのみ表現できる。
  type: 'literal'
  literalType: 'date'
  value: Dayjs // valueをどう持つかが悩ましい
}

export type LiteralNode = StringLiteralNode | NumberLiteralNode | BooleanLiteralNode | NullLiteralNode | DateLiteralNode

export interface VariableNode {
  type: 'variable'
  path: string[]
}

export type LogicalOperator = '&&' | '||'
export type EqualityOperator = '=' | '!='
export type MathEqualityOperator = '>' | '<' | '<=' | '>='
export type MathOperator = '+' | '-' | '*' | '/'
export type Operator = MathOperator | EqualityOperator | MathEqualityOperator | LogicalOperator
export interface OperatorNode {
  type: 'operator'
  operator: Operator
  left: ExpressionNode
  right: ExpressionNode
}

export type FunctionNames =
  | 'add'
  | 'present'
  | 'equal'
  | 'in'
  | 'never'
  | 'not'
  | 'and'
  | 'or'
  | 'date'
  | 'blank'
  | 'include'
  | 'startsWith'
  | 'rank'
  | 'year'
  | 'month'
  | 'week'
  | 'today'
  | 'dateAdd'
  | 'overlap'

export interface FunctionNode {
  type: 'func'
  name: FunctionNames
  args: ExpressionNode[]
}

export interface ArrayNode {
  type: 'array'
  elements: ExpressionNode[]
}

export type ExpressionNode = FunctionNode | ArrayNode | LiteralNode | VariableNode | OperatorNode

//
// deprecated。以前使っていたast
//
interface DeprecatedValidationFunctionValue {
  type: 'value'
  value: unknown
}

interface DeprecatedValidationFunctionRecordValue {
  type: 'recordValue'
  path: string[] // pathが空配列のときルート。
}

export interface DeprecatedValidationFunctionNode {
  type: 'function'
  functionType: 'present' | 'in' | 'never' | 'equal'
  arguments: Array<
    DeprecatedValidationFunctionNode | DeprecatedValidationFunctionValue | DeprecatedValidationFunctionRecordValue
  >
}

export type ExpressionNodeWithDeprecated =
  | ExpressionNode
  | DeprecatedValidationFunctionNode
  | DeprecatedValidationFunctionValue
  | DeprecatedValidationFunctionRecordValue

// 一部でparseしたいニーズがあるので、一部のみschema定義している
const functionNodeSchema = z.object({
  type: z.literal(`func`),
  name: z.string(),
  args: z.unknown().array(),
})

const deprecatedFunctionNodeSchema = z.object({
  type: z.literal(`function`),
  functionType: z.string(),
  arguments: z.unknown().array(),
})

export const functionNodeSchemaWithDeprecated = z.union([functionNodeSchema, deprecatedFunctionNodeSchema])

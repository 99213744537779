import type { ViewConfigKpi, ViewConfigSheet } from '../../../../schemas/view_config'
import { getNodeByName } from '../../../util/node'
import type { CompileContext } from '../../common'
import { generateSalescoreUserNode } from './generateSalescoreUserGroupTree'

export function addSalescoreUserTree(
  config: ViewConfigSheet,
  user: ViewConfigKpi['user'],
  context: CompileContext,
): ViewConfigSheet {
  if (user === undefined) {
    return config
  }
  if (config.tree === undefined) {
    // ありえないはず
    return config
  }

  // 破壊的操作でsalescore_users_with_groupをjoinする
  const newTree = structuredClone(config.tree) // TODO
  const nodeWithUserProperty = getNodeByName(newTree, user.property.nodeName)?.node
  if (nodeWithUserProperty === undefined) {
    context.logs.error(`ユーザーフィールドのノードが見つかりませんでした。${user.property.nodeName}`)
    return config
  }

  const modelProperty = context.modelSearcher.searchProperty(user.property.modelName, user.property.propertyName)
  const newNode = generateSalescoreUserNode(user, modelProperty)
  nodeWithUserProperty.children ??= []

  if (newNode !== undefined) {
    nodeWithUserProperty.children.push(newNode)
  }

  return {
    ...config,
    tree: newTree,
  }
}

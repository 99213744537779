import { z } from 'zod'

import { viewQueryAggregationSchema, viewQueryDimensionFieldSchema } from './aggregation'
import { viewQueryNodeSchema } from './tree'

// 本来であればpivotの設定はaggregation側で行なっているので、それを参照すれば十分だが
// multiTablePivotではアプリケーションレイヤでKPI_IDのdimensionを追加するというややこしい処理があり、
// その辺の兼ね合いで別途pivotを定義している。
// このpivotはツリーの組み立てのみで使われ、SQLの発行などには関与しないため、最低限の値のみを定義すればOK
const viewQueryDimensionFieldForMultiTablePivotSchema = viewQueryDimensionFieldSchema.pick({
  name: true,
  fixedValues: true,
  sortedValues: true,
})
export type ViewQueryDimensionFieldForMultiTablePivot = z.infer<typeof viewQueryDimensionFieldForMultiTablePivotSchema>
const viewQueryPivotForMultiTablePivotSchema = z.object({
  rows: viewQueryDimensionFieldForMultiTablePivotSchema.array(),
  columns: viewQueryDimensionFieldForMultiTablePivotSchema.array(),
})
export type ViewQueryPivotForMultiTablePivot = z.infer<typeof viewQueryPivotForMultiTablePivotSchema>

const viewQueryMultiTablePivotQuerySchema = z.object({
  name: z.string(),
  role: z.string(),
  kpiId: z.string(),
  kpiName: z.string(), // ラベル表示に利用
  kpiGroupName: z.string().optional(), // 表示で利用
  schema: viewQueryAggregationSchema.nullable(), // 生成に失敗したらnull
})
export type ViewQueryMultiTablePivotQuery = z.infer<typeof viewQueryMultiTablePivotQuerySchema>

const viewQueryMultiTablePivotSorterSchema = z.object({
  columnKeys: z
    .object({
      key: z.string(),
      order: z.enum(['asc', 'desc']),
    })
    .array()
    .optional(),
  totalRowOrder: z.enum(['top', 'bottom']).optional(), // デフォルトはbottom
  totalColumnOrder: z.enum(['left', 'right']).optional(), // デフォルトはright
  // totalColumnOrder: z.enum(['left', 'right']).optional(), // TODO
  // TODO: rowのkeyのsortもできるようにするか？
})
export type ViewQueryMultiTablePivotSorter = z.infer<typeof viewQueryMultiTablePivotSorterSchema>

export const viewQueryMultiTablePivotSchema = z.object({
  type: z.literal('multiTablePivot'),
  tree: viewQueryNodeSchema, // このtreeはレスポンスのデータ構造の明示のために使われる（クエリの実行には使われない）
  queries: viewQueryMultiTablePivotQuerySchema.array(),
  pivot: viewQueryPivotForMultiTablePivotSchema,
  sorter: viewQueryMultiTablePivotSorterSchema.optional(),
  options: z
    .object({
      skipTotal: z.boolean().optional(),
    })
    .optional(),
})
export type ViewQueryMultiTablePivot = z.infer<typeof viewQueryMultiTablePivotSchema>

import { Button, Tooltip } from 'antd'

type ButtonType = typeof Button
type ButtonArg = Parameters<ButtonType>[0]

export function ButtonWithTooltip(arg: ButtonArg & { tooltipTitle: string | JSX.Element; showTooltip?: boolean }) {
  const { tooltipTitle, showTooltip, ...restArg } = arg
  if (showTooltip ?? true) {
    return (
      <Tooltip title={tooltipTitle}>
        <Button {...restArg} />
      </Tooltip>
    )
  }
  return <Button {...restArg} />
}

import { isNull } from '@salescore/buff-common'

import type { ViewQueryAggregation } from '../../../../../schemas/query'
import type { ViewUIKpiTimeSeries } from '../../../../../schemas/ui/kpiTimeSeries'
import type { ViewConfigKpiTimeSeries } from '../../../../../schemas/view_config'
import { quote } from '../../../../query/executeViewQuery/generateSql'
import type { CompileContext } from '../../../common'
import type { CompileViewConfigResponse } from '../../../response'
import { compileSheetAndMeasure } from '../../sheet/compileSheetAndMeasure'
import { generateSheetWithSequenceFilter } from './generateSequenceJoinedSnapshotSql'
import { generateWaterfallMeasure } from './generateWaterfallMeasures'
import { generateWaterfallQueryNode } from './generateWaterfallQueryNode'
import { dimensionColumnName, prioritizedTableName } from './utils'

// TODO
const userStream = {
  name: `salescore_users`,
  destination: {
    name: `salescore_users`,
  },
  meta: {
    label: `ユーザー`,
  },
}
const emptyQuery: ViewQueryAggregation = {
  type: 'aggregation',
  tree: {
    name: userStream.name,
    path: [userStream.name],
    read: {
      idColumn: 'id', // TODO: idが常にある前提。カスタムストリームなど、idがない場合にどうするか？destinationで指定すべきか？
      tableSql: userStream.destination.name,
      tableType: 'table',
    },
    write: {
      streamName: userStream.name,
    },
    meta: {
      label: userStream.meta.label,
      dependedStreamNames: [userStream.name],
    },
  },
  sorters: [],
  filterTree: {
    logicalOperator: 'and',
    leafs: [],
    children: [],
  },
  measures: [],
}

export function compileWaterfallViewConfig(
  config: ViewConfigKpiTimeSeries,
  context: CompileContext,
): CompileViewConfigResponse {
  const ui: ViewUIKpiTimeSeries = {
    type: 'KpiTimeSeriesGraph',
    dimensions: [],
    queriedDimensions: [],
    waterfall: {
      dimensions:
        config.waterfall?.dimensions.map((x) => ({
          name: x.name,
          label: x.label,
        })) ?? [],
    },
  }

  const empty: CompileViewConfigResponse = {
    // fetchを発生させないように空のクエリを返す
    queries: [],
    query: emptyQuery,
    ui: [ui],
    context,
  }

  const startAt = config.filters?.startPeriod?.startAt
  const endAt = config.filters?.endPeriod?.endAt

  if (isNull(startAt) || isNull(endAt)) {
    return empty
  }

  const p1AggregationQuery = generatePeriodQuery(config, startAt, context)
  const p2AggregationQuery = generatePeriodQuery(config, endAt, context)
  const pivotedQuery = generatePivotedQuery(config, context)

  if (isNull(p1AggregationQuery) || isNull(p2AggregationQuery) || isNull(pivotedQuery)) {
    return empty
  }

  return {
    queries: [pivotedQuery, p1AggregationQuery, p2AggregationQuery], // p1とp2のクエリの順番は固定する
    query: pivotedQuery,
    ui: [ui],
    context,
  }
}

function generatePivotedQuery(
  config: ViewConfigKpiTimeSeries,
  context: CompileContext,
): ViewQueryAggregation | undefined {
  const tree = generateWaterfallQueryNode(config, context)
  if (isNull(tree)) {
    return
  }

  const measure = generateWaterfallMeasure({ config, queryNode: tree, context })
  if (isNull(measure)) {
    return
  }

  return {
    type: 'aggregation',
    tree,
    measures: [measure],
    filterTree: {
      logicalOperator: 'and',
      leafs: [],
      children: [],
    },
    sorters: [],
    pivot: {
      rows: [
        // 集計時に優先順位を考慮して排他集計を行うため、ここで優先順位の列を元にピボットさせている
        {
          type: 'field',
          name: dimensionColumnName,
          sql: `${quote(prioritizedTableName)}.${quote(dimensionColumnName)}`,
          fieldSql: `${quote(prioritizedTableName)}.${quote(dimensionColumnName)}`,
        },
      ],
      columns: [],
    },
  }
}

function generatePeriodQuery(
  config: ViewConfigKpiTimeSeries,
  sequenceCreatedAt: string,
  context: CompileContext,
): ViewQueryAggregation | undefined {
  const sheet = generateSheetWithSequenceFilter(config, sequenceCreatedAt)
  const measure = config.kpiFragment?.measure

  if (isNull(sheet) || isNull(measure)) {
    return
  }

  const compiled = compileSheetAndMeasure(measure, sheet, { rows: [], columns: [] }, [], context)
  if (isNull(compiled)) {
    return
  }

  return compiled.query
}

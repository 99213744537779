import { type AlwaysFilter, numericFilterTypes } from '../../../../../../schemas/query/filterTypes'
import { NotImplementedError } from '../../../../../errors'
import { nullFilter, numericCompareFilter, numericInFilter } from './filters'
import { validateFilterType } from './validateFilterType'

// FYI https://docs.looker.com/reference/filter-expressions
export const generateNumericFilter = (filter: AlwaysFilter): string => {
  const type = validateFilterType(filter.filter_type, numericFilterTypes)

  switch (type) {
    case 'null': {
      return nullFilter(filter, false)
    }
    case 'not_null': {
      return nullFilter(filter, true)
    }
    case 'in': {
      return numericInFilter(filter, false)
    }
    case 'not_in': {
      return numericInFilter(filter, true)
    }
    case 'equal': {
      return numericCompareFilter(filter)
    }
    case 'not_equal': {
      return numericCompareFilter(filter)
    }
    case 'greater_than': {
      return numericCompareFilter(filter)
    }
    case 'greater_than_or_equal': {
      return numericCompareFilter(filter)
    }
    case 'less_than': {
      return numericCompareFilter(filter)
    }
    case 'less_than_or_equal': {
      return numericCompareFilter(filter)
    }
    // case ('between'):
    //   return numericCompareFilter(filter)
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const x: never = type
      throw new NotImplementedError()
    }
  }
}

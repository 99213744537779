import { defineProperty } from './util'

const toMap = function <K, V>(xs: Array<[K, V]>) {
  return xs.reduce((accumulator, property) => {
    accumulator.set(property[0], property[1])
    return accumulator
  }, new Map<K, V>())
}

const toArray = function <K, V>(this: Map<K, V>) {
  // eslint-disable-next-line unicorn/prefer-spread
  return Array.from(this.entries())
}

const toObject = <V>(map: Map<string, V>) =>
  // eslint-disable-next-line unicorn/prefer-spread
  Array.from(map.entries())
    .map(([k, v]) => ({ [k]: v }))
    .reduce((l, r) => Object.assign(l, r), {})

const map = function <K, V, T>(this: Map<K, V>, f: (xs: [K, V]) => T) {
  // eslint-disable-next-line unicorn/prefer-spread
  return Array.from(this.entries()).map(([k, v]) => f([k, v]))
}

const transformProperties = function <K, V, T>(this: Map<K, V>, f: (value: V) => T) {
  // eslint-disable-next-line unicorn/prefer-spread
  const xs = Array.from(this.entries()).map(([k, v]) => [k, f(v)] as [K, T])
  return toMap(xs)
}

export function defineMapProperties() {
  defineProperty(Map.prototype, 'toArray', toArray, 'Map')
  defineProperty(Map.prototype, 'toObject', toObject, 'Map')
  defineProperty(Map.prototype, 'map', map, 'Map')
  defineProperty(Map.prototype, 'transformProperties', transformProperties, 'Map')
}

import { type AlwaysFilter, arrayFilterTypes } from '../../../../../../schemas/query/filterTypes'
import { NotImplementedError } from '../../../../../errors'
import { arrayIncludeFilter, arrayInFilter, arrayOverlapFilter, nullFilter } from './filters'
import { validateFilterType } from './validateFilterType'

// TODO
export const generateArrayFilter = (filter: AlwaysFilter): string => {
  const type = validateFilterType(filter.filter_type, arrayFilterTypes)

  switch (type) {
    case 'null': {
      return nullFilter(filter, false)
    }
    case 'not_null': {
      return nullFilter(filter, true)
    }
    case 'include': {
      return arrayIncludeFilter(filter, false)
    }
    case 'not_include': {
      return arrayIncludeFilter(filter, true)
    }
    case 'in': {
      return arrayInFilter(filter, false)
    }
    case 'not_in': {
      return arrayInFilter(filter, true)
    }
    case 'overlap': {
      return arrayOverlapFilter(filter, false)
    }
    case 'not_overlap': {
      return arrayOverlapFilter(filter, true)
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const x: never = type
      throw new NotImplementedError()
    }
  }
}

import { useState } from 'react'

/**
 *  example
 *  const { isModalVisible, showModal, hideModal } = useModal()
 *
 *  <Modal visible={isModalVisible} onCancel={hideModal} width={"80%"}>
 *    <KpiModalContent record={record} dashboard={dashboard} kpi={kpi} nextStepKpi={nextStepKpi} period={period} />
 *  </Modal>
 *
 * @deprecated App.useApp() に移行しましょう https://ant.design/components/app
 */

export function useModal<T>(isDefaultVisible = false): {
  content: undefined | T
  hideModal: () => void
  isModalVisible: boolean
  showModal: (content?: T) => void
} {
  const [isModalVisible, setIsModalVisible] = useState(isDefaultVisible)
  const [content, setContent] = useState<T | undefined>()
  const showModal = (content?: T): void => {
    setIsModalVisible(true)
    setContent(content)
  }
  const hideModal = (): void => {
    setIsModalVisible(false)
    setContent(undefined)
  }

  return {
    isModalVisible,
    showModal,
    hideModal,
    content,
  }
}

import { LoadingOutlined } from '@ant-design/icons'
import { TAILWIND_CSS_COLORS } from '@salescore/core'
import { Avatar, Space, Spin } from 'antd'
import platform from 'platform'
import { useEffect, useRef, useState } from 'react'

import type { useLauncherState } from './hooks/useLauncherState'

const ROW_HEIGHT = 58
const VISIBLE_ROW_NUM = 4
const LITTLE_VISIBLE_ROW_RATIO = 0.5 // ちょっと見える行
const color = `#555`

export function LauncherCommandList({ state }: { state: ReturnType<typeof useLauncherState> }) {
  const reference = useRef<HTMLDivElement>(null)
  const [hoveredIndex, setHoveredIndex] = useState<number | undefined>()
  useEffect(() => {
    if (reference.current !== null) {
      handleScroll({
        cursorTopY: state.cursor * ROW_HEIGHT,
        cursorHeight: ROW_HEIGHT,
        rootDivElement: reference.current,
      })
    }
  }, [state.cursor])
  if (state.filteredCommands.length === 0) {
    return (
      <div
        style={{
          padding: '16px 0px',
          fontSize: 12.8,
          color: '#656565',
        }}
        className="flex align-middle"
      >
        <Space style={{ paddingLeft: 30, height: 26 }}>
          <span style={{ fontSize: 14, letterSpacing: 1.05 }}>見つかりませんでした</span>
        </Space>
      </div>
    )
  }

  return (
    <div
      className="relative"
      style={{
        maxHeight: ROW_HEIGHT * (VISIBLE_ROW_NUM + LITTLE_VISIBLE_ROW_RATIO),
        overflowY: 'scroll',
        scrollbarColor: '#717171',
        // scrollbarWidth: "auto",
        color,
      }}
      ref={reference}
    >
      {}
      {state.filteredCommands.map((command, index) => {
        const isActive = hoveredIndex === undefined ? index === state.cursor : index === hoveredIndex
        const backgroundColor = isActive ? TAILWIND_CSS_COLORS.blue['50'] : 'inherit'

        return (
          <div
            style={{
              padding: '16px 0px',
              fontSize: 12.8,
              backgroundColor,
              height: ROW_HEIGHT,
            }}
            className="relative flex cursor-pointer justify-between align-middle"
            onMouseOver={() => {
              // setHoveredIndex(i)
              state.setCursor(index)
            }}
            onMouseOut={() => {
              // setHoveredIndex(undefined)
            }}
            onClick={() => {
              state.executeCommand(command)
            }}
          >
            {isActive && (
              <div
                className="absolute left-0 top-0 h-full"
                style={{
                  width: 3,
                  backgroundColor: '#4C82F7',
                }}
              />
            )}
            <Space style={{ paddingLeft: 30, height: 26 }}>
              <Avatar
                icon={command.icon}
                style={{ backgroundColor, border: `1px solid ${color}`, color, marginRight: 8 }}
                size={26}
              />
              <div style={{ lineHeight: 1.4 }}>
                <span style={{ fontSize: 14, letterSpacing: 1.05 }}>{command.title}</span>
                {command.subTitle !== undefined && (
                  <>
                    <br />
                    <span className="text-gray-400" style={{ fontSize: 10, letterSpacing: 1.05 }}>
                      {command.subTitle ?? ''}
                    </span>
                  </>
                )}
              </div>
            </Space>
            <Space className="mr-4">
              {command.loading === true && (
                <>
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#B8B9B9' }} spin />} />
                </>
              )}
              {(command.shortcut ?? []).map((keyText) => (
                <KeyIcon keyText={keyText} />
              ))}
            </Space>
          </div>
        )
      })}
    </div>
  )
}

// 引数をkeyにするとエラーになるので注意
function KeyIcon({ keyText }: { keyText: string }) {
  const key = getKeyText(keyText)
  return (
    <div
      style={{
        width: 19 + 5 * key.length,
        height: 24,
        // backgroundColor: 'rgba(0, 0, 0, .2)',
        border: '1px solid rgba(0, 0, 0, .5)',
        color,
        // fontFamily: AvenirBook;
        fontSize: '.7em',
        padding: '4px 5px',
        textAlign: 'center',
        borderRadius: 3,
        borderColor: `#bbb`,
      }}
      className="uppercase"
    >
      {key}
    </div>
  )
}

function getKeyText(key: string): string {
  const isWindows = platform.os?.family === 'Windows'
  switch (key) {
    case 'command': {
      return isWindows ? `Ctrl` : '⌘'
    }
  }

  return key
}

function handleScroll({
  cursorTopY,
  cursorHeight,
  rootDivElement,
}: {
  cursorTopY: number
  cursorHeight: number
  rootDivElement: HTMLDivElement
}): void {
  const cursorBottomY = cursorTopY + cursorHeight
  const areaHeight = rootDivElement.clientHeight
  const areaTopY = rootDivElement.scrollTop
  const areaBottomY = areaTopY + areaHeight

  if (areaBottomY <= cursorBottomY) {
    // カーソルが表示領域の下側にいってしまった場合
    rootDivElement.scrollTo({
      top: cursorBottomY - areaHeight + ROW_HEIGHT * LITTLE_VISIBLE_ROW_RATIO,
      behavior: undefined,
    }) // カーソルの下端が表示領域の下端となるようにする
  } else if (cursorTopY <= areaTopY) {
    // カーソルが表示領域の上側にいってしまった場合
    rootDivElement.scrollTo({ top: cursorTopY - ROW_HEIGHT * LITTLE_VISIBLE_ROW_RATIO, behavior: undefined })
  }
}

import { useRef } from 'react'

import { useEffectOnce } from './useEffectOnce'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useUnmount = (function_: () => any): void => {
  const functionReference = useRef(function_)

  // update the ref each render so if it change the newest callback will be invoked
  functionReference.current = function_

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return,unicorn/consistent-function-scoping
  useEffectOnce(() => () => functionReference.current())
}

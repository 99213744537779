import { z } from 'zod'

import { type CoreIcon, iconSchema } from '../icon'
import { dataEntrySchema } from './dataEntry'
import { formItemSchema, titleFormItemSchema } from './form'
import { kpiSchema } from './kpi'
import { kpiPivotSchema } from './kpiPivot'
import { kpiTimeSeriesSchema } from './kpiTimeSeries'
import { SheetSchema } from './sheet'

//
// root
//
export type ViewUIComponent =
  | ViewUIRow
  | ViewUICol
  | ViewUIForm
  | ViewUIFormList
  | ViewUIChildRecordsForm
  | z.infer<typeof formItemSchema>
  | z.infer<typeof titleFormItemSchema>
  | z.infer<typeof SheetSchema>
  | z.infer<typeof kpiSchema>
  | z.infer<typeof kpiPivotSchema>
  | z.infer<typeof dataEntrySchema>
  | z.infer<typeof kpiTimeSeriesSchema>
// export type ViewUIComponent = z.infer<typeof viewUiComponentSchema>

export const viewUiComponentSchema = z.lazy(() =>
  z.union([
    rowSchema,
    colSchema,
    formItemSchema,
    titleFormItemSchema,
    formListSchema,
    childRecordsFormSchema,
    formSchema,
    SheetSchema,
    kpiSchema,
    kpiPivotSchema,
    dataEntrySchema,
    kpiTimeSeriesSchema,
  ]),
)

export const viewUISchema = viewUiComponentSchema.array()
export type ViewUI = z.infer<typeof viewUISchema>

// form
export interface ViewUIForm {
  type: 'Form'
  label: string
  children: ViewUIComponent[]
  nameFieldName?: string
  recordUrlFieldName?: string
  icon?: CoreIcon
  color?: string
}

export const formSchema: z.ZodSchema<ViewUIForm> = z.lazy(() =>
  z.object({
    type: z.literal('Form'),
    label: z.string(),
    children: viewUISchema,
    nameFieldName: z.string().optional(),
    recordUrlFieldName: z.string().optional(),
    icon: iconSchema.optional(),
    color: z.string().optional(),
  }),
)

export interface ViewUIChildRecordsForm {
  type: 'ChildRecordsForm'
  label: string
  tableNodeName: string
  children: ViewUIComponent[]
  nameFieldName?: string
  recordUrlFieldName?: string
  relation?: 'many_to_one' | 'one_to_many' | 'one_to_one'
  icon?: CoreIcon
  color?: string
}

export const childRecordsFormSchema: z.ZodSchema<ViewUIChildRecordsForm> = z.lazy(() =>
  z.object({
    type: z.literal('ChildRecordsForm'),
    label: z.string(),
    tableNodeName: z.string(),
    children: viewUISchema,
    nameFieldName: z.string().optional(),
    recordUrlFieldName: z.string().optional(),
    relation: z.enum([`many_to_one`, `one_to_many`, 'one_to_one']).optional(),
    icon: iconSchema.optional(),
    color: z.string().optional(),
  }),
)

export interface ViewUIFormList {
  type: 'FormList'
  label: string
  path: string[]
  children: ViewUIComponent[]
}

export const formListSchema: z.ZodSchema<ViewUIFormList> = z.lazy(() =>
  z.object({
    type: z.literal('FormList'),
    label: z.string(),
    path: z.string().array(),
    children: viewUISchema,
  }),
)

// grid
export interface ViewUICol {
  type: 'Col'
  id: string
  span: number
  children: ViewUIComponent[]
}

export interface ViewUIRow {
  type: 'Row'
  id: string
  gutter?: number
  children: ViewUICol[]
  // justify: "space-between" // など
}

export const colSchema: z.ZodSchema<ViewUICol> = z.lazy(() =>
  z.object({
    type: z.literal('Col'),
    id: z.string(),
    span: z.number(),
    children: viewUISchema,
  }),
)

export const rowSchema: z.ZodSchema<ViewUIRow> = z.lazy(() =>
  z.object({
    type: z.literal('Row'),
    id: z.string(),
    gutter: z.number().optional(),
    children: colSchema.array(),
  }),
)

import { SALESCORE_USER_GROUP_MODEL, USER_GROUP_MODEL_ID_PROPERTY } from './models/SALESCORE_USER_GROUP_MODEL'
import { SALESCORE_USER_GROUPS_WITH_PARENTS_MODEL } from './models/SALESCORE_USER_GROUPS_WITH_PARENTS_MODEL'
import { SALESCORE_USER_MODEL, USER_MODEL_ID_PROPERTY } from './models/SALESCORE_USER_MODEL'
import { SALESCORE_USER_WITH_GROUP_MODEL } from './models/SALESCORE_USER_WITH_GROUP_MODEL'

export const PRESET_MODELS = {
  USER: {
    NAME: `salescore_users`,
    USER_GROUP_ID_PROPERTY_NAME: `user_group_id`,
    NAME_PROPERTY_NAME: `name`,
    ID_PROPERTY: USER_MODEL_ID_PROPERTY,
    MODEL: SALESCORE_USER_MODEL,
  },
  USER_GROUP: {
    NAME: `salescore_user_groups`,
    USER_GROUP_ID: `user_group_id`,
    ID_PROPERTY: USER_GROUP_MODEL_ID_PROPERTY,
    NAME_PROPERTY_NAME: `name`,
    MODEL: SALESCORE_USER_GROUP_MODEL,
  },
  USER_WITH_USER_GROUP: {
    NAME: `salescore_users_with_group`,
    ID_PROPERTY: USER_GROUP_MODEL_ID_PROPERTY,
    NAME_PROPERTY_NAME: `name`, // TODO
    MODEL: SALESCORE_USER_WITH_GROUP_MODEL,
  },
  USER_GROUPS_WITH_PARENTS: {
    NAME: `salescore_user_groups_with_parents`,
    ID_PROPERTY: USER_GROUP_MODEL_ID_PROPERTY,
    MODEL: SALESCORE_USER_GROUPS_WITH_PARENTS_MODEL,
  },
}

import { PRESET_MODELS } from '../../../../constant/presetModels'
import type { ModelProperty } from '../../../../schemas/model/modelProperty'
import type { NodePropertyName, ViewConfigKpi, ViewConfigTreeNode } from '../../../../schemas/view_config'

// ユーザーと関連するユーザーグループを紐付けてノードを生成する
export function generateSalescoreUserNode(
  user: ViewConfigKpi['user'],
  modelProperty: ModelProperty | undefined,
): ViewConfigTreeNode | undefined {
  if (user === undefined) {
    return undefined
  }

  return {
    type: `model`,
    name: PRESET_MODELS.USER_WITH_USER_GROUP.NAME,
    modelName: PRESET_MODELS.USER_WITH_USER_GROUP.NAME,
    referenceToProperty: {
      ...user.property,
      referenceTo: {
        modelName: PRESET_MODELS.USER_WITH_USER_GROUP.NAME,
        key: getSalescoreUsersJoinKey(user.joinAs, user.property, modelProperty),
      },
    },
  }
}

function getSalescoreUsersJoinKey(
  joinAs: 'id' | 'email' | 'name',
  nodeProperty: NodePropertyName,
  modelProperty: ModelProperty | undefined,
) {
  if (joinAs !== 'id') {
    return joinAs
  }

  // referenceが複数ある場合はsalesforce_userをpickする (polymorphicを完全にケアできるものではないがsalesforceに関してはこれで問題ないはず)
  const referenceToModelName =
    modelProperty?.referenceTo?.find((x) => x.modelName === 'salesforce_user') ?? modelProperty?.referenceTo?.first() // TODO: polymorphic relation
  if (modelProperty === undefined || referenceToModelName === undefined) {
    return `id`
  }

  // TODO: 固有のkeyも考慮する必要がある
  switch (referenceToModelName.modelName) {
    case 'salesforce_user': {
      return `salesforce_user_id`
    }
    case 'salesforce_group': {
      // TODO: polymorphicなものがこのレイヤに影響している？旧Visualizeからの移行時のみの問題？
      return `salesforce_user_id`
    }
    case 'hubspot_owners': {
      return `hubspot_owner_id`
    }
    case 'hubspot_users': {
      return `hubspot_user_id`
    }
    // 2023/03 hubspotのengagementのロジックが間違っており、以下のモデル名がreferenceToで生成されていそうなので一旦追加
    case 'hubspot__owners': {
      return `hubspot_owner_id`
    }
    case 'hubspot__users': {
      return `hubspot_user_id`
    }
    case 'zoho_user': {
      return `zoho_user_id`
    }
    case 'next_sfa_user_info': {
      return `next_sfa_user_id`
    }
    case 'google_sheets_users': {
      return `google_sheets_user_id`
    }
    case 'comdesk_staff': {
      return `comdesk_user_id`
    }
    case 'senses_users': {
      return `senses_user_id`
    }
    case 'microsoft_dynamics_systemusers': {
      return `microsoft_dynamics_user_id`
    }
    case 'big_query_users': {
      return `big_query_user_id`
    }
    case 'kintone_user': {
      return `kintone_user_id`
    }
    default: {
      return `id`
    }
  }
}

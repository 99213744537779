import { z } from 'zod'

const specialCharacterSchema = z.object({
  type: z.literal('specialCharacter'),
  value: z.enum([`*`]),
})
export type SpecialCharacter = z.infer<typeof specialCharacterSchema>

const literalSchema = z.object({
  type: z.literal('literal'),
  value: z.unknown(),
})
export type Literal = z.infer<typeof literalSchema>

const columnReferenceSchema = z.object({
  type: z.literal(`columnReference`), // columnといいつつ、asのaliasも含む
  tableName: z.string().nullable(),
  columnName: z.string(),
})
export type ColumnReference = z.infer<typeof columnReferenceSchema>

const tableReferenceSchema = z.object({
  type: z.literal(`tableReference`),
  tableName: z.string(),
})
export type TableReference = z.infer<typeof tableReferenceSchema>

const expressionAtomSchema = z.union([columnReferenceSchema, literalSchema, specialCharacterSchema])
const binaryOperatorSchema = z.object({
  type: z.literal(`binaryOperator`),
  operator: z.string(), // TODO
  left: expressionAtomSchema,
  right: expressionAtomSchema,
})
export type BinaryOperator = z.infer<typeof binaryOperatorSchema>
const expressionSchema1 = z.union([binaryOperatorSchema, expressionAtomSchema])
export type ExpressionWithNoNest = z.infer<typeof expressionSchema1>

// ZodがCyclical objectsに対応していないため、愚直にbinaryOperatorを書き連ねる
// TODO: ここに限界を感じたら、zodを捨ててtypeを直接書くと良い。
// https://github.com/colinhacks/zod#cyclical-objects
const binaryOperatorSchema2 = z.object({
  type: z.literal(`binaryOperator`),
  operator: z.string(), // TODO
  left: expressionSchema1,
  right: expressionSchema1,
})
const expressionSchema2 = z.union([binaryOperatorSchema2, expressionSchema1])

const binaryOperatorSchema3 = z.object({
  type: z.literal(`binaryOperator`),
  operator: z.string(), // TODO
  left: expressionSchema2,
  right: expressionSchema2,
})
const expressionSchema3 = z.union([binaryOperatorSchema3, expressionSchema2])

const expressionSchema = expressionSchema3
export type Expression = z.infer<typeof expressionSchema>

const aggregateFunctionSchema = z.object({
  type: z.literal(`aggregateFunction`),
  name: z.string(),
  expression: expressionSchema,
})
export type AggregateFunction = z.infer<typeof aggregateFunctionSchema>

//
// select
//
const limitSchema = z.object({
  type: z.literal(`limit`),
  count: z.number(),
})
export type Limit = z.infer<typeof limitSchema>

const offsetSchema = z.object({
  type: z.literal(`offset`),
  start: z.number(),
})
export type Offset = z.infer<typeof offsetSchema>

const orderSchema = z.object({
  type: z.literal(`order`),
  expression: expressionAtomSchema,
  order: z.enum([`asc`, `desc`]).nullable(),
})
const orderBySchema = z.object({
  type: z.literal(`orderBy`),
  orders: orderSchema.array(),
})
export type OrderBy = z.infer<typeof orderBySchema>

const expressionGroupSchema = z.object({
  type: z.literal('expressionGroup'),
  expression: expressionSchema,
})
const rollupGroupSchema = z.object({
  type: z.literal('rollupGroup'),
  expressionGroups: expressionGroupSchema.array(),
})
const groupSchema = z.union([expressionGroupSchema, rollupGroupSchema])
const groupBySchema = z.object({
  type: z.literal(`groupBy`),
  groups: groupSchema.array(),
})
export type GroupBy = z.infer<typeof groupBySchema>
const whereSchema = z.object({
  type: z.literal(`where`),
  expression: expressionSchema,
})
export type Where = z.infer<typeof whereSchema>
const joinSchema = z.object({
  type: z.literal(`join`),
  joinType: z.literal('LEFT'),
  table: tableReferenceSchema,
  as: z.string().nullable(),
  on: expressionSchema,
})
export type Join = z.infer<typeof joinSchema>
const fromSchema = z.object({
  type: z.literal(`from`),
  table: tableReferenceSchema,
  as: z.string().nullable(),
})
export type From = z.infer<typeof fromSchema>
const fieldSchema = z.object({
  type: z.literal(`field`),
  expression: z.union([expressionSchema, aggregateFunctionSchema]),
  as: z.string().nullable(),
})
export type Field = z.infer<typeof fieldSchema>

const selectCommandSchema = z.object({
  type: z.literal(`selectCommand`),
  fields: fieldSchema.array(),
  from: fromSchema,
  joins: joinSchema.array().nullable(),
  where: whereSchema.nullable(),
  groupBy: z.null(), // TODO: groupBySchema.nullable()により[TS7056]errorが発生するため一旦nullで回避
  orderBy: orderBySchema.nullable(),
  limit: limitSchema.nullable(),
  offset: offsetSchema.nullable(),
})
export type SelectCommand = z.infer<typeof selectCommandSchema>

const setCommandSchema = z.object({
  type: z.literal('setCommand'),
  body: z.string(),
})

//
// commands
//
const commandSchema = z.union([selectCommandSchema, setCommandSchema])
export type Command = z.infer<typeof commandSchema>
export const commandsSchema = commandSchema.array()

// Cyclical objectsの関係でもはや無理なので、定義を諦めた
const caseSchema = z.object({
  type: z.literal(`case`),
  whens: z
    .object({
      condition: binaryOperatorSchema2,
      value: binaryOperatorSchema2,
    })
    .array(),
  else: z
    .object({
      value: binaryOperatorSchema2,
    })
    .optional(),
})
export type CaseWhen = z.infer<typeof caseSchema>

const functionSchema = z.object({
  type: z.literal(`function`),
  name: z.string(),
  expressions: expressionSchema.array(),
})
export type FunctionType = z.infer<typeof functionSchema>

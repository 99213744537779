export function exec<T extends { children?: T[] }>(node: T, f: (x: T) => T | undefined): T | undefined {
  const executed = f(node)
  if (executed === undefined) {
    return undefined
  }

  return {
    ...executed,
    children: (node.children ?? []).map((child) => exec(child, f)).compact(),
  }
}

import { isNull, isSome } from '@salescore/buff-common'

import {
  type GenerateSearchSqlForStreamArgument,
  generateSearchSqlMustache,
  orderByLength,
  SEARCH_RESULT_LABEL_COLUMN_NAME,
  SEARCH_RESULT_META_LABEL_COLUMN_NAME,
  SEARCH_RESULT_VALUE_COLUMN_NAME,
} from './common'

export function generateSearchSqlForSalesforceContact(argument: GenerateSearchSqlForStreamArgument) {
  const { model, parentModel, parentNode, context } = argument
  const contactStream = model
  if (parentModel.write?.sourceEntityName === 'OpportunityContactRole') {
    const res = generateSearchSqlForSalesforceContactFromOpportunityContactRole(argument)
    if (res !== undefined) {
      return res
    }
  }

  const nameProperty = contactStream.properties.find((x) => x.meta === 'name')
  const labelField = nameProperty?.name ?? 'id'
  const accountStream = context.modelSearcher.searchModel(`salesforce_account`) // TODO: source nameで検索するか？
  const contactAccountIdColumn = contactStream.properties.find((x) => x.write?.sourcePropertyName === 'AccountId')
  const accountNameProperty = accountStream?.properties.find((x) => x.meta === 'name')
  if (isNull(accountStream) || isNull(contactAccountIdColumn) || isNull(accountNameProperty) || isNull(nameProperty)) {
    return
  }

  const referencingAccountProperties = parentModel.properties.filter(
    (x) => x.referenceTo?.first()?.modelName === 'salesforce_account', // TODO: これはもうどうしようもない？
  )
  const referencingAccountPropertyConditions = referencingAccountProperties.map(
    (property) =>
      // TODO: field nameの取得
      // TODO: field(attributes)がなくとも参照できるよう、record.idで絞り込んで残りはテーブル名で絞り込むか？
      `('{{ record.attributes.${parentNode.name}_${property.name} }}' IN ('', account.id))`,
  )
  const conditions = [
    generateSearchSqlMustache(`CONCAT(contact."${labelField}", ' - ', account.name)`),
    referencingAccountPropertyConditions.isPresent()
      ? `(${referencingAccountPropertyConditions.join(' OR ')})`
      : undefined,
  ].compact()

  const searchSql = `SELECT
contact.id as ${SEARCH_RESULT_VALUE_COLUMN_NAME},
contact."${labelField}" as ${SEARCH_RESULT_LABEL_COLUMN_NAME},
account."${accountNameProperty.name}" as ${SEARCH_RESULT_META_LABEL_COLUMN_NAME}
FROM "${contactStream.name}" as contact
LEFT JOIN "${accountStream.name}" as account
ON account.id = contact.${contactAccountIdColumn.name}
WHERE ${conditions.join(' AND ')}
`
  return {
    sql: orderByLength(searchSql, `contact."${labelField}"`),
    dependedPropertyNames: [
      {
        streamName: contactStream.name,
        propertyName: nameProperty.name,
      },
      {
        streamName: accountStream.name,
        propertyName: accountNameProperty.name,
      },
    ],
  }
}

export function generateSearchSqlForSalesforceContactFromOpportunityContactRole({
  model,
  parentModel,
  parentNode,
  context,
}: GenerateSearchSqlForStreamArgument) {
  const contactStream = model
  // const opportunityContactRoleStream = parentModel
  const opportunityContactRoleNode = parentNode

  const nameProperty = contactStream.properties.find((x) => x.meta === 'name')
  const labelField = nameProperty?.name ?? 'id'
  const contactAccountIdColumn = contactStream.properties.find((x) => x.write?.sourcePropertyName === 'AccountId')
  const opportunityStream = context.modelSearcher.searchModel(`salesforce_opportunity`) // TODO
  const opportunityAccountIdColumn = opportunityStream?.properties.find(
    (x) => x.write?.sourcePropertyName === 'AccountId',
  )
  if (
    isNull(contactAccountIdColumn) ||
    isNull(nameProperty) ||
    isNull(opportunityStream) ||
    isNull(opportunityAccountIdColumn)
  ) {
    return
  }

  const accountStream = context.modelSearcher.searchModel(`salesforce_account`) // TODO
  const accountNameProperty = accountStream?.properties.find((x) => x.meta === 'name')

  const fields = [
    `contact.id as ${SEARCH_RESULT_VALUE_COLUMN_NAME}`,
    `contact."${labelField}" as ${SEARCH_RESULT_LABEL_COLUMN_NAME}`,
    accountNameProperty === undefined
      ? undefined
      : `account."${accountNameProperty.name}" as ${SEARCH_RESULT_META_LABEL_COLUMN_NAME}`,
  ].compact()
  const join = [
    accountStream === undefined
      ? undefined
      : `LEFT JOIN "${accountStream.name}" as account ON account.id = contact.${contactAccountIdColumn.name}`,
  ].compact()

  const parentParentNodeName = `salesforce_opportunity` // TODO: これ以外の場合にparentRecordでの取得が機能しない
  const conditions = [
    accountStream === undefined
      ? generateSearchSqlMustache(`contact."${labelField}"`)
      : generateSearchSqlMustache(`CONCAT(contact."${labelField}", ' - ', account.name)`),
    `(contact.${contactAccountIdColumn.name} IN (select * from opportunity_account_ids) OR NOT exists(select * from opportunity_account_ids))`,
    `'{{ parentRecord.attributes.${parentParentNodeName}_account_id }}' IN (contact.${contactAccountIdColumn.name}, '')`,
  ]
  const searchSql = `
WITH opportunity_account_ids AS (
  SELECT "${opportunityAccountIdColumn.name}" 
  FROM "${opportunityStream.name}" as opportunity
  WHERE 
  opportunity.id = '{{ record.attributes.${opportunityContactRoleNode.name}_opportunity_id }}'
  AND
  "${opportunityAccountIdColumn.name}" is NOT NULL
  AND
  "${opportunityAccountIdColumn.name}" != ''
)

SELECT
${fields.join(', ')}
FROM "${contactStream.name}" as contact
${join.join(`\n`)}
WHERE
${conditions.join(' AND ')}
`
  return {
    sql: orderByLength(searchSql, `contact."${labelField}"`),
    dependedPropertyNames: [
      {
        streamName: contactStream.name,
        propertyName: nameProperty.name,
      },
      isSome(accountStream) && isSome(accountNameProperty)
        ? {
            streamName: accountStream.name,
            propertyName: accountNameProperty.name,
          }
        : undefined,
    ].compact(),
  }
}

import { App, Layout } from 'antd'
import type { AppProps } from 'next/app'
import type { ReactNode } from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import { HandleQuery } from '../../common/HandleQuery'
import { AuthenticatedApolloProvider } from '../../providers/AuthenticatedApolloProvider'
import { I18nProvider } from '../../providers/I18nProvider'
import { GlobalLauncher } from '../launcher/GlobalLauncher'
import { Sider } from './Sider'

export const DefaultLayout = (properties: AppProps): ReactNode => (
  <AuthenticatedApolloProvider>
    <App component={false}>
      <Content {...properties} />
    </App>
  </AuthenticatedApolloProvider>
)

function Content(properties: AppProps): ReactNode {
  const { Component, router } = properties

  return (
    <>
      <Layout style={{ height: '100%' }} hasSider>
        <Sider />
        <Layout.Content className="overflow-scroll-layout-content overflow-visible-print p-5 pb-16">
          <SwitchTransition>
            <CSSTransition key={router.asPath} timeout={200} classNames="page-transition">
              <I18nProvider>
                <HandleQuery>
                  <Component />
                </HandleQuery>
              </I18nProvider>
            </CSSTransition>
          </SwitchTransition>
        </Layout.Content>
      </Layout>
      <GlobalLauncher />
    </>
  )
}

import { isNull, parseJsonIfValid } from '@salescore/buff-common'
import type { AtomEffect } from 'recoil'
import { z, type ZodSchema } from 'zod'

import { addQueryParameterToUrl, getQueryParameter, removeQueryParameterToUrl } from '../misc/util2'

export const localStorageEffect: <T>(key: string, schema: ZodSchema<T>) => AtomEffect<T> =
  (key, schema) =>
  ({ setSelf, onSet }) => {
    const keyWithPrefix = `recoil/${key}`
    const savedValue = localStorage.getItem(keyWithPrefix)
    if (savedValue !== null) {
      const x = schema.safeParse(parseJsonIfValid(savedValue))
      if (x.success) {
        setSelf(x.data)
      }
    }

    onSet((newValue, _, isReset) => {
      isReset ? localStorage.removeItem(keyWithPrefix) : localStorage.setItem(keyWithPrefix, JSON.stringify(newValue))
    })
  }

export const localStorageWithQueryParameterEffect: (
  key: string,
  query: string,
) => AtomEffect<string | null | undefined> =
  (key, query) =>
  ({ setSelf, onSet }) => {
    const keyWithPrefix = `recoil/${key}`
    const queryValue = getQueryParameter(query)
    if (queryValue === null) {
      const savedValue = localStorage.getItem(keyWithPrefix)
      if (savedValue !== null) {
        const x = z.string().safeParse(savedValue)
        if (x.success) {
          setSelf(x.data)
          addQueryParameterToUrl(query, x.data)
        }
      }
    } else {
      setSelf(queryValue)
    }

    onSet((newValue, _, isReset) => {
      if (isReset || isNull(newValue)) {
        localStorage.removeItem(keyWithPrefix)
        removeQueryParameterToUrl(query)
      } else {
        localStorage.setItem(keyWithPrefix, newValue)
        addQueryParameterToUrl(query, newValue)
      }
    })
  }

export const queryParameterEffect: (query: string) => AtomEffect<string | null> =
  (query) =>
  ({ setSelf, onSet }) => {
    const queryValue = getQueryParameter(query)
    if (queryValue !== null) {
      setSelf(queryValue)
    }

    onSet((newValue, _, isReset) => {
      if (isReset || isNull(newValue)) {
        removeQueryParameterToUrl(query)
      } else {
        addQueryParameterToUrl(query, newValue)
      }
    })
  }

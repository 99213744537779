import type { ItemNode } from './createTreesFromItems'

export function findNodeFromTrees<T>(trees: Array<ItemNode<T>>, nodeId: string): ItemNode<T> | undefined {
  for (const tree of trees) {
    const node = dfs(tree, nodeId)
    if (node !== undefined) {
      return node
    }
  }
  return undefined
}

function dfs<T>(node: ItemNode<T>, nodeId: string): ItemNode<T> | undefined {
  if (node.id === nodeId) {
    return node
  }
  for (const child of node.children) {
    const result = dfs(child, nodeId)
    if (result !== undefined) {
      return result
    }
  }
  return undefined
}

import { z } from 'zod'

import { nodePropertyNameSchema } from './common'
import { type ViewConfigField, viewConfigFieldSchema } from './field'
import { viewConfigFilterNodeSchema } from './filter'
import { viewConfigTreeNodeSchema } from './tree'

export const viewConfigSorterSchema = z.object({
  type: z.literal(`property`), // customも許してunionにする想定
  property: nodePropertyNameSchema,
  order: z.enum(['asc', 'desc']),
})
export type ViewConfigSorter = z.infer<typeof viewConfigSorterSchema>

const viewConfigMetaSchema = z.object({
  fixedLeftColumnIndex: z.number().optional(),
  visibleRowNum: z.number().optional(),
  searchQuery: z.string().optional(),
  isGoalView: z.boolean().optional(), // 目標設定で使う際は、特別な表示をする。こんな設定をいれたくないが、仕方なさそう
  shouldJoinRecordsInApplication: z.boolean().optional(), // アプリケーションジョインを使うかどうか。デフォルトはfalse。
})
export type ViewConfigMeta = z.infer<typeof viewConfigMetaSchema>

const sheetPresetSchema = z.object({
  name: z.string(), // unique
  tree: viewConfigTreeNodeSchema.optional(),
  fields: viewConfigFieldSchema.array().optional(),
  filterTree: viewConfigFilterNodeSchema.optional(),
  sorters: viewConfigSorterSchema.array().optional(),
  meta: viewConfigMetaSchema.optional(),
})
export type ViewConfigSheetPreset = z.infer<typeof sheetPresetSchema>

export const viewConfigSheetExtraPeriodSchema = z.object({
  startAt: z.string().optional(),
  endAt: z.string().optional(),
  name: z.string(),
  nameLabel: z.string().optional(),
  groupName: z.string().optional(),
  groupNameLabel: z.string().optional(),
})
export type ViewConfigSheetExtraPeriod = z.infer<typeof viewConfigSheetExtraPeriodSchema>

const viewConfigSheetExtraSchema = z.object({
  // 現状目標入力画面でのみ使用。期間設定
  period: viewConfigSheetExtraPeriodSchema.optional(),
})
export type ViewConfigSheetExtra = z.infer<typeof viewConfigSheetExtraSchema>

//
// config
//
export const ViewConfigSheetSchema = z.object({
  type: z.literal(`sheet`),
  tree: viewConfigTreeNodeSchema.optional(), // 未設定のとき、プロパティは存在しない。設定済みの時は必須
  fields: viewConfigFieldSchema.array().optional(), // 同上
  filterTree: viewConfigFilterNodeSchema.optional(),
  sorters: viewConfigSorterSchema.array().optional(),
  presets: sheetPresetSchema.array().optional(),
  meta: viewConfigMetaSchema.optional(),
  extra: viewConfigSheetExtraSchema.optional(),
})
export type ViewConfigSheet = z.infer<typeof ViewConfigSheetSchema>

export interface ObjectPermission {
  creatable: boolean
  updatable: boolean
  deletable: boolean
}

export const addOverrideOptionToViewConfig = async (
  viewConfigSheet: ViewConfigSheet,
  modelNameToPermission: (modelName: string) => Promise<ObjectPermission | undefined>,
): Promise<ViewConfigSheet> => ({
  ...viewConfigSheet,
  fields: await Promise.all(
    (viewConfigSheet.fields ?? []).map(async (field: ViewConfigField): Promise<ViewConfigField> => {
      const permission = await modelNameToPermission(field.property.modelName)
      if (permission === undefined) {
        return field
      }
      return {
        ...field,
        override: {
          uiColumn: {
            creatable: permission.creatable,
            updatable: permission.updatable,
            deletable: permission.deletable,
          },
        },
      }
    }),
  ),
})

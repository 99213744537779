import { toRecord } from '../../util/record'
import type {
  BinaryOperator,
  CaseWhen,
  ColumnReference,
  Expression,
  ExpressionWithNoNest,
  FunctionType,
} from '../parser/grammer_schema'
import type { BaseTableRecord, ExpressionContext } from './types'

export function expression(exp: Expression, record: BaseTableRecord, context: ExpressionContext): unknown {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  const e = exp as unknown as ExpressionWithNoNest // ネストさせている都合で、後続の処理が型レベルで微妙になるので、ここで強引に変換する
  const { type } = e
  switch (type) {
    case 'binaryOperator': {
      return binaryOperator(e, record, context)
    } // ネストさせている都合でasが必要
    case 'columnReference': {
      return columnReference(e, record, context)
    }
    case 'literal': {
      return e.value
    }
    case 'specialCharacter': {
      return e.value
    } // TODO: count(*)の形式にのみ対応。select * from ... には対応しない。
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const x: never = type
      // TODO: case whenをzodに落とし込めなかったので、ここで対応している
      if (type === 'case') {
        return caseWhen(e as CaseWhen, record, context)
      }
      if (type === 'function') {
        return functionExpression(e as FunctionType, record, context)
      }
      throw new Error(`not implemented`)
    }
  }
}

function functionExpression(exp: FunctionType, record: BaseTableRecord, context: ExpressionContext): unknown {
  const name = exp.name.toLowerCase()
  switch (name) {
    case 'grouping': {
      return 0 // 現状のロジックでexpressionが評価されるタイミングでは、常に0を返すのが正しい
    }
    default: {
      throw new Error(`not implemented`)
    }
  }
}

function caseWhen(exp: CaseWhen, record: BaseTableRecord, context: ExpressionContext): unknown {
  for (const w of exp.whens) {
    const conditionValue = expression(w.condition, record, context)
    if (conditionValue === true) {
      // TODO
      return expression(w.value, record, context)
    }
  }
  if (exp.else !== undefined) {
    return expression(exp.else.value, record, context)
  }
  return null
}

export function expressionFieldName(exp: Expression): string {
  switch (exp.type) {
    case 'binaryOperator': {
      return `binary`
    } // TODO
    case 'columnReference': {
      return exp.columnName
    }
    case 'literal': {
      return `literal`
    } // TODO
    default: {
      // const _x: never = exp.type // ネストさせている都合でうまくいかない
      // TODO: case whenをzodに落とし込めなかったので、ここで対応している
      if ((exp.type as unknown) === 'case') {
        return `case`
      }
      throw new Error(`expression not implemented`)
    }
  }
}

export function binaryOperator(exp: BinaryOperator, record: BaseTableRecord, context: ExpressionContext): boolean {
  const left = expression(exp.left, record, context)
  const right = expression(exp.right, record, context)
  const rightWithType = forceTypeToRight(left, right)

  const isBoolean = typeof left === 'boolean' && typeof rightWithType === 'boolean'
  const isString = typeof left === 'string' && typeof rightWithType === 'string'
  const isNumber = typeof left === 'number' && typeof rightWithType === 'number'
  const isDate = left instanceof Date && rightWithType instanceof Date
  const isNull = left === null && rightWithType === null
  if (!isString && !isNumber && !isDate && !isBoolean && !isNull) {
    // TODO: 型が違う際の実装
    return false
  }

  if (left === null || rightWithType === null) {
    switch (exp.operator) {
      case '=': {
        return left === rightWithType
      }
      case '!=': {
        return left !== rightWithType
      }
      case 'IS': {
        return left === rightWithType
      }
      case 'IS NOT': {
        return left !== rightWithType
      }
      default: {
        throw new Error(exp.operator)
      }
    }
  }

  switch (exp.operator) {
    case '=': {
      return left === rightWithType
    }
    case '!=': {
      return left !== rightWithType
    }
    case '>': {
      return left > rightWithType
    }
    case '>=': {
      return left >= rightWithType
    }
    case '<': {
      return left < rightWithType
    }
    case '<=': {
      return left <= rightWithType
    }
    case 'AND': {
      return isTruthy(left) && isTruthy(rightWithType)
    }
    case 'OR': {
      return isTruthy(left) || isTruthy(rightWithType)
    }
    case 'IS': {
      return left === rightWithType
    }
    case 'IS NOT': {
      return left !== rightWithType
    }
    case 'LIKE': {
      if (isString) {
        const regexp = new RegExp(rightWithType.replaceAll('%', '.*'))
        return regexp.test(left)
      }
      throw new Error(`${exp.operator} operator must apply to string, not ${typeof left}, ${typeof rightWithType}`)
    }
    default: {
      throw new Error(`not implemented operator ${exp.operator}`)
    }
  }
}

function isTruthy(x: unknown): boolean {
  if (typeof x === 'boolean') {
    return x
  }
  throw new Error(`argument of AND/OR must be type boolean, not type ${typeof x}`)
}

// companies.created_on = '2022-01-01' のような、左辺と右辺の型が異なるケースで、
// 強制的に右辺の型を変更する関数。（実用上はこれ以外のパターンがないと仮定した雑な対応）
function forceTypeToRight(left: unknown, right: unknown) {
  if (left instanceof Date && typeof right === 'string') {
    return new Date(right)
  }

  return right
}

export function columnReference(exp: ColumnReference, record: BaseTableRecord, context: ExpressionContext): unknown {
  if (exp.tableName === null) {
    // 一致するasがあるか確認
    const alias = context.fields.find((field) => field.as === exp.columnName)
    if (alias !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
      return expression(alias.expression as unknown as Expression, record, context) // TODO: なぜasが必要かわかっていない
    }
    // 一致するカラムがあるか、先頭から確認
    const tableAndColumns = toRecord(record).toArray()
    const matches = tableAndColumns.filter(
      ([_tableName, columnAndValues]) => columnAndValues[exp.columnName] !== undefined,
    )
    if (matches.length > 1) {
      throw new Error(`ambiguous`)
    }
    const match = matches[0]
    if (match === undefined) {
      throw new Error(
        `テーブル名が省略されたため、一致するエイリアス・カラムを探索しましたが見つかりませんでした。${exp.columnName}`,
      )
    }
    return match[1][exp.columnName]
  }

  // TODO: テーブル、カラムの存在確認

  const table = record[exp.tableName]
  if (table === undefined) {
    return null
  }
  const value = table[exp.columnName]
  if (value === undefined) {
    return null
  }
  return value
}

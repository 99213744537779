import { ResponsiveBar } from '@nivo/bar'

import { numberWithDelimiterFilter } from '../../misc/filters'

type Data = Array<Record<string, number | string>>

export const TimeSeriesChart = ({ indexBy, keys, data }: { indexBy: string; keys: string[]; data: Data }) => {
  const legendVisibility = !(keys.length === 1 && keys[0] === ' ')
  // FYI: https://nivo.rocks/bar/
  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy={indexBy}
      margin={{ top: 50, right: 230, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: 'linear' }}
      // indexScale={{ type: 'band', round: true }}
      colors={{ scheme: 'set3' }}
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisTop={undefined}
      axisRight={undefined}
      labelSkipHeight={12}
      valueFormat={(x) =>
        // 棒グラフ内の値
        numberWithDelimiterFilter(x)
      }
      labelFormat={(x) =>
        // 何に使われてるのか不明
        numberWithDelimiterFilter(x)
      }
      legends={
        legendVisibility
          ? [
              {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 160,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 150,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]
          : []
      }
      animate={true}
      // motionStiffness={90}
      // motionDamping={15}
    />
  )
}

import { isNull } from '@salescore/buff-common'

import type { NodePath, ViewQueryRecordNode } from '../../schemas/query'
import { getRecordNodesByPath, type ViewQueryRecordNodeWithParent } from '../util/recordNodeUtil'

export interface SheetCell {
  value: unknown
  label?: unknown // 現状はCSV作成のときのみで利用。各所でlabelを求めてしまっているので統一したい
  height: number
  innerRowIndexStart: number
  innerRowIndexEnd: number
  recordNode: undefined | ViewQueryRecordNodeWithParent
  parentRecordNode: undefined | ViewQueryRecordNodeWithParent // rootのときにundefined
}

type SheetCellNode = Omit<SheetCell, 'value'>

export interface SheetCellGroup {
  uiColumn: UiColumn | null
  innerCells: SheetCell[]
  height: number
}

// ツリー構造であるRecordNodeを、シートの1行の形に変更したもの
// シート行はカラムごとに情報を持ち、カラムは内部行(=セル)から構成される。
export interface SheetRow {
  height: number
  columns: SheetCellGroup[]
}

type UiColumn = {
  nodePath: NodePath
  fieldName?: string // fieldNameがないケースは、systemColumnのときのみを想定
  selectOptions?: Array<{ label: string; value: string | number }> // 現状はCSV作成のときのみで利用
} | null

export function convertRecordNodeToSheetRow(record: ViewQueryRecordNode, uiColumns: UiColumn[]): SheetRow {
  const columns: SheetCellGroup[] = uiColumns.map((uiColumn): SheetCellGroup => {
    if (uiColumn === null) {
      return {
        uiColumn,
        innerCells: [],
        height: 1,
      }
    }

    const sheetCellNodes = getRecordNodesWithInnerRowIndex(record, uiColumn.nodePath, false) // TODO
    return {
      uiColumn,
      innerCells: sheetCellNodes.map((x) => {
        const value = uiColumn.fieldName === undefined ? null : x.recordNode?.attributes[uiColumn.fieldName]

        const getLabel = () => {
          if (uiColumn.fieldName === undefined) {
            return null
          }
          const options = uiColumn.selectOptions ?? []
          if (options.isBlank()) {
            return x.recordNode?.attributes[`${uiColumn.fieldName}_label`]
          }
          if (Array.isArray(value)) {
            // eslint-disable-next-line max-nested-callbacks
            return value.map((v) => options.find((x) => x.value === v)?.label)
          }
          return uiColumn.selectOptions?.find((x) => x.value === value)?.label
        }

        return {
          ...x,
          value,
          label: getLabel(),
        }
      }),
      height: sheetCellNodes.map((x) => x.innerRowIndexEnd).max() ?? 1,
    }
  })

  return {
    height: columns.map((x) => x.height).max() ?? 1,
    columns,
  }
}

function getRecordNodesWithInnerRowIndex(
  rootNode: ViewQueryRecordNode,
  nodePath: NodePath,
  shouldUseParentHeight: boolean, // relationがparentのとき
): SheetCellNode[] {
  // TODO: なぜかrowがないことがありうるようなので、検査する
  if (isNull(rootNode)) {
    return []
  }

  // rootのとき
  if (nodePath.length === 1) {
    return [
      {
        innerRowIndexStart: 0,
        innerRowIndexEnd: rootNode.meta.height,
        recordNode: rootNode,
        parentRecordNode: undefined,
        height: rootNode.meta.height,
      },
    ]
  }

  const parentRecordNodes = getParentRecordNodes(rootNode, nodePath)
  if (parentRecordNodes === undefined) {
    // 親が一切いない子など
    return []
  }

  const recordNodesWithIndex = parentRecordNodes.flatMap((parentRecordNode): SheetCellNode[] => {
    // 子がいない場合、空セルを親の高さで作る
    if (parentRecordNode.children.isEmpty()) {
      return [
        {
          innerRowIndexStart: parentRecordNode.parent.meta.innerRowIndexStart,
          innerRowIndexEnd: parentRecordNode.parent.meta.innerRowIndexEnd,
          recordNode: undefined,
          parentRecordNode: parentRecordNode.parent,
          height: parentRecordNode.parent.meta.height,
        },
      ]
    }

    // parentな子が1つだけある場合、親の高さで作る
    if (shouldUseParentHeight && parentRecordNode.children.length === 1) {
      return [
        {
          innerRowIndexStart: parentRecordNode.parent.meta.innerRowIndexStart,
          innerRowIndexEnd: parentRecordNode.parent.meta.innerRowIndexEnd,
          recordNode: parentRecordNode.children.first()!,
          parentRecordNode: parentRecordNode.parent,
          height: parentRecordNode.parent.meta.height,
        },
      ]
    }

    return parentRecordNode.children.map((recordNode) => ({
      innerRowIndexStart: recordNode.meta.innerRowIndexStart,
      innerRowIndexEnd: recordNode.meta.innerRowIndexEnd,
      recordNode,
      parentRecordNode: parentRecordNode.parent,
      height: recordNode.meta.height,
    }))
  })

  // 親がいないセルに対して、blankCellを描画するために穴埋めする
  return addBlankCell(recordNodesWithIndex, rootNode.meta.innerRowIndexEnd)
}

function addBlankCell(nodesWithIndex: SheetCellNode[], rowInnerRowIndexEnd: number): SheetCellNode[] {
  const result: SheetCellNode[] = []
  for (const nodeWithIndex of nodesWithIndex) {
    const lastIndex = result.last()?.innerRowIndexEnd ?? 0
    if (nodeWithIndex.innerRowIndexStart === lastIndex) {
      result.push(nodeWithIndex)
    } else {
      const emptyCell: SheetCellNode = {
        innerRowIndexStart: lastIndex,
        innerRowIndexEnd: nodeWithIndex.innerRowIndexStart,
        recordNode: undefined,
        parentRecordNode: undefined,
        height: nodeWithIndex.innerRowIndexStart - lastIndex,
      }
      result.push(emptyCell, nodeWithIndex)
    }
  }
  const lastIndex = result.last()?.innerRowIndexEnd
  if (lastIndex !== undefined && lastIndex !== rowInnerRowIndexEnd) {
    result.push({
      innerRowIndexStart: lastIndex,
      innerRowIndexEnd: rowInnerRowIndexEnd,
      recordNode: undefined,
      parentRecordNode: undefined,
      height: rowInnerRowIndexEnd - lastIndex,
    })
  }

  return result
}

function getParentRecordNodes(rootNode: ViewQueryRecordNode, nodePath: string[]) {
  const parentRecordNodes = getRecordNodesByPath(rootNode, nodePath.slice(0, -1))
  return parentRecordNodes.map((parent) => {
    const children = parent.children.find((tableNode) => tableNode.nodeName === nodePath.last()!)?.children ?? []
    return {
      parent,
      children: children.map((child) => ({
        ...child,
        parent,
      })),
    }
  })
}

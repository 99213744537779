import { z } from 'zod'

import {
  propertyMetaSchema,
  propertyTypeSchema,
  selectOptionSchema as propertySelectOptionSchema,
} from '../model/modelProperty'
//
// enum
//
export const fieldTypeSchema = propertyTypeSchema
export type FieldType = z.infer<typeof fieldTypeSchema>

export const fieldMetaSchema = propertyMetaSchema
export type FieldMeta = z.infer<typeof fieldMetaSchema>

//
// parts
//
export const nodePathSchema = z.array(z.string())
export type NodePath = z.infer<typeof nodePathSchema>

export const selectOptionSchema = propertySelectOptionSchema
export type SelectOption = z.infer<typeof selectOptionSchema>

export const propertyNameWithStreamNameSchema = z.object({
  streamName: z.string(),
  propertyName: z.string(),
})
export type PropertyNameWithStreamName = z.infer<typeof propertyNameWithStreamNameSchema>

import { z } from 'zod'

import { iconSchema } from '../icon'
import { modelPropertySchema, validationRuleSchema } from './modelProperty'

export const coreModelIndexSchema = z.object({
  name: z.string(),
  columnNames: z.array(z.string()),
  unique: z.boolean().optional(),
})
export type CoreModelIndex = z.infer<typeof coreModelIndexSchema>

export const coreModelSchema = z.object({
  name: z.string(),
  label: z.string(),
  creatable: z.boolean().optional().nullable(), // デフォルトでtrue
  updatable: z.boolean().optional().nullable(), // デフォルトでtrue
  deletable: z.boolean().optional().nullable(), // デフォルトでtrue
  properties: modelPropertySchema.array(),
  icon: iconSchema.optional(),
  color: z.string().optional(), // #FFFFFFの形式
  write: z
    .object({
      sourceEntityName: z.string(),
      // 2023/06 salescore系モデルのみで使用。もともとrawに書き込んでからtransformする形にしていたが、このフラグがtrueであれば直接transform後のテーブルに書き込む
      //         rawに書き込んでからtransformする形がやはりやりづらいので、最終的にこの形のみにしたい
      shouldWriteToNormalizedTable: z.boolean().optional(),
    })
    .optional()
    .nullable(),
  // NOTE: validationはpropertyにもstreamにも記述できる。validation時はどちらのvalidationも全て実行される。
  //       システム的には使い分けには意味がなく、可読性のためのみで使い分ける。（単一のpropertyに対するvalidationはproperty側に、複数のproerptyのときにstream側）
  validations: validationRuleSchema.array().optional(),
  // NOTE: インデックスについて
  //       Destinationの操作という面ではtransformにいれるのが適切な項目だが、以下の理由よりmodel内に記述する。
  //       ・transformのinvalidateの操作が多少複雑になる。indexを増やす度に、transformが走ってしまうことは避けたい
  //       ・インデックスの情報はゆくゆくユーザーに提供したい。「インデックスが貼られてないため、表示が遅くなります」みたいな警告を出したい。
  indices: coreModelIndexSchema.array().optional().nullable(),
})
export type CoreModel = z.infer<typeof coreModelSchema>

import { z } from 'zod'

import { fieldMetaTypes, fieldTypes, viewUiComponentTypeSchema } from './enum'

const measureCellSchema = z.object({
  name: z.string(),
  label: z.string(),
})
export type ViewUISheetColumnMeasureCell = z.infer<typeof measureCellSchema>

const dynamicSelectOptionConfigSchema = z.object({
  type: z.literal(`sqlArray`),
  sql: z.string(),
  valuesColumn: z.string(),
})
export type DynamicSelectOptionConfig = z.infer<typeof dynamicSelectOptionConfigSchema>

export const viewUiSheetColumnSchema = z.object({
  fieldName: z.string(),
  label: z.string(),
  description: z.string().optional(),
  rank: z.number(),
  width: z.number(),
  fieldType: fieldTypes,
  fieldMetaType: fieldMetaTypes.optional(),
  selectOptions: z
    .object({
      label: z.string(),
      value: z.union([z.string(), z.number()]),
      color: z.string().optional().nullable(),
      icon: z.string().optional().nullable(),
    })
    .array()
    .optional(),
  creatable: z.boolean().optional(), // デフォルトでtrue
  updatable: z.boolean().optional(), // デフォルトでtrue
  deletable: z.boolean().optional(), // デフォルトでtrue
  required: z.boolean().optional(), // デフォルトでfalse
  visible: z.boolean().optional(), // デフォルトで true
  columnDeletable: z.boolean().optional(), // デフォルトでtrue
  measures: measureCellSchema.array().optional(),
  // 以下、特殊なプロパティ
  // NOTE: 今後unionになる予定
  dynamicSelectOptionsConfig: dynamicSelectOptionConfigSchema.optional(),
})
export type ViewUiSheetColumn = z.infer<typeof viewUiSheetColumnSchema>

export const SheetSchema = z.object({
  type: z.literal(viewUiComponentTypeSchema.enum.Sheet),
  columns: viewUiSheetColumnSchema.array(),
  fixedLeftColumnIndex: z.number().optional(), // 左固定列の右端のindex
  visibleRowNum: z.number().optional(),
  isGoalView: z.boolean().optional(),
})
export type ViewUISheet = z.infer<typeof SheetSchema>

import { z } from 'zod'

import { dataEntryCommonSchema, dataEntrySchema } from './dataEntry'
import { viewUiComponentTypeSchema } from './enum'

//
// form
//
// もはやラベル表示、エラー表示ロジックの共通化のためにしか使っていない
export const formItemSchema = dataEntryCommonSchema.merge(
  z.object({
    type: z.literal(viewUiComponentTypeSchema.enum.FormItem),
    label: z.string(),
    child: dataEntrySchema,
    // validationRules: validationRuleSchema.array().optional(), // TODO
  }),
)
export type ViewUIFormItem = z.infer<typeof formItemSchema>

// 不要そう
export const titleFormItemSchema = z.object({
  type: z.literal(viewUiComponentTypeSchema.enum.TitleFormItem),
  urlPath: z.string().array().optional(),
  valuePath: z.string().array().optional(),
  defaultTitle: z.string().optional(),
})
export type ViewUITitleFormItem = z.infer<typeof titleFormItemSchema>

// treeのrootは削除できない仕様
export function remove<T extends { name: string; children?: T[] }>(tree: T, targetNodeName: string): T {
  const children = tree.children ?? []

  if (children.length === 0) {
    return tree
  }

  if (children.map((x) => x.name).includes(targetNodeName)) {
    const newChildren = children.filter((x) => x.name !== targetNodeName)
    return {
      ...tree,
      children: newChildren,
    }
  }

  // dfs
  const newChildren = children.map((x) => remove(x, targetNodeName))
  return {
    ...tree,
    children: newChildren,
  }
}

import { EditOutlined, LinkOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import {
  DeleteOrganizationDocument,
  type OrganizationFieldsFragment,
  OrganizationPlanTypeEnum,
  OrganizationStatusEnum,
  SourceProviderEnum,
  UpdateOrganizationDocument,
} from '@salescore/admin-client-api'
import { camelToPascal, compareFunction } from '@salescore/buff-common'
import { App, Avatar, Button, message, Select, Space, Table, Tag } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'
import Link from 'next/link'
import { type ReactNode, useState } from 'react'

import { createDestroyColumn, getColumnSearchProps as getColumnSearchProperties } from '../../common/antd'
import { ORGANIZATION_STATUS_OPTIONS, OrganizationStatusTag } from '../shared/OrganizationStatusTag'
import { ProviderLogo } from '../shared/ProviderLogo'
import { SubscriptionPlanTag } from '../shared/SubscriptionPlanTag'
import { SubscriptionForms } from './SubscriptionForm'

export const OrganizationsTable = ({
  organizations,
  refetch,
}: {
  organizations: OrganizationFieldsFragment[]
  refetch: () => void
}): ReactNode => {
  const { modal } = App.useApp()

  const [updateOrganizationMutation] = useMutation(UpdateOrganizationDocument)
  const [deleteOrganizationMutation] = useMutation(DeleteOrganizationDocument)
  const [loadingOrganizationIds, setLoadingOrganizationIds] = useState<string[]>([])
  const [destroyLoading, setDestroyLoading] = useState(false)

  const onDestroy = async (record: OrganizationFieldsFragment) => {
    await deleteOrganizationMutation({
      variables: {
        organizationId: record.id,
      },
      onCompleted: () => {
        refetch()
      },
    })
  }
  return (
    <Table
      rowKey="id"
      dataSource={organizations}
      pagination={{
        pageSize: 100,
      }}
      columns={[
        {
          title: '',
          dataIndex: 'imageUrl',
          render(_, record) {
            return <Avatar src={record.imageUrl} shape="square" />
          },
        },
        {
          title: '組織名',
          dataIndex: 'name',
          sorter: (a, b) => compareFunction(a.name, b.name),
          ...getColumnSearchProperties((record: OrganizationFieldsFragment) => record.name),
        },
        {
          title: '',
          dataIndex: 'status',
          sorter: (a, b) => compareFunction(a.status, b.status),
          filters: ORGANIZATION_STATUS_OPTIONS.map((x) => ({ value: x.value, text: x.label })),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
          onFilter: (value, record) => record.status.includes(value as string),
          render(_, record) {
            return (
              <Select
                bordered={false}
                loading={loadingOrganizationIds.includes(record.id)}
                value={record.status}
                onChange={(status: OrganizationStatusEnum) => {
                  setLoadingOrganizationIds((ids) => [...ids, record.id])
                  void updateOrganizationMutation({
                    variables: {
                      organizationId: record.id,
                      organization: {
                        name: record.name,
                        adminMemo: record.adminMemo,
                        status,
                      },
                    },
                    onCompleted: () => {
                      void message.success(t(`更新しました`))
                      setLoadingOrganizationIds((ids) => ids.filter((x) => x !== record.id))
                    },
                  })
                }}
              >
                {ORGANIZATION_STATUS_OPTIONS.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    <OrganizationStatusTag status={option.value} />
                  </Select.Option>
                ))}
              </Select>
            )
          },
        },
        {
          title: 'プラン',
          dataIndex: 'subscriptions',
          sorter: (a, b) =>
            compareFunction(a.subscriptions.map((x) => x.plan).join(','), b.subscriptions.map((x) => x.plan).join(',')),
          render(_value, record) {
            return (
              <div
                onClick={() => {
                  const m = modal.info({
                    width: 'min(80%, 1200px)',
                    okText: '閉じる',
                    okType: 'default',
                    maskClosable: true,
                    title: 'ライセンス編集',
                    content: (
                      <SubscriptionForms
                        organization={record}
                        subscriptions={record.subscriptions}
                        onAfterFinish={() => {
                          refetch()
                          m.destroy()
                        }}
                      />
                    ),
                  })
                }}
                className="cursor-pointer"
              >
                <Space>
                  {record.subscriptions.map((subscription, index) => (
                    <SubscriptionPlanTag
                      key={index}
                      plan={subscription.plan}
                      numberOfSeats={subscription.numberOfSeats}
                    />
                  ))}
                  {record.organizationPlans.map((x, index) => (
                    <Tag color="yellow" key={index}>
                      {camelToPascal(x.license)} {x.plan === OrganizationPlanTypeEnum.Free ? '(free)' : ''}
                    </Tag>
                  ))}
                  {record.subscriptions.isBlank() && record.organizationPlans.isBlank() && <span>なし</span>}
                  <EditOutlined />
                </Space>
              </div>
            )
          },
        },
        {
          title: '作成日',
          dataIndex: 'createdAt',
          sorter: (a, b) => compareFunction(dayjs(a.createdAt).unix(), dayjs(b.createdAt).unix()),
        },
        {
          title: '合計ライセンス数',
          key: 'subscription',
          sorter: (a, b) =>
            compareFunction(
              a.subscriptions.map((x) => x.numberOfSeats).sum(),
              b.subscriptions.map((x) => x.numberOfSeats).sum(),
            ),
          render(_, record) {
            return <Space>{record.subscriptions.map((x) => x.numberOfSeats).sum()}</Space>
          },
        },
        {
          title: '連携先',
          key: 'sources',
          filters: Object.values(SourceProviderEnum).map((x) => ({ value: x, text: x })),
          // フィルタ結果の処理
          onFilter: (value, record) =>
            record.sources
              .map((x) => x.provider)
              .join(',')
              // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
              .includes(value as string),
          sorter: (a, b) =>
            compareFunction(a.sources.map((x) => x.provider).join(','), b.sources.map((x) => x.provider).join(',')),
          render(_, record) {
            return (
              <Space>
                {record.sources
                  .filter((x) => x.provider !== SourceProviderEnum.Salescore)
                  .map((source) => (
                    <ProviderLogo
                      key={`${record.id}-${source.id}`}
                      provider={source.provider}
                      active={source.connection?.active === true}
                    />
                  ))}
              </Space>
            )
          },
        },
        {
          title: '',
          key: 'externalLink',
          render(_, record) {
            return (
              <a
                href={`${process.env.FRONT_MAIN_URL ?? 'https://app.salescore.jp'}/o/${record.id}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button icon={<LinkOutlined />}>本番</Button>
              </a>
            )
          },
        },
        {
          title: '',
          key: 'link',
          render(_, record) {
            return (
              <Link href={`/o/${record.id}`} legacyBehavior>
                <Button>詳細</Button>
              </Link>
            )
          },
        },
        createDestroyColumn(
          destroyLoading,
          setDestroyLoading,
          onDestroy,
          (organization) => organization.status !== OrganizationStatusEnum.Suspended,
        ),
      ]}
    />
  )
}

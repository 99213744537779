// export * from './date/quarter'
import { compare } from './date/compare'
import { detect } from './date/detect'
import { format } from './date/format'
import { parse } from './date/parse'
import { quarterRange, range } from './date/range'

export const dateUtil = {
  compare,
  range,
  quarterRange,
  format,
  detect,
  parse,
}

import { z } from 'zod'

import { viewQueryFieldSchema } from './field'
import { viewQueryFilterNodeSchema } from './filter'
import { viewQuerySorterSchema } from './sorter'
import { viewQueryNodeSchema } from './tree'

export const snapshotsConfigSchema = z.object({
  // ハイライト条件機能のための情報。取得したレコードのスナップショットを取得する
  nodeName: z.string(),
  modelName: z.string(),
  propertyName: z.string(),
  fieldName: z.string(), // XXX: salesforceに依存した作りになっている
  historyModelName: z.string(),
  historyModelParentIdColumnName: z.string(),
  day: z.number(),
})
export type SnapshotConfig = z.infer<typeof snapshotsConfigSchema>

// ViewQuery.schemaのみを受け取るか、全て受け取るか検討
export const viewQueryListSchema = z.object({
  type: z.literal('list'),
  tree: viewQueryNodeSchema,
  fields: viewQueryFieldSchema.array(),
  filterTree: viewQueryFilterNodeSchema,
  sorters: viewQuerySorterSchema.array(),
  extra: z
    .object({
      searchQuery: z.string().optional(),
      asSubQuery: z.boolean().optional(),
      snapshotConfigs: snapshotsConfigSchema.array().optional(),
    })
    .optional(),
})
export type ViewQueryList = z.infer<typeof viewQueryListSchema>

//
// ON句が以下の形式になることを想定した型。
// "<parentNode.name>"."<parentNodeJoinColumn>" = "currentNode.name"."currentNodeJoinColumn"
// これ以外の形式になるような特殊なON句については、一旦考慮しない。もし今後発生した場合、union typeを作る予定。

import { z } from 'zod'

import { type NodePath, nodePathSchema, propertyNameWithStreamNameSchema } from './common'
import { viewQueryFilterNodeSchema } from './filter'

//
export const viewQueryNodeReadJoinOnByIdSchema = z.object({
  type: z.literal('id'),
  parentNodeName: z.string(),
  parentNodeColumnName: z.string(),
  currentNodeName: z.string(),
  currentNodeColumnName: z.string(),
  meta: z.object({
    parentNodeTableSql: z.string(),
    parentNodeLabel: z.string(),
    parentNodeColumnLabel: z.string(),
    parentNodeStreamName: z.string(),
    parentNodePropertyName: z.string(),
    currentNodeTableSql: z.string(),
    currentNodeLabel: z.string(),
    currentNodeColumnLabel: z.string(),
    currentNodeStreamName: z.string(),
    currentNodePropertyName: z.string(),
  }),
})
export const viewQueryNodeReadJoinOnByCustomSqlSchema = z.object({
  type: z.literal('sql'),
  sql: z.string(),
})
const viewQueryNodeReadJoinOnSchema = z.union([
  viewQueryNodeReadJoinOnByIdSchema,
  viewQueryNodeReadJoinOnByCustomSqlSchema,
])
export type ViewQueryNodeReadJoinOn = z.infer<typeof viewQueryNodeReadJoinOnSchema>

const viewQueryNodeReadSchema = z.object({
  tableSql: z.string(), // 通常は単にテーブル名。サブクエリでも可能。
  tableType: z.enum(['table', 'subquery']),
  idColumn: z.string(), // 今後optionalにする予定だが、当分は確実に入るはず
  join: z
    .object({
      // 子ノードのとき、必ず存在する
      relation: z.enum(['one_to_many', 'many_to_one', 'one_to_one']),
      joinType: z.enum(['LEFT JOIN', 'RIGHT JOIN', 'FULL OUTER JOIN', 'CROSS JOIN', 'INNER JOIN']),
      joinOn: viewQueryNodeReadJoinOnSchema, // id = user_idのような基本的な結合条件。さらに絞り込み条件を指定したい場合はjoinFilterTreeで記載する。
      search: z.object({
        // レコードを検索する際に使う
        sql: z.string(), // value,labelの2つのフィールドを返すSQL
        dependedPropertyNames: propertyNameWithStreamNameSchema.array(),
      }),
      joinFilterTree: viewQueryFilterNodeSchema.optional(), // 結合した後に絞り込みたい場合。UI的にjoinOnと分けて表示したいので、ここでも分けておく
    })
    .optional(),
})
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ViewQueryNode = {
  // 木構造
  name: string // uniqueなノード名。readのasで使われる(SQL発行に関するフィールドはreadにまとめているが、これだけ例外?) TODO
  path: NodePath // 親からのパスを配列に格納した値
  children?: ViewQueryNode[]
  read: z.infer<typeof viewQueryNodeReadSchema>
  write?: {
    streamName: string // 書き込み時に直接使われることはないが、フォームUIのロジックを完結にするため永続化する? あっても特に違和感ないので
    parentIdColumn?: string // 子ノードであり、かつ書き込み可能にしたいとき、親レコードIDを格納しているカラムを指定
  }

  // UI表示で使う情報
  meta: {
    label: string
    // streamとの依存関係
    dependedStreamNames: string[] // 依存しているstream名。subqueryでない限りは1つのみ
  }
}
export const viewQueryNodeSchema: z.ZodSchema<ViewQueryNode> = z.lazy(() =>
  z.object({
    name: z.string(),
    path: nodePathSchema,
    children: viewQueryNodeSchema.array().optional(),
    read: viewQueryNodeReadSchema,
    write: z
      .object({
        streamName: z.string(),
        parentIdColumn: z.string().optional(),
      })
      .optional(),
    meta: z.object({
      label: z.string(),
      dependedStreamNames: z.string().array(),
    }),
  }),
)

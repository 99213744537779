import type { ViewConfig } from '../../schemas/view_config'

// XXX: こういう処理をここで書くべきかどうか、非常に悩ましい…
type ViewConfigType = ViewConfig['type']
const CONFIG_TYPE: Record<ViewConfigType, string> = {
  sheet: 'シート',
  form: 'フォーム',
  kpi: 'KPI',
  kpiPivot: 'ダッシュボード',
  kpiTimeSeries: '案件管理',
}

export const JA = {
  CONFIG_TYPE,
}

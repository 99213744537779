import { z } from 'zod'

const validationFunctionValueSchema = z.object({
  type: z.literal('value'),
  value: z.unknown(),
})

const validationFunctionRecordValueSchema = z.object({
  type: z.literal('recordValue'),
  path: z.string().array(), // pathが空配列のときルート。
})

export interface ValidationFunction {
  type: 'function'
  functionType: 'present' | 'in' | 'never' | 'equal'
  arguments: Array<
    | ValidationFunction
    | z.infer<typeof validationFunctionValueSchema>
    | z.infer<typeof validationFunctionRecordValueSchema>
  >
}
export type ValidationArgument = ValidationFunction['arguments'][0]

export const validationFunctionSchema: z.ZodSchema<ValidationFunction> = z.lazy(() =>
  z.object({
    type: z.literal('function'),
    functionType: z.enum(['present', 'in', 'never']), // 一旦presentのみとしている
    arguments: z
      .union([validationFunctionValueSchema, validationFunctionSchema, validationFunctionRecordValueSchema])
      .array(),
  }),
)

//
// sample
//
// const v: validationFunction = {
//   type: 'function',
//   functionType: 'present',
//   arguments: [{
//     type: "value",
//     value: "{{ record.name }}"
//   }]
// }

// const vv: validationFunction = {
//   type: 'function',
//   functionType: 'in',
//   arguments: [{
//     type: "value",
//     value: "{{ record.record_type_id }}"
//   },
//   {
//     type: 'value',
//     value: ["a", "b"]
//   }]
// }

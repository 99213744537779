import { z } from 'zod'

import { nodePathSchema, propertyNameWithStreamNameSchema } from './common'

export const viewQuerySorterSchema = z.object({
  // 木構造
  nodePaths: nodePathSchema.array(),

  read: z.object({
    sql: z.string(), // 基本的にテーブル名。それ以外ありうるか？
    order: z.enum(['asc', 'desc']),
  }),

  meta: z.object({
    label: z.string(), // ソートに対して名前をつけられる? 不要か?
    dependedPropertyNamesWithStreamName: propertyNameWithStreamNameSchema.array(),
  }),
})
export type ViewQuerySorter = z.infer<typeof viewQuerySorterSchema>

import { getTailwindColorByIndex } from '../../../../constant/colors'
import type { ViewQuery, ViewQueryList } from '../../../../schemas/query'
import type { ViewUIComponent } from '../../../../schemas/ui/ui'
import type { ViewConfigForm } from '../../../../schemas/view_config'
import { flatNodes } from '../../../query/executeViewQuery/util'
import type { CompileContext } from '../../common'
import type { CompileViewConfigResponse } from '../../response'
import { compileSheetViewConfigFilterTree } from '../sheet/compileSheetViewConfigFilterTree'
import { compileSheetViewConfigTree } from '../sheet/compileSheetViewConfigTree'
import { expandChildrenByReferenceField } from '../sheet/expandChildrenByReferenceField'
import { pruneSheetConfigTree } from '../sheet/pruneSheetConfigTree'
import {
  compileFormViewConfigComponents,
  type FieldAndComponentNode,
  getAdditionalQueryField,
} from './compileFormViewConfigComponents'

export function compileFormViewConfig(config: ViewConfigForm, context: CompileContext): CompileViewConfigResponse {
  // TODO
  const prunedTree = pruneSheetConfigTree(config)
  const expandedTree = expandChildrenByReferenceField(prunedTree, config, context)
  const tree = compileSheetViewConfigTree(expandedTree, context)
  if (tree === undefined || expandedTree === undefined) {
    return undefined
  }
  const queryNodes = flatNodes(tree)
  const configNodes = flatNodes(expandedTree)
  const componentNodes = compileFormViewConfigComponents(config.components, { queryNodes, configNodes, context })
  if (componentNodes.isBlank()) {
    return undefined
  }
  const flattenComponentNodes = componentNodes.flatMap((x) => flatNodes(x))
  const additionalFields = getAdditionalQueryField(tree, config.tree, context)
  const fields = [
    additionalFields?.nameQueryField,
    additionalFields?.recordUrlQueryField,
    ...flattenComponentNodes.flatMap((x) => x.queryFields),
  ]
    .compact()
    .uniqueBy((x) => x.name)
  const filterTree = compileSheetViewConfigFilterTree(config.filterTree, queryNodes, configNodes, fields, context) // TODO: id埋め込み
  const listQuery: ViewQueryList = {
    type: 'list',
    tree,
    fields,
    filterTree: {
      ...filterTree,
      leafs: [
        ...filterTree.leafs,
        {
          nodePaths: [tree.path],
          read: {
            sql: `"${tree.name}".id = '{{ recordId }}'`,
            dependedParameters: ['recordId'],
          },
        },
      ],
    },
    sorters: [],
  }

  const queries: ViewQuery[] = [listQuery].compact()
  const rootModel = context.modelSearcher.searchModel(config.tree?.modelName)

  return {
    queries,
    query: queries[0]!,
    ui: [
      {
        type: 'Form',
        label: tree.meta.label,
        children: componentNodes.map((x) => convertComponentNodes(x)),
        nameFieldName: additionalFields?.nameQueryField?.name,
        recordUrlFieldName: additionalFields?.recordUrlQueryField?.name,
        color: rootModel?.color ?? getTailwindColorByIndex(0),
        icon: rootModel?.icon,
      },
    ],
    context,
  }
}

function convertComponentNodes(node: FieldAndComponentNode): ViewUIComponent {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions,@typescript-eslint/no-unsafe-type-assertion
  return {
    ...node.component,
    children: node.children.map((x) => convertComponentNodes(x)),
  } as ViewUIComponent // TODO
}

import { z } from 'zod'

import { timeframeSchema } from '../misc/timeframe'
import { fieldTypeSchema } from '../query'

// NOTE: typeを編集した時のためにdimensionに関わるロジックを整理しておくと
// [core] getUiDimensionCandidates
// [core] constant
// [client] KpiParameterSider: DimensionPicker
// [core] compileKpiViewConfig
// [core] compileSheetViewConfigMeasures
// [core] searchFieldAndPropertyForDimension: findDimensionField
// [client] pivotColumnsSelector
// [core] compileKpiPivotViewConfig
// [client] ViewDrillDownModal
// [client] ViewUIKpiSheet/DimensionAdditionForm
export const viewUiDimensionCandidateCommonSchema = z.object({
  key: z.string(),
  fieldType: fieldTypeSchema,
  label: z.string(),
  // 以下はこのレイヤで定義するものではないが、config側でunionのマージができないので、仕方なくここで定義している
  dateSpan: timeframeSchema.optional(), // 日付のフィールドをどうグルーピングするか
  // numericSpan: z.number().array().optional(), // 数値のフィールドをどうグルーピングするか
  dimensionCount: z.number().optional(), // kpi_pivotでの利用を想定。いくつのKPI(measure)でこのdimensionが使えるか
  sortedValues: z.string().array().optional(),
})
export type ViewUiDimensionCandidateCommon = z.infer<typeof viewUiDimensionCandidateCommonSchema>

const viewUiDimensionCandidatePropertySchema = viewUiDimensionCandidateCommonSchema.merge(
  z.object({
    type: z.literal('property'),
    // property: nodePropertyNameSchema, // なぜかこれで実行時エラーになった
    property: z.object({
      nodeName: z.string(),
      modelName: z.string(),
      propertyName: z.string(),
    }),
  }),
)
export type ViewUiDimensionCandidateProperty = z.infer<typeof viewUiDimensionCandidatePropertySchema>

export const viewUiDimensionCandidateFieldNameSchema = viewUiDimensionCandidateCommonSchema.merge(
  z.object({
    type: z.literal('fieldName'), // KPIのときのみ利用予定
    fieldName: z.string(),
  }),
)
export const viewUiDimensionCandidateDimensionGroupSchema = viewUiDimensionCandidateCommonSchema.merge(
  z.object({
    type: z.literal('dimensionGroup'),
    groupId: z.string(),
  }),
)
export const viewUiDimensionCandidateDimensionAliasSchema = viewUiDimensionCandidateCommonSchema.merge(
  z.object({
    type: z.literal('alias'),
    alias: z.string(),
  }),
)
export type ViewUiDimensionCandidateDimensionGroup = z.infer<typeof viewUiDimensionCandidateDimensionGroupSchema>

export const viewUiDimensionCandidateSchema = z.union([
  viewUiDimensionCandidatePropertySchema,
  viewUiDimensionCandidateFieldNameSchema,
  viewUiDimensionCandidateDimensionGroupSchema,
  viewUiDimensionCandidateDimensionAliasSchema,
])
export type ViewUiDimensionCandidate = z.infer<typeof viewUiDimensionCandidateSchema>

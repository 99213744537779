import { z } from 'zod'

import { viewConfigDimensionSchema } from './dimension'
import { viewConfigFilterNodeSchema, viewConfigFilterSchema } from './filter'
import { type ViewConfigForm, ViewConfigFormSchema } from './form'
import { type ViewConfigKpi, viewConfigKpiParameterSchema, viewConfigKpiSchema } from './kpi'
import { type ViewConfigKpiPivot, ViewConfigKpiPivotSchema } from './kpi_pivot'
import { type ViewConfigKpiTimeSeries, viewConfigKpiTimeSeriesSchema } from './kpiTimeSeries'
import { type ViewConfigSheet, ViewConfigSheetSchema } from './sheet'

export * from './common'
export * from './dimension'
export * from './field'
export * from './filter'
export * from './form'
export * from './kpi'
export * from './kpi_pivot'
export * from './kpiTimeSeries'
export * from './measure'
export * from './sheet'
export * from './tree'

// NOTE:
// TS7056 The inferred type of this node exceeds the maximum length the compiler will serialize. An explicit type annotation is needed.
// 上記エラーの回避のため、ViewConfigの型を推論させずに明示的に定義している
// ref: https://github.com/colinhacks/zod/issues/1040
export type ViewConfig = ViewConfigSheet | ViewConfigForm | ViewConfigKpiPivot | ViewConfigKpi | ViewConfigKpiTimeSeries

export const viewConfigSchema: z.ZodType<ViewConfig> = z.union([
  ViewConfigSheetSchema,
  ViewConfigFormSchema,
  ViewConfigKpiPivotSchema,
  viewConfigKpiSchema,
  viewConfigKpiTimeSeriesSchema,
])

export const additionalFilterLeafSchema = z.object({
  type: z.enum([`drillDown`, `waterfall`]), // 現状必要ないが、一応typeを作っておく
  leaf: viewConfigFilterSchema,
})
export type AdditionalFilterLeaf = z.infer<typeof additionalFilterLeafSchema>

// viewConfigSchemaを参照するので、ここで定義しないとエラーになる（回避策を検討）
const additionalConfigSchema = z.object({
  // configに対して、絞り込み条件を追加したいとき
  // typeを一応保持したいので、leafsとtreesをわざわざ分けている -> 不要になったので廃止した
  filterLeafs: additionalFilterLeafSchema.array().optional(),
  // 現状、kpiPivotで分析軸の指定があった場合のみ利用
  previousDimmensions: viewConfigDimensionSchema.array().optional(),
  // filterTrees: z
  //   .object({
  //     type: z.enum([`measure`]),
  //     tree: viewConfigFilterNodeSchema,
  //   })
  //   .array()
  //   .optional(),
  // KPI -> シートのドリルダウンを行うとき、前のKPIの情報（user,date,measureのfilter）を引き継ぎたいとき
  previousConfig: viewConfigSchema.optional(),
  // KPIビューのpivot,sorterなどはadditionalConfigとして定義。
  // 「元のビュー情報を変えずに実行する」という側面でそうしているが、シート側も似たような概念はあるし、切り分けが微妙
  kpiParameter: viewConfigKpiParameterSchema.optional(),
  // kpiParameterはプリセットにそのまま保存する値として設計してるので、perUserは別で保持する
  kpiParameterPerUser: z
    .object({
      totalRowOrder: z.enum(['top', 'bottom']).optional(), // デフォルトはbottom
      totalColumnOrder: z.enum(['left', 'right']).optional(), // デフォルトはbottom
      skipTotal: z.boolean().optional(),
    })
    .optional(),
  // 2022/11 旧クエリからの移行時、特に移行前後のクエリの整合性の担保のためだけに利用
  skipMultiplicityCheck: z.boolean().optional(),
  // filterTree 丸々上書きする場合に利用
  filterTree: viewConfigFilterNodeSchema.optional(),
  // RI の設定画面のプレビューではフィルタを掛けない
  skipKpiTimeSeriesFilters: z.boolean().optional(),
})
export type ViewConfigAdditionalConfig = z.infer<typeof additionalConfigSchema>

import { z } from 'zod'

import { pivotAppearanceSchema } from '../ui/ui'
import { kpiPresetSchema } from './kpi'

export const ViewConfigKpiPivotSchema = z.object({
  type: z.literal(`kpiPivot`),
  kpis: z
    .object({
      viewId: z.string(), // kpiの参照
      kpiGroupName: z.string().optional(), // KPIグループ
    })
    .array(),
  presets: kpiPresetSchema.array().optional(),
  ui: z
    .object({
      appearance: pivotAppearanceSchema.optional(),
    })
    .optional(),
})
export type ViewConfigKpiPivot = z.infer<typeof ViewConfigKpiPivotSchema>

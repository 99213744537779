import { z } from 'zod'

export const fieldTypes = z.enum([
  'array',
  'boolean',
  'integer',
  'numeric',
  'object',
  'string',
  'date',
  'datetime',
  'time',
  'notFound', // configで指定していたフィールドが存在しなくなったとき
])

export const fieldMetaTypes = z.enum([
  'select',
  'multi_select',
  'text',
  'relation',
  'phone_number',
  'address',
  'currency',
  'url',
  'email',
  'percent',
  'polymorphic_type',
  'name',
  'record_url',
  'html',
  'id',
])

export const viewUiComponentTypeSchema = z.enum([
  'Row',
  'Col',
  'Form',
  'ChildRecordsForm', // 自前のFormList
  'FormList', // deprecated
  'FormItem',
  'TitleFormItem',
  'Input',
  'Select',
  'SearchSelect',
  'InputNumber',
  'Checkbox',
  'DatePicker',
  'Sheet',
  'KpiSheet',
  'KpiPivotSheet',
  'KpiTimeSeriesGraph',
])
export type ViewUIComponentType = z.infer<typeof viewUiComponentTypeSchema>

import { SearchOutlined } from '@ant-design/icons'
import { isSome } from '@salescore/buff-common'
import { Badge, Select, Tag } from 'antd'
import { t } from 'i18next'

interface OptionGroup {
  name: string
  options: Option[]
}

interface Option {
  value: string
  label: string
  key: string
  description?: string
  count?: number
  tags?: string[]
}

export const SortableSelectSelect = ({
  optionGroups,
  values,
  placeholder,
  setValues,
  onChange,
}: {
  optionGroups: OptionGroup[]
  values: string[]
  placeholder?: string
  setValues: (xs: string[]) => void
  onChange: (values: string[]) => void
}) => {
  const options = optionGroups.flatMap((x) => x.options)

  return (
    <Select
      showSearch
      mode="multiple"
      filterOption={
        // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
        (input, option) => ((option?.key ?? '') as string).toLowerCase().includes(input.toLowerCase())
      }
      tagRender={(properties) => {
        if (properties.value === values[0]) {
          return (
            <div style={{ color: '#bfbfbf', paddingLeft: 7, top: 3 }} className="absolute">
              {placeholder ?? t(`項目を追加…`)}
              <br />
            </div>
          )
        }
        return <></>
      }}
      onChange={(values) => {
        setValues(values)
        onChange(values)
      }}
      value={values}
      placeholder={t(`項目を追加…`)}
      suffixIcon={<SearchOutlined />}
      className="w-full"
    >
      {optionGroups.length > 1
        ? optionGroups.map((group, index) => (
            <Select.OptGroup label={group.name} key={`${group.name}-${index}`}>
              {group.options.map((option, index) => (
                <Select.Option value={option.value} key={`${option.key}-${index}`}>
                  <span className="mr-1">{option.label}</span>
                  {(option.tags ?? []).map((tag) => (
                    <Tag className="ml-1">{tag}</Tag>
                  ))}
                  {isSome(option.description) && (
                    <span className="ml-2 text-xs text-gray-500">- {option.description}</span>
                  )}
                </Select.Option>
              ))}
            </Select.OptGroup>
          ))
        : options
            .uniqueBy((x) => x.value)
            .map((option) => (
              <Select.Option value={option.value} key={option.key}>
                <span className="mr-1">{option.label}</span>
                {option.count === undefined ? (
                  <></>
                ) : (
                  <Badge count={option.count} className="ml-2" style={{ backgroundColor: `#aaa` }} />
                )}
                {(option.tags ?? []).map((tag) => (
                  <Tag className="ml-1">{tag}</Tag>
                ))}
                {isSome(option.description) && (
                  <span className="ml-2 text-xs text-gray-500">- {option.description}</span>
                )}
              </Select.Option>
            ))}
    </Select>
  )
}

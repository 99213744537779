export const RecoilDebugObserver = ({ namespace }: { namespace?: string }) => {
  if (process.env.NODE_ENV !== 'development') {
    return <></>
  }
  // 2022/12/17 開発中にエラーがでるようになったので、一旦disabled
  // const snapshot = useRecoilSnapshot()
  // useEffect(() => {
  //   const nodes = snapshot.getNodes_UNSTABLE({ isModified: true })
  //   const changes = Array.from(nodes).map((node) => {
  //     const loadable = snapshot.getLoadable(node)
  //     return [node.key, loadable.contents]
  //   })
  //   changes.forEach((change) => {
  //     logger.debug(`[${[`recoil`, namespace].compact().join('/')}]`, change[0], change[1])
  //   })

  //   if (changes.some((x) => x[0] === 'cursor')) {
  //     logger.debug(
  //       changes
  //         .filter((x) => x[0] === 'cursor')
  //         .map((x) => x[1])
  //         .first(),
  //     )
  //   }
  // }, [snapshot])

  return <></>
}

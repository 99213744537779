import type { Dayjs } from 'dayjs'

import type { Timeframe } from './timeframe'

export function format(day: Dayjs, timeframe: Timeframe, option?: { accountClosingMonth?: number }) {
  switch (timeframe) {
    case 'day': {
      return day.format(`YYYY-MM-DD`)
    }
    case 'week': {
      // 週は常に月曜始まりで、2022-12-01Wの形式で表す
      // dayjsのstartOf('week')は日曜始まり
      const week = day.startOf('week').add(1, 'day').format(`YYYY-MM-DD`)
      return `${week}W`
    }
    case 'month': {
      return day.format(`YYYY-MM`)
    }
    case 'fiscal_quarter': {
      const offset = option?.accountClosingMonth ?? 3 // デフォルトを3月締め4月始まりとする
      const fiscalDate = day.subtract(offset, 'month') // offset分を引いた年が年度となる(3月のときは前年の年、4月のときはその年)
      const fiscalMonth = fiscalDate.month() // 0-11
      const fiscalQuarter = Math.floor(fiscalMonth / 3) + 1
      return `${fiscalDate.year()}-${fiscalQuarter}Q`
    }
    case 'fiscal_year': {
      const offset = option?.accountClosingMonth ?? 3 // デフォルトを3月締め4月始まりとする
      return day.subtract(offset, 'month').format(`YYYY`) // offset分を引いた年が年度となる(3月のときは前年の年、4月のときはその年)
    }
    case 'year': {
      return day.format(`YYYY`)
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const x: never = timeframe
    }
  }
}

import { z } from 'zod'

export const timeframeSchema = z.enum([
  'hour',
  'day',
  'week',
  'month',
  'fiscal_quarter',
  'fiscal_half',
  'fiscal_year',
  'year',
  'weekday',
])
export type ViewTimeframe = z.infer<typeof timeframeSchema>

export type DateTimeFilterExpressionType = ViewTimeframe | 'relative' | 'datetime' | 'date'

// TODO: timeframeSchemaをextendしたかったが、enumのextend方法が分からなかったので、一旦手書き
export const filterDateTimeTypeSchema = z.enum([
  'hour',
  'day',
  'week',
  'month',
  'year',
  'fiscal_quarter',
  'fiscal_half',
  'fiscal_year',
  'weekday',
  // ここからfilterのための値
  'relative',
  'datetime',
  'date',
])

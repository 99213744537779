import { type AlwaysFilter, timeFilterTypes } from '../../../../../../schemas/query/filterTypes'
import { NotImplementedError } from '../../../../../errors'
import { nullFilter, timeCompareFilter } from './filters'
import { validateFilterType } from './validateFilterType'

// https://docs.looker.com/reference/filter-expressions
export const generateTimeFilter = (filter: AlwaysFilter, interval: string | undefined = undefined): string => {
  const type = validateFilterType(filter.filter_type, timeFilterTypes)

  switch (type) {
    case 'null': {
      return nullFilter(filter, false)
    }
    case 'not_null': {
      return nullFilter(filter, true)
    }
    // case 'in':
    //   return stringInFilter(filter, false)
    // case 'not_in':
    //   return stringInFilter(filter, true)
    case 'equal': {
      return timeCompareFilter(filter, interval)
    }
    case 'not_equal': {
      return timeCompareFilter(filter, interval)
    }
    case 'greater_than': {
      return timeCompareFilter(filter, interval)
    }
    case 'greater_than_or_equal': {
      return timeCompareFilter(filter, interval)
    }
    case 'less_than': {
      return timeCompareFilter(filter, interval)
    }
    case 'less_than_or_equal': {
      return timeCompareFilter(filter, interval)
    }
    // case ('between'):
    //   return timeCompareFilter(filter)
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const x: never = type
      throw new NotImplementedError()
    }
  }
}

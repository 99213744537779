import { useRouter } from 'next/router'

import { logger } from '../misc/logtail'

export const useRedirect = (callback?: () => void) => {
  const router = useRouter()

  const redirect = (path: string) => {
    logger.debug(`redirect called. ${path}`) // TODO: テスト用に出力している。mock側のみで対応したい。
    if (router.asPath !== path) {
      void router.push(path)
      if (callback !== undefined) {
        callback()
      }
    }
  }

  return redirect
}

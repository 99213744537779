//
// 「よしなに」丸め込んで返す関数。KPI表示での利用を想定
// ・整数部は常に全て表示
// ・小数点以下は、整数部の桁数(含む0) + 小数部の桁数 < Nになるように表示
//
const DEFAULT_PRECISION = 4
// toFixedで何桁の数値とするか。16桁の数値とする。
// 本来はここが多ければ多いほど良いが、例えば (0.95).toFixed(20) は 0.94999999999999995559 となってしまうので、
// 浮動小数点の誤差がでない精度で切り上げる。実用的にこれで十分なはず
// 2023/06 (2.88).toFixed(16) が 2.8799999999999999 となってしまうので、更に桁数を減らして12にした
// TODO: 丸め誤差への対応が雑になり続けているため、ライブラリをいれるなど抜本的な対策をしたい。
// （扱う値の範囲が一定に決まっているので、よしなな対応でここまでやってこれたが、前提条件が崩れるたびに不具合となっている）
const TO_FIXED_PRECISION = 12

export function roundValue<T extends number | undefined | null>(x: T, precision?: number): T {
  if (x === undefined || x === null) {
    return x
  }
  const numberString = x.toFixed(TO_FIXED_PRECISION)
  const [integer, decimal] = numberString.split('.')
  if (integer === undefined) {
    // ありえないはず
    return x
  }
  if (decimal === undefined) {
    // 小数点以下がないとき。17桁以上の整数じゃない限りありえないはず。
    return x
  }
  const validDecimalCount = (precision ?? DEFAULT_PRECISION) - integer.length
  if (validDecimalCount < 1) {
    // 整数部の有効桁数が十分多い時は、小数点以下は表示しない
    // TODO: 型定義に問題あり
    // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
    return Math.floor(x) as T
  }
  // 小数部を必要な分だけ使って、数値にして返す
  const fixed = [integer, decimal.slice(0, validDecimalCount)].join('.')
  // TODO: 型定義に問題あり
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  const y = Number(fixed) as T
  return y
}

//
// 小数点以下をN桁表示にしつつ、0は削除して返す（OH様向け仕様）
//
function fixValue<T extends number | undefined | null>(x: T, precision?: number): T {
  if (x === undefined || x === null) {
    return x
  }

  const numberString = x.toFixed(precision ?? 2)
  // TODO: 型定義に問題あり
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  return Number(numberString) as T
}

export const numberUtil = {
  roundValue,
  fixValue,
}

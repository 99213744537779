import { type AlwaysFilter, booleanFilterTypes } from '../../../../../../schemas/query/filterTypes'
import { NotImplementedError } from '../../../../../errors'
import { booleanEqualFilter, nullFilter } from './filters'
import { validateFilterType } from './validateFilterType'

export const generateBooleanFilter = (filter: AlwaysFilter): string => {
  const type = validateFilterType(filter.filter_type, booleanFilterTypes)

  switch (type) {
    case 'null': {
      return nullFilter(filter, false)
    }
    case 'not_null': {
      return nullFilter(filter, true)
    }
    case 'equal': {
      return booleanEqualFilter(filter, false)
    }
    case 'not_equal': {
      return booleanEqualFilter(filter, true)
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const x: never = type
      throw new NotImplementedError()
    }
  }
}

import type { SortableListOptions } from './SortableList'
import { SortableListWrapper } from './SortableListWrapper'
import { SortableSelectSelect } from './SortableSelectSelect'

interface OptionGroup<T extends string> {
  name: string
  options: Array<Option<T>>
}

interface Option<T extends string> {
  value: string
  label: string
  key: string
  description?: string
  // 集計軸設定のためだけの設定。選択した値に対して、さらに詳細な値を設定できる
  options?: Array<{ value: T; label: string }>
  optionValues?: T[]
  tags?: string[]
  count?: number
}

export function SortableSelect<T extends string>({
  optionGroups,
  values,
  options,
  placeholder,
  setValues,
  onChange,
  onOptionChange,
}: {
  optionGroups: Array<OptionGroup<T>>
  values: string[]
  options?: SortableListOptions
  placeholder?: string
  setValues: (xs: string[]) => void
  onChange: (values: string[]) => void
  onOptionChange?: (argument: { value: string; optionValue: T[] }) => void
}) {
  const selectedOptions = values
    .map((value) => optionGroups.flatMap((x) => x.options).find((option) => option.value === value))
    .compact()

  return (
    <>
      <SortableSelectSelect
        optionGroups={optionGroups.map((optionGroup) => ({
          ...optionGroup,
          options: optionGroup.options.filter((option) => !(options?.undeletable ?? []).includes(option.value)),
        }))}
        placeholder={placeholder}
        values={values}
        setValues={setValues}
        onChange={onChange}
      />
      <SortableListWrapper
        items={selectedOptions}
        onChange={(sortedOptions) => {
          const values = sortedOptions.map((x) => x.value)
          setValues(values)
          onChange(values)
        }}
        onOptionChange={({ value, item }) => {
          if (onOptionChange !== undefined) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
            onOptionChange({ optionValue: value as T[], value: item.value })
          }
        }}
        className="mt-4"
        options={options}
      />
    </>
  )
}

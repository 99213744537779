type EchartsColor = 'navy' | 'lightGreen' | 'yellow' | 'magenta' | 'lightBlue' | 'green' | 'orange' | 'purple' | 'pink'

const echartsColorDict: Record<EchartsColor, { r: number; g: number; b: number }> = {
  navy: {
    r: 82,
    g: 95,
    b: 189,
  },
  lightGreen: {
    r: 130,
    g: 200,
    b: 107,
  },
  yellow: {
    r: 255,
    g: 185,
    b: 15,
  },
  magenta: {
    r: 234,
    g: 92,
    b: 94,
  },
  lightBlue: {
    r: 108,
    g: 182,
    b: 216,
  },
  green: {
    r: 49,
    g: 153,
    b: 103,
  },
  orange: {
    r: 249,
    g: 123,
    b: 78,
  },
  purple: {
    r: 146,
    g: 80,
    b: 170,
  },
  pink: {
    r: 232,
    g: 108,
    b: 197,
  },
}

export function assignColors(keys: string[]): Record<string, string | undefined> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  const colorDictKeys = Object.keys(echartsColorDict) as EchartsColor[]
  const record: Record<string, string> = {}
  for (const [index, key] of keys.entries()) {
    const dictIndex = index % Object.keys(colorDictKeys).length
    const dictKey = colorDictKeys[dictIndex]
    if (dictKey === undefined) {
      continue
    }
    const color = echartsColorDict[dictKey]
    record[key] = `rgb(${color.r}, ${color.g}, ${color.b})`
  }
  return record
}

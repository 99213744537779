import { Logtail } from '@logtail/browser'
import { type Context, LogLevel } from '@logtail/types'
import { isPresent, VERSION } from '@salescore/buff-common'
import { Logger } from 'tslog'

import type { LoggerEvent } from './loggerEvents'

abstract class FrontendLogger {
  abstract info(eventName: LoggerEvent, message: string, body?: Context): void

  abstract warn(eventName: LoggerEvent, message: string, body?: Context): void

  abstract error(eventName: LoggerEvent, message: string, body?: Context): void

  // デバッグメッセージはeventTypeなしで使える形とする
  abstract debug(...body: unknown[]): void
}

class LogtailLogger extends FrontendLogger {
  private readonly logger: Logtail

  public constructor(token: string) {
    super()
    this.logger = new Logtail(token)
    // eslint-disable-next-line @typescript-eslint/require-await
    this.logger.use(async (log) => ({
      ...log,
      APP_ENV: process.env.APP_ENV ?? '',
      VERSION,
    }))
  }

  public async info(eventName: LoggerEvent, message: string, body?: Context): Promise<void> {
    await this.logger.log(
      generateMessage(eventName, message),
      LogLevel.Info,
      { ...body, eventName },
      // info()ではなくlog()を使わないと以下のstackHintが使えない
      // 以下で起点となるファイル名を指定することで、このファイルの呼び元をログに残すことができる
      // -> というのをbackendのロジックで行っていたが、ブラウザ側ではファイル名の出力はできなそう？ TODO: 一旦本番運用しつつ検討予定
      // { fileName: 'logtail', methodNames: ['info'] },
    )
  }

  public async warn(eventName: LoggerEvent, message: string, body?: Context): Promise<void> {
    await this.logger.log(generateMessage(eventName, message), LogLevel.Warn, { ...body, eventName })
  }

  public async error(eventName: LoggerEvent, message: string, body?: Context): Promise<void> {
    await this.logger.log(generateMessage(eventName, message), LogLevel.Error, { ...body, eventName })
  }

  // eslint-disable-next-line @typescript-eslint/class-methods-use-this
  public async debug(...body: unknown[]): Promise<void> {
    // empty
  }
}

function generateMessage(eventName: LoggerEvent, message: string): string {
  return `[${eventName}] ${message}`
}

// logtailの設定が行われていない場合、代わりにtslogを使う
class SalescoreLogger extends FrontendLogger {
  private readonly logger: Logger<unknown>

  public constructor() {
    super()
    this.logger = new Logger({
      name: `logtail`, // TODO: name、どうしようか？
      overwrite: {
        mask: (arguments_: unknown[]): unknown[] =>
          // logtailの第二引数に詳細パラメータを渡すことがあるが、開発中にこれが表示されると邪魔なので隠す？方針を悩み中。
          [arguments_[0]],
      },
    })

    this.logger.warn(`LOGTAIL_SOURCE_TOKEN is not specified`)
  }

  public info(eventName: LoggerEvent, message: string, body?: Context): void {
    this.logger.info(generateMessage(eventName, message), { ...body, eventName })
  }

  public warn(eventName: LoggerEvent, message: string, body?: Context): void {
    this.logger.warn(generateMessage(eventName, message), { ...body, eventName })
  }

  public error(eventName: LoggerEvent, message: string, body?: Context): void {
    this.logger.error(generateMessage(eventName, message), { ...body, eventName })
  }

  public debug(...body: unknown[]): void {
    this.logger.debug(...body)
  }
}

export const logger: FrontendLogger = isPresent(process.env.LOGTAIL_SOURCE_TOKEN)
  ? new LogtailLogger(process.env.LOGTAIL_SOURCE_TOKEN)
  : new SalescoreLogger()

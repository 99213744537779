import type { FeatureNames } from '@salescore/core'
import type * as React from 'react'

export const isDateTimeString = (x?: string) => {
  if (x === undefined) {
    return false
  }

  return /^\d{4}(?:[/-]\d{2}){2}[ T](?:\d{2}:){2}\d{2}/.test(x)
}

// 簡易的に半角/全角を検査して文字幅を求める
export const calculateCharLength = (x: string) => {
  if (typeof x !== 'string') {
    return 0
  }
  const asciiChar = x.replaceAll(/[^\d a-z]/gi, '')
  const multiByteChars = x.replaceAll(/[\d a-z]/gi, '')
  return asciiChar.length * 1 + multiByteChars.length * 2
}

export const getQueryParameter = (key: string) => {
  const searchParameters = new URLSearchParams(location.search)
  return searchParameters.get(key)
}

export const addQueryParameterToUrl = (key: string, value: string) => {
  const searchParameters = new URLSearchParams(location.search)
  searchParameters.delete(key)
  searchParameters.append(key, value)
  history.replaceState({}, '', location.pathname + '?' + searchParameters.toString())
}

export const removeQueryParameterToUrl = (key: string) => {
  const searchParameters = new URLSearchParams(location.search)
  searchParameters.delete(key)
  history.replaceState({}, '', location.pathname + '?' + searchParameters.toString())
}

export const insertUniqueKeyToObject = <T>(dataSource: T[]): Array<T & { key: React.Key }> =>
  dataSource.map((data, index) => ({
    ...data,
    key: index,
  }))

// backend側にも同名同機能の関数はあるがインポートグラフの関係で両方同時には見えない
export const organizationHasFeature = (
  organization: {
    organizationFeatures: Array<{
      feature: string
    }>
  },
  featureName: FeatureNames,
): boolean => organization.organizationFeatures.map((e) => e.feature).includes(featureName)

import type { Dayjs } from 'dayjs'
import { z } from 'zod'

const operatorSchema = z.enum(['=', '!=', '<', '>', '<=', '>='])
type Operator = z.infer<typeof operatorSchema>

export function compare(left: Dayjs, right: Dayjs, operator: Operator) {
  switch (operator) {
    case '=': {
      return left.isSame(right)
    }
    case '!=': {
      return !left.isSame(right)
    }
    case '<': {
      return left.isBefore(right)
    }
    case '>': {
      return left.isAfter(right)
    }
    case '<=': {
      return left.isBefore(right) || left.isSame(right)
    }
    case '>=': {
      return left.isAfter(right) || left.isSame(right)
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const x: never = operator
      throw new Error(`unexepected operator`)
    }
  }
}

import { Popconfirm } from 'antd'
import { t } from 'i18next'
import type * as React from 'react'

export const PopconfirmIf: React.FC<{
  children: JSX.Element
  condition: boolean
  title: JSX.Element
  onConfirm: () => void
  okText?: string
}> = ({ children, condition, title, onConfirm, okText }) => {
  if (condition) {
    return (
      <Popconfirm onConfirm={onConfirm} okText={okText ?? t(`はい`)} title={title}>
        {children}
      </Popconfirm>
    )
  }

  return <>{children}</>
}

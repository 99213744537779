export function OverlaySection({
  disabled,
  children,
  message,
  maxHeight,
}: {
  disabled: boolean
  children: JSX.Element
  message: JSX.Element
  maxHeight?: number
}) {
  if (!disabled) {
    return children
  }

  return (
    <div className="locked-section relative overflow-hidden" style={{ maxHeight: maxHeight ?? 500 }}>
      <div className="p-6">{children}</div>
      <div className="absolute left-0 top-0 size-full rounded bg-gray-200 opacity-50" />
      <div className="absolute left-0 top-0 z-10 flex size-full items-center justify-center align-middle">
        <div className="text-center">{message}</div>
      </div>
    </div>
  )
}

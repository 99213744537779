import { useEffect, useState } from 'react'

export const useEffectWithChange = <T>(callback: (prevValue: T, currentValue: T) => void | Promise<void>, value: T) => {
  const [prevValue, setPrevValue] = useState(value)

  useEffect(() => {
    if (prevValue !== value) {
      setPrevValue(value)
      void callback(prevValue, value)
    }
  }, [value])
}

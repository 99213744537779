// 2022-1Qの形式のクォーター表現を受け取り、その間のクォーターを生成する
// 時系列グラフぐらいでしか使わない予定だが、一応共通系に書いておく
export class Quarter {
  public year: number
  public quarter: number

  public constructor(year: number, quarter: number) {
    this.year = year
    this.quarter = quarter
  }

  public static buildFromString(s: string) {
    if (!/^\d{4}-\dQ$/.test(s)) {
      return
    }
    const [year, quarter] = s
      .replace('Q', '')
      .split('-')
      .map((x) => Number.parseInt(x))
    return new Quarter(year!, quarter!)
  }

  public next() {
    if (this.quarter === 4) {
      return new Quarter(this.year + 1, 1)
    }
    return new Quarter(this.year, this.quarter + 1)
  }

  public isBeforeOrEqual(x: Quarter) {
    if (this.year === x.year) {
      return this.quarter <= x.quarter
    }
    return this.year <= x.year
  }

  public toString() {
    return `${this.year}-${this.quarter}Q`
  }
}

import { useSortable } from '@dnd-kit/sortable'
import { CSS, type Transform } from '@dnd-kit/utilities'
import type { ReactNode } from 'react'

export interface SortableItemProperties {
  id: string
  render: () => ReactNode
  transformStyle?: Partial<Transform>
}

export function SortableItem(properties: SortableItemProperties) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: properties.id })

  const style = {
    transform: transform
      ? properties.transformStyle
        ? CSS.Transform.toString({ ...transform, ...properties.transformStyle })
        : CSS.Transform.toString({ ...transform })
      : undefined,
    transition,
    zIndex: isDragging ? 1 : undefined,
    opacity: 1,
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {properties.render()}
    </div>
  )
}

import { z } from 'zod'

// dbのsource.config のスキーマは現在はelt内にあるがフロントエンド側で必要になったものを順次引越させる

export const salesforceCredentialsSpec = z.object({
  credentials: z.object({
    refreshToken: z.string(),
    instanceUrl: z.string(), // instanceUrlはcredentialsではないので、ここに含めるべきではないが、初期設計時のミスでここに入れている
    clientId: z.string().optional(),
    clientSecret: z.string().optional(),
    version: z.enum(['42.0', '58.0']).optional(), // ここで厳密にチェックして間違ったバージョン記法が混入するのを避ける。しかし、59.0とかで指定したときにエラーになる将来的なリスクがややでかいかも…
  }),
  meta: z
    .object({
      email: z.string(),
      username: z.string(),
      display_name: z.string(),
      organization_id: z.string(),
      user_id: z.string().optional(), // 後から追加したのでoptional
    })
    .optional(), // 後から追加したので、初期の組織には存在しない。
  // 直接的にはInActiveなUserを取得するためのフラグを作成するため。今後の活用があるかも？
  option: z
    .object({
      fetchInactiveUser: z.boolean().default(false).optional(),
      fetchArchivedRecord: z.boolean().default(false).optional(),
    })
    .optional(),
})

export type SalesforceSpec = z.infer<typeof salesforceCredentialsSpec>

// TODO: いずれ全部引っ越しさせて整備する
export const sourceConfigUnionSchema = z.discriminatedUnion('provider', [
  z.object({
    provider: z.literal('salesforce'),
    config: salesforceCredentialsSpec,
  }),
])

export type SourceConfigUnion = z.infer<typeof sourceConfigUnionSchema>

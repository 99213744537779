import { OrganizationStatusEnum } from '@salescore/admin-client-api'
import { Tag } from 'antd'

export const ORGANIZATION_STATUS_OPTIONS = [
  {
    label: '使用中',
    value: OrganizationStatusEnum.Using,
  },
  {
    label: 'デモ',
    value: OrganizationStatusEnum.Demo,
  },
  {
    label: 'トライアル',
    value: OrganizationStatusEnum.Trial,
  },
  {
    label: '停止中',
    value: OrganizationStatusEnum.Suspended,
  },
  {
    label: '設定中',
    value: OrganizationStatusEnum.Onboarding,
  },
  {
    label: '無料利用',
    value: OrganizationStatusEnum.Free,
  },
]

export const ORGANIZATION_STATUS_COLORS = {
  [OrganizationStatusEnum.Using]: 'green',
  [OrganizationStatusEnum.Demo]: 'yellow',
  [OrganizationStatusEnum.Trial]: 'blue',
  [OrganizationStatusEnum.Suspended]: 'gray',
  [OrganizationStatusEnum.Onboarding]: 'purple',
  [OrganizationStatusEnum.Free]: 'pink',
}

export const ORGANIZATION_STATUS_JA = {
  [OrganizationStatusEnum.Using]: '使用中',
  [OrganizationStatusEnum.Demo]: 'デモ',
  [OrganizationStatusEnum.Trial]: 'トライアル',
  [OrganizationStatusEnum.Suspended]: '停止中',
  [OrganizationStatusEnum.Onboarding]: '設定中',
  [OrganizationStatusEnum.Free]: '無料利用',
}

export const OrganizationStatusTag = ({ status }: { status: OrganizationStatusEnum }) => (
  <Tag color={ORGANIZATION_STATUS_COLORS[status]}>{ORGANIZATION_STATUS_JA[status]}</Tag>
)

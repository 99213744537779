import { isPresent } from '@salescore/buff-common'

import type { CoreModel } from '../../../../schemas/model/model'
import type { ModelProperty } from '../../../../schemas/model/modelProperty'
import type { ViewQueryNode } from '../../../../schemas/query'
import type { ViewConfigSheet, ViewConfigTreeNode } from '../../../../schemas/view_config'
import type { CompileContext } from '../../common'
import { compileSheetViewConfigFilterTree } from './compileSheetViewConfigFilterTree'
import { generateSearchSql } from './searchSql/generateSearchSql'

export function compileSheetViewConfigTree(
  tree: ViewConfigSheet['tree'],
  context: CompileContext,
): ViewQueryNode | undefined {
  if (tree === undefined) {
    return undefined
  }

  return rec(tree, undefined, undefined, context)
}

// eslint-disable-next-line complexity
function rec(
  node: ViewConfigTreeNode,
  parentQueryNode: ViewQueryNode | undefined,
  parentModel: CoreModel | undefined,
  context: CompileContext,
): ViewQueryNode | undefined {
  const model = context.modelSearcher.searchModel(node.modelName)
  if (model === undefined) {
    return undefined
  }
  const { name } = node
  const referenceToProperty = context.modelSearcher.searchProperty(
    node.referenceToProperty?.modelName ?? '',
    node.referenceToProperty?.propertyName ?? '',
  )
  const referenceLabel = referenceToProperty?.label
  const label = node.ui?.label ?? `${model.label}${isPresent(referenceLabel) ? `(${referenceLabel})` : ``}`

  const queryNodeWithoutJoin: ViewQueryNode = {
    name,
    path: [...(parentQueryNode?.path ?? []), name],
    read:
      node.override?.tableSql === undefined
        ? {
            tableSql: model.name,
            tableType: 'table',
            idColumn: 'id',
          }
        : {
            tableSql: node.override.tableSql,
            tableType: 'subquery',
            idColumn: 'id',
          },
    write:
      model.creatable === false
        ? undefined
        : {
            streamName: model.name,
            // 初期設計時の「parentId」という名前が非常に微妙になってしまったが、やりたいことは以下。one_to_manyのときしか使わない TODO: 改修したい
            parentIdColumn: referenceToProperty?.write?.sourcePropertyName,
          },
    meta: {
      label,
      dependedStreamNames: [model.name], // deprecated?
    },
  }

  const joinResult =
    parentQueryNode === undefined || parentModel === undefined
      ? undefined
      : generateJoin(node, parentQueryNode, parentModel, model, queryNodeWithoutJoin, context)
  const join = joinResult?.join

  if (join === undefined && parentModel !== undefined) {
    context.logs.error(`JOINの生成に失敗しました。`)
    return undefined
  }

  const queryNode: ViewQueryNode = {
    ...queryNodeWithoutJoin,
    read: {
      ...queryNodeWithoutJoin.read,
      join,
    },
  }
  const children = node.children?.map((child) => rec(child, queryNode, model, context)).compact()

  return {
    ...queryNode,
    children: (children?.isPresent() ?? false) ? children : undefined,
  }
}

// eslint-disable-next-line @typescript-eslint/max-params
function generateJoin(
  node: ViewConfigTreeNode,
  parentQueryNode: ViewQueryNode,
  parentModel: CoreModel,
  model: CoreModel,
  queryNode: ViewQueryNode,
  context: CompileContext,
): { join: ViewQueryNode['read']['join']; referenceToProperty: ModelProperty } | undefined {
  if (node.referenceToProperty === undefined) {
    // return undefined
    throw new Error(`子ノードでreferenceToが指定されていません`) // TODO: ありえないはず
  }

  const referenceToProperty = context.modelSearcher.searchProperty(
    node.referenceToProperty.modelName,
    node.referenceToProperty.propertyName,
  )
  if (referenceToProperty === undefined) {
    context.logs.error(
      `参照プロパティが見つかりません。モデル名: ${node.referenceToProperty.modelName}, プロパティ名: ${node.referenceToProperty.propertyName}`,
    )
    return undefined
  }

  const { relation, parentNodeColumnName, currentNodeColumnName, parentNodeColumnLabel, currentNodeColumnLabel } =
    node.name === node.referenceToProperty.nodeName
      ? {
          // propertyをこのノードが持っている場合、すなわち親を参照している場合
          // TODO: one_to_oneの判定が雑
          relation: (referenceToProperty.referenceTo ?? []).some((x) => x.relation === 'one_to_one')
            ? ('one_to_one' as const)
            : (`one_to_many` as const),
          parentNodeColumnName: node.referenceToProperty.referenceTo.key ?? 'id',
          parentNodeColumnLabel: 'ID',
          currentNodeColumnName: referenceToProperty.name,
          currentNodeColumnLabel: referenceToProperty.label,
        }
      : {
          relation: `many_to_one` as const,
          currentNodeColumnName: node.referenceToProperty.referenceTo.key ?? 'id',
          currentNodeColumnLabel: 'ID',
          parentNodeColumnName: referenceToProperty.name,
          parentNodeColumnLabel: referenceToProperty.label,
        }

  const search = generateSearchSql({
    model,
    parentModel,
    parentNode: parentQueryNode,
    context,
  })
  const join: ViewQueryNode['read']['join'] = {
    relation,
    joinType: `LEFT JOIN`,
    joinOn:
      node.referenceToProperty.override?.onSql === undefined
        ? {
            type: 'id',
            parentNodeName: parentQueryNode.name,
            parentNodeColumnName,
            currentNodeName: node.name,
            currentNodeColumnName,
            meta: {
              parentNodeTableSql: parentModel.name,
              parentNodeLabel: parentModel.label,
              parentNodeColumnLabel,
              parentNodeStreamName: parentModel.name,
              parentNodePropertyName: parentNodeColumnName,
              currentNodeTableSql: model.name,
              currentNodeLabel: model.label,
              currentNodeColumnLabel,
              currentNodeStreamName: model.name,
              currentNodePropertyName: currentNodeColumnName,
            },
          }
        : {
            type: 'sql',
            sql: node.referenceToProperty.override.onSql,
          },
    joinFilterTree:
      node.additionalJoinOn === undefined
        ? undefined
        : compileSheetViewConfigFilterTree(node.additionalJoinOn, [queryNode], [], [], context),
    search: {
      ...search,
      sql: node.override?.searchSql ?? search.sql,
    },
  }

  return {
    join,
    referenceToProperty,
  }
}

import { DownloadOutlined } from '@ant-design/icons'
import { Button, message } from 'antd'
import { t } from 'i18next'

import { useBooleanState } from '../hooks/useBooleanState'
import { generateCsvString } from '../misc/generate_csv_string'

type Data = unknown[][]
export interface CsvDownloadButtonArgument {
  data: Data | (() => Data | Promise<Data>)
  filename: string
  headers?: string[]
  text?: string
}

export const csvDownload = async function ({ data, filename, headers }: CsvDownloadButtonArgument) {
  const rows = Array.isArray(data) ? data : await data()
  const text = generateCsvString({
    headers: headers ?? [],
    rows,
  })

  const bom = new Uint8Array([0xef, 0xbb, 0xbf])
  const downloadLink = document.createElement('a')
  downloadLink.download = filename + '.csv'
  downloadLink.href = URL.createObjectURL(new Blob([bom, text], { type: 'text/csv' }))
  downloadLink.dataset.downloadurl = ['text/csv', downloadLink.download, downloadLink.href].join(':')
  downloadLink.click()
}

export const CsvDownloadButton = ({
  data,
  filename,
  headers,
  text,
  disabled,
}: CsvDownloadButtonArgument & { disabled?: boolean }) => {
  const loading = useBooleanState()
  return (
    <Button
      icon={<DownloadOutlined />}
      onClick={async () => {
        loading.setTrue()
        try {
          await csvDownload({ data, filename, headers })
        } catch {
          void message.error(t(`エラーが発生しました。`))
        } finally {
          loading.setFalse()
        }
      }}
      loading={loading.isTrue}
      disabled={disabled}
    >
      {text}
    </Button>
  )
}

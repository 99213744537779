import type { SourceProviderEnum } from '@salescore/admin-client-api'

export const ProviderLogo = ({
  provider,
  width,
  active,
}: {
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  provider: SourceProviderEnum | string
  width?: number
  active?: boolean
}) => (
  <img
    width={width ?? 30}
    src={`/images/provider/${provider}.png`}
    alt={provider}
    style={
      active === false
        ? {
            filter: 'grayscale(100%)',
            opacity: 0.8,
          }
        : {}
    }
  />
)

import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  type NormalizedCacheObject,
  split,
} from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { VERSION } from '@salescore/buff-common'
import { Kind, OperationTypeNode } from 'graphql'
import { createClient } from 'graphql-ws'
import { useEffect, useState } from 'react'

// const {
//   env: { GRAPHQL_WS_URL },
// } = process
//
// TODO: 本当はエラーで終了させたいのだがGithub Actionsのビルド時の環境変数の設定が面倒なので一旦見送り
// TODO: バラバラでチェックしたくないので環境変数の利用を集中化したい
// if (GRAPHQL_WS_URL === undefined) {
//   throw new Error('GRAPHQL_WS_URL must be a string')
// }
// frontend側はnextの何かが理由でこういう書き方しても取得できない

const httpLink = new HttpLink({
  uri: process.env.GRAPHQL_URL,
  credentials: 'include',
  headers: {
    // NOTE: JWTを使って認証をするため、cookieは基本的に使わない。
    // integrationの設定時にOAuthのアクセストークンをサーバーサイドに渡すときのみセッションを用いるため必要。
    'Access-Control-Allow-Credentials': 'true',
  },
})

const createWsLint = (token: string | undefined): GraphQLWsLink =>
  new GraphQLWsLink(
    createClient({
      // TODO: 本当はこのfallbackは止めたい
      url: process.env.GRAPHQL_WS_URL ?? 'ws://localhost:4000/graphql',
      connectionParams: {
        authToken: token,
      },
    }),
  )

const createAuthLink = (token: string | undefined): ApolloLink =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: token === undefined ? '' : `Bearer ${token}`,
      },
    }))

    return forward(operation)
  })

const createApolloClient = (token: string | undefined): ApolloClient<NormalizedCacheObject> =>
  new ApolloClient({
    cache: new InMemoryCache(),
    link: split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return definition.kind === Kind.OPERATION_DEFINITION && definition.operation === OperationTypeNode.SUBSCRIPTION
      },
      createWsLint(token),
      from([createAuthLink(token), httpLink]),
    ),
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only', // cache-and-networkが選べなかった
      },
    },
    name: 'salescore-admin-client',
    version: VERSION,
  })

export const useApolloClient = (token: string | undefined): ApolloClient<NormalizedCacheObject> | undefined => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject> | undefined>()

  const awaitSetClient = (): void => {
    const client = createApolloClient(token)
    setClient(client)
  }

  useEffect(() => {
    awaitSetClient()
  }, [])

  return client
}

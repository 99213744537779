import { ApolloError } from '@apollo/client'
import { logger, useRedirect } from '@salescore/frontend-common'
import { App, Spin } from 'antd'
import { t } from 'i18next'
import { type ReactNode, Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

export const HandleQuery = ({
  loadingElement,
  children,
}: {
  loadingElement?: ReactNode
  children: ReactNode
}): ReactNode => {
  const { message } = App.useApp()
  const redirect = useRedirect()

  return (
    <ErrorBoundary
      fallbackRender={() => <div></div>}
      onError={(error): void => {
        // admin権限がなくてクエリーが拒否されたときにはログアウトさせる
        if (
          error instanceof ApolloError &&
          (error.graphQLErrors[0]?.extensions?.code === 'FORBIDDEN' || error.message === 'Not authorized')
        ) {
          redirect('/sign_out')
          return
        }
        logger.debug(error)
        message.error(t(`エラーが発生しました`))
      }}
    >
      <Suspense fallback={loadingElement ?? <Spin />}>{children}</Suspense>
    </ErrorBoundary>
  )
}

import type { ViewConfig, ViewConfigField } from '../../schemas/view_config'
import { isIdentifiable, makeIdentifiable } from '../util/makeIdentifiable'

interface Context {
  changes: string[]
}

// スクリプト用
export function normalizeMeasureName(config: ViewConfig): ViewConfig | undefined {
  const context = { changes: [] }
  switch (config.type) {
    case 'sheet': {
      const fields = normalizeMeasures(config.fields, context)
      if (fields === undefined || context.changes.isBlank()) {
        return undefined
      }

      return {
        ...config,
        fields,
      }
    }
    // TODO
    default: {
      return undefined
    }
  }
}

function normalizeMeasures(fields: ViewConfigField[] | undefined, context?: Context): ViewConfigField[] | undefined {
  if (fields === undefined) {
    return undefined
  }

  return fields.map((field): ViewConfigField => {
    const measures = field.measures ?? []
    if (measures.isBlank()) {
      return field
    }

    return {
      ...field,
      measures: measures.map((measure) => {
        if (isIdentifiable(measure.name)) {
          return measure
        }

        const name = makeIdentifiable(measure.name)
        context?.changes.push(`${measure.name}を${name}に変更`)
        return {
          ...measure,
          name,
        }
      }),
    }
  })
}

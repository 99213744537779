import { type ReadWriteSelectorOptions, selector } from 'recoil'

import { logger } from '../misc/logtail'

type SetType<T> = ReadWriteSelectorOptions<T>['set']
type SetParametersType<T> = Parameters<SetType<T>>
type SetOptType<T> = SetParametersType<T>[0]

// type SetNewValueType<T> = SetParametersType<T>[1]
export const mutation = <T>({ key, set }: { key: string; set: (opt: SetOptType<T>, value: T) => void }) =>
  selector<T>({
    key,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    get() {
      // empty
    },
    set(opt, newValue) {
      if (process.env.NODE_ENV === 'development') {
        logger.debug(`[recoil] ${key} mutation called`, newValue)
      }
      // resetのときのみnewValueがdefaultValueになりうるが、resetを使わない前提でTをassertionする
      // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
      set(opt, newValue as T)
    },
  })

import { z } from 'zod'

import { nodePathSchema } from './common'

export const viewQueryFilterSchema = z.object({
  // 木構造
  nodePaths: nodePathSchema.array(),

  read: z.object({
    sql: z.string(), // 論理演算子によって他の SQL 式と結合しても意味が変わらない SQL 式。例えば `name = "hoge"` や `(name = "hoge" AND age = 20)` などであり、 `name = "hoge" OR age = 20` ではない。
    dependedParameters: z.string().array().optional(), // where文がパラメーターに依存する場合、パラメーター名
  }),
})
export type ViewQueryFilter = z.infer<typeof viewQueryFilterSchema>

// logicalOperator を節点とする木構造。以下のような絵を描くとわかりやすい。
//        and
//   /  /  |  \  \
//  l1 l2  l3  or and
//             /  /  \
//            l4 l5  l6
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ViewQueryFilterNode = {
  logicalOperator: 'and' | 'or' // [...leafs, ...children] に対して適用する論理演算子
  leafs: ViewQueryFilter[] // 論理演算子によって他の SQL 式と結合しても意味が変わらない SQL 式 の配列
  children: ViewQueryFilterNode[]
}
export const viewQueryFilterNodeSchema: z.ZodSchema<ViewQueryFilterNode> = z.lazy(() =>
  z.object({
    logicalOperator: z.enum(['and', 'or']),
    leafs: viewQueryFilterSchema.array(),
    children: viewQueryFilterNodeSchema.array(),
  }),
)

import { z } from 'zod'

import { viewQueryAggregationSchema } from './aggregation'
import { viewQueryListSchema } from './list'
import { viewQueryMultiTablePivotSchema } from './multi_table_pivot'

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ViewQueryRecordNode = {
  id: string | undefined
  attributes: Record<string, unknown>
  meta: {
    height: number
    innerRowIndexStart: number
    innerRowIndexEnd: number
    highlightAttributeNames?: string[] // TODO: 後から適切な形式を考える予定
  }
  children: ViewQueryTableNode[]
}

export interface ViewQueryTableNode {
  nodeName: string
  children: ViewQueryRecordNode[]
}

export interface ViewQueryResultCursor {
  page: number
  pageSize: number
  chunkSize: number
  nextId: string
}

export interface ViewQueryResultUi {
  pivotColumns?: ViewQueryResultUiPivotColumn[]
}

export interface ViewQueryResultUiPivotColumn {
  columnFieldName: string
  valuesWithLabel: Array<{ value: string; label: string | undefined; isStreaming: boolean }>
}

export type ViewQueryResultSnapshot = Record<
  string, // NodeName
  Record<
    string, // recordId
    Record<
      string, // day
      Record<string, unknown>
    >
  >
>

type ViewQueryVersion = 'v1.0.0' | undefined

export const viewQuerySchema = z.union([
  viewQueryListSchema,
  viewQueryAggregationSchema,
  viewQueryMultiTablePivotSchema,
])
export type ViewQuery = z.infer<typeof viewQuerySchema>

export interface ViewQueryResult {
  viewQuery: ViewQuery
  result: ViewQueryRecordNode[]
  nextCursor: ViewQueryResultCursor | undefined
  ui?: ViewQueryResultUi
  sqls?: string[] // deprecated. sqlDetailsを利用予定
  sqlDetails?: Array<{
    sql: string
    name: string
  }>
  queryResultMeta?: unknown
  error?: string
  warn?: string // errorのときはclient側で表示されない(resultを格納しない)が、warnのときは表示される
  version?: ViewQueryVersion
  snapshots?: ViewQueryResultSnapshot
  streamId?: string
}

import { extendProperties } from '@salescore/buff-common'

import type { ViewConfig, ViewConfigAdditionalConfig } from '../../schemas/view_config'
import { type CompileContext, CompileLog, type CompileResources } from './common'
import { compileFormViewConfig } from './compileViewConfig/form/compileFormViewConfig'
import { compileKpiViewConfig } from './compileViewConfig/kpi/compileKpiViewConfig'
import { compileKpiPivotViewConfig } from './compileViewConfig/kpiPivot/compileKpiPivotViewConfig'
import { ModelSearcher } from './compileViewConfig/ModelSearcher'
import { compileKpiTimeSeriesViewConfig } from './compileViewConfig/ri/compileKpiTimeSeriesViewConfig'
import { compileSheetViewConfig } from './compileViewConfig/sheet/compileSheetViewConfig'
import { ViewSearcher } from './compileViewConfig/ViewSearcher'
import type { CompileViewConfigResponse } from './response'

// TODO: テスト側の都合
extendProperties()

export function compileViewConfig(
  config: ViewConfig,
  resources: CompileResources,
  context?: {
    additionalConfig?: ViewConfigAdditionalConfig
  },
): CompileViewConfigResponse {
  // TODO: 2022/11/11 どこかのタイミングでviewがundefinedになりうるようなので……。
  if (resources.view === undefined) {
    return undefined
  }

  const modelSearcher = new ModelSearcher(resources.models, resources.organizationSetting.userGroupCategoryNames)
  const viewSearcher = new ViewSearcher(resources.views)
  const { type } = config
  const logs = new CompileLog()
  const compileContext: CompileContext = {
    resources,
    additionalConfig: context?.additionalConfig,
    modelSearcher,
    viewSearcher,
    logs,
  }

  switch (type) {
    case 'sheet': {
      return compileSheetViewConfig(config, compileContext)
    }
    case 'form': {
      return compileFormViewConfig(config, compileContext)
    }
    case 'kpi': {
      return compileKpiViewConfig(config, compileContext)
    }
    case 'kpiPivot': {
      return compileKpiPivotViewConfig(config, compileContext)
    }
    case 'kpiTimeSeries': {
      return compileKpiTimeSeriesViewConfig(config, compileContext)
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const x: never = type
      // throw new Error(`not implemented`)

      return compileSheetViewConfig(config, compileContext)
    }
  }
}

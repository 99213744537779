import { Layout } from 'antd'
import type { AppProps } from 'next/app'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import { I18nProvider } from '../../providers/I18nProvider'

export const NoMemberLayout = ({ router, Component }: AppProps) => (
  <Layout className="h-full bg-gray-900">
    <SwitchTransition>
      <CSSTransition key={router.asPath} timeout={200} classNames="page-transition">
        <I18nProvider>
          <Component />
        </I18nProvider>
      </CSSTransition>
    </SwitchTransition>
  </Layout>
)

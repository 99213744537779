import { extendProperties } from '../extend_properties'
import { parse } from './parse'
import { query } from './query'
import type { Table, TableRecord } from './query/types'

extendProperties()

export class PgEmulator {
  private readonly tables: Table[]

  public constructor() {
    this.tables = []
  }

  public initializeTable<T extends TableRecord>({ name, records }: { name: string; records: T[] }) {
    this.tables.push({
      name,
      columns: [], // TODO
      records,
    })
  }

  public query(sql: string) {
    const commands = parse(sql)
    const command = commands[0]
    if (command === undefined) {
      throw new Error(`empty command`)
    }
    return query(command, this.tables)
  }
}

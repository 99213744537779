import { arrayMoveImmutable } from 'array-move'
import { useState } from 'react'

import { SortableFlexElementContent, type SortableFlexElementContentItem } from './SortableFlexElementContent'

type SortableFlexElementContentItemWithMeta<T> = SortableFlexElementContentItem & {
  meta: T
}

export function SortableFlexElement<T>({
  items,
  onChange,
  className,
  onClick,
  axis,
  disabled,
  pressDelay,
}: // CustomContainer
{
  items: Array<SortableFlexElementContentItemWithMeta<T>>
  onChange: (items: Array<SortableFlexElementContentItemWithMeta<T>>) => void | Promise<void>
  className?: string
  onClick?: (key: string) => void
  axis?: 'x' | 'y' | 'xy'
  disabled?: boolean
  pressDelay?: number
  // CustomContainer?: React.FC<SortableFlexElementCustomContainerArg>
}) {
  const [dragging, setDragging] = useState(false)
  const [loading, setLoading] = useState(false)

  // NOTE: ソート時のUXを良くするため、onChangeは非同期で行い、itemsやvalueの変更はソート直後に行われるようにした方が良い。
  const swapItem = async (oldIndex: number, newIndex: number) => {
    const newItems = arrayMoveImmutable(items, oldIndex, newIndex)
    try {
      setLoading(true)
      await onChange(newItems)
    } finally {
      setLoading(false)
    }
  }

  const onSortEnd = async ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    setDragging(false)
    if (oldIndex === newIndex) {
      // ここでonClickを発火させるしかない？
      if (onClick !== undefined) {
        onClick(items[oldIndex]!.key)
      }
    } else {
      await swapItem(oldIndex, newIndex)
    }
  }

  if (items.length === 0) {
    return <></>
  }

  if (disabled === true) {
    return <div className={className ?? ``}>{items.map((item) => item.render())}</div>
  }

  return (
    <SortableFlexElementContent
      axis={axis ?? 'x'}
      items={items}
      onSortEnd={onSortEnd}
      dragging={dragging}
      onSortStart={() => {
        setDragging(true)
      }}
      className={className}
      pressDelay={pressDelay}
      // CustomContainer={CustomContainer} // TODO
      // loading={loading} // TODO
    />
  )
}

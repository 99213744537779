import { z } from 'zod'

const modelPropertyNameSchema = z.object({
  type: z.literal(`modelProperty`),
  modelName: z.string(),
  propertyName: z.string(),
})
const goalDimensionNameSchema = z.object({
  type: z.literal(`goalDimension`),
  goalDimensionId: z.string(),
})
export const dimensionGroupPropertySchema = z.union([goalDimensionNameSchema, modelPropertyNameSchema])
export type DimensionGroupProperty = z.infer<typeof dimensionGroupPropertySchema>

export const dimensionGroupSchema = z.object({
  id: z.string(),
  label: z.string(),
  properties: dimensionGroupPropertySchema.array(),
})

export type DimensionGroup = z.infer<typeof dimensionGroupSchema>

import { z } from 'zod'

const goalDimensionSchema = z.object({
  id: z.string(),
  label: z.string(),
  selectOptions: z.string().array(),
})

export const goalAggregationFunctionEnum = z.enum([`sum`, `avg`])
const goalConfigKpiViewSchema = z.object({
  id: z.string(),
  name: z.string(),
  aggregationFunction: goalAggregationFunctionEnum,
})
export type GoalConfigKpiView = z.infer<typeof goalConfigKpiViewSchema>

// UI側のフォームでも使っているので注意(むしろ分けた方が良いだろうか？)
export const goalConfigSchema = z.object({
  id: z.string(),
  name: z.string(),
  timeSpanType: z.enum(['month', 'week', 'day', 'none']),
  userType: z.enum(['user', 'group', 'none']),
  goalDimension1: goalDimensionSchema.nullable().optional(),
  goalDimension2: goalDimensionSchema.nullable().optional(),
  goalDimension3: goalDimensionSchema.nullable().optional(),
  goalDimension4: goalDimensionSchema.nullable().optional(),
  goalDimension5: goalDimensionSchema.nullable().optional(),
  kpiViews: goalConfigKpiViewSchema.array(),
})
export type GoalConfig = z.infer<typeof goalConfigSchema>

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import parser from './parser/grammer'
import { type Command, commandsSchema } from './parser/grammer_schema'

export function parse(sql: string): Command[] {
  const ast = parser.parse(sql) as unknown
  const result = commandsSchema.safeParse(ast)
  if (!result.success) {
    // TODO: CASE WHENなどがzodで表現できず、結局assertionすることにした。
    // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
    return ast as Command[]
  }
  return result.data
}

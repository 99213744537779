import { z } from 'zod'

import { type ViewConfigFilterNodeForJoinOn, viewConfigFilterNodeForJoinOnSchema } from './filter'

const referenceToSchema = z.object({
  modelName: z.string(),
  key: z.string().optional().nullable(), // デフォルトでid
})

// joinで用いるreferenceToのプロパティ
// nodeNameが親ノード(many_to_one)のときと、子ノード(one_to_many)のときの2つのパターンがありうる
const referenceToProperty = z.object({
  nodeName: z.string(),
  modelName: z.string(),
  propertyName: z.string(),
  referenceTo: referenceToSchema,
  override: z
    .object({
      onSql: z.string().optional(),
    })
    .optional(),
})
export type ReferenceToProperty = z.infer<typeof referenceToProperty>

export interface ViewConfigTreeNode {
  type: 'model'
  name: string // unique
  modelName: string
  referenceToProperty?: ReferenceToProperty
  additionalJoinOn?: ViewConfigFilterNodeForJoinOn
  children?: ViewConfigTreeNode[]
  // TODO: ViewQueryNode側のスキーマをシンプルにした上で、overrideの型をPartial<ViewQueryNode>にする
  ui?: {
    label?: string
  }
  override?: {
    searchSql?: string
    tableSql?: string
  }
}
export const viewConfigTreeNodeSchema: z.ZodSchema<ViewConfigTreeNode> = z.lazy(() =>
  z.object({
    type: z.literal(`model`), // customも許してunionにする想定
    name: z.string(),
    modelName: z.string(),
    referenceToProperty: referenceToProperty.optional(), // rootの時だけoptional
    additionalJoinOn: viewConfigFilterNodeForJoinOnSchema.optional(), // referenceToで示される単純なid = id以外のjoin onを指定したいとき
    children: viewConfigTreeNodeSchema.array().optional(),
    ui: z
      .object({
        // TODO: UIから引っ張ってくるか？
        label: z.string().optional(),
      })
      .optional(),
    override: z
      .object({
        // TODO: UIから引っ張ってくるか？
        searchSql: z.string().optional(),
        // XXX: 目標設定ページでのみ利用を想定
        tableSql: z.string().optional(),
      })
      .optional(),
  }),
)

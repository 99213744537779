import { SaveOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import {
  type OrganizationFieldsFragment,
  type SubscriptionFieldsFragment,
  SubscriptionPlanEnum,
  UpdateSubscriptionDocument,
} from '@salescore/admin-client-api'
import { App, Button, Divider, Form, InputNumber } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { t } from 'i18next'

import { SUBSCRIPTION_STATUS_OPTIONS } from '../shared/SubscriptionPlanTag'
import { OrganizationPlanCard } from './OrganizationPlanCard'

interface FormValue {
  numberOfSeats: number
  pricePerSeat: number
  plan: SubscriptionPlanEnum
  note?: string
}

export const SubscriptionForms = ({
  organization,
  subscriptions,
  onAfterFinish,
}: {
  organization: OrganizationFieldsFragment
  subscriptions: SubscriptionFieldsFragment[]
  onAfterFinish: () => void
}): React.JSX.Element => {
  const plans: SubscriptionPlanEnum[] = [
    SubscriptionPlanEnum.Visualize,
    SubscriptionPlanEnum.Sync,
    SubscriptionPlanEnum.Ri,
  ]

  return (
    <div>
      {plans.map((plan, index) => (
        <SubscriptionForm
          key={index}
          organizationId={organization.id}
          plan={plan}
          subscription={subscriptions.find((x) => x.plan === plan)}
          onAfterFinish={onAfterFinish}
        />
      ))}
      <OrganizationPlanCard organization={organization} onAfterFinish={onAfterFinish} />
    </div>
  )
}

function SubscriptionForm({
  organizationId,
  plan,
  subscription,
  onAfterFinish,
}: {
  organizationId: string
  plan: SubscriptionPlanEnum
  subscription?: SubscriptionFieldsFragment
  onAfterFinish: () => void
}): JSX.Element {
  const { message } = App.useApp()
  const [updateSubscriptionMutation, { loading }] = useMutation(UpdateSubscriptionDocument)

  const onFinish = (value: FormValue): void => {
    void updateSubscriptionMutation({
      variables: {
        organizationId,
        subscription: {
          numberOfSeats: value.numberOfSeats,
          pricePerSeat: value.pricePerSeat ?? 5000,
          plan,
          note: value.note,
        },
      },
      onCompleted: () => {
        onAfterFinish()
        message.success(t(`更新しました`))
      },
    })
  }

  return (
    <div>
      <div className="mb-3 text-xl font-bold">{SUBSCRIPTION_STATUS_OPTIONS.find((x) => x.value === plan)?.label}</div>
      <Form initialValues={subscription} onFinish={onFinish}>
        <Form.Item
          name="numberOfSeats"
          label="ライセンス数"
          rules={[{ required: true, message: 'ライセンス数を入力してください' }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item label="備考(あれば)" name="note">
          <TextArea />
        </Form.Item>
        <Form.Item>
          <Button type="primary" icon={<SaveOutlined />} htmlType="submit" loading={loading}>
            保存
          </Button>
        </Form.Item>
      </Form>
      <Divider />
    </div>
  )
}

import { z } from 'zod'

import { filterDateTimeTypeSchema } from '../misc/timeframe'
import { filterTypes } from '../query/filterTypes'
import { nodePropertyNameSchema } from './common'
import { viewConfigDimensionSchema } from './dimension'

const viewConfigFilterCommonSchema = z.object({
  filterType: z.enum(filterTypes),
  filterValue: z.unknown().optional(),
  filterValueLabel: z.string().optional(), // valueがID値の時に、表示上はラベルの値を表示する目的で使う。compileの際は一切使わない想定
  filterValueParameterName: z.string().optional(), // 固定のvalueを指定する代わりに、実行時に可変なパラメーターを指定することも可能 // TODO: validation
  filterDateTimeType: filterDateTimeTypeSchema.optional(),
})

const viewConfigPropertyFilterSchema = viewConfigFilterCommonSchema.merge(
  z.object({
    type: z.literal(`property`),
    property: nodePropertyNameSchema,
    option: z
      .object({
        shouldFilterToReferencedEntityName: z.boolean().optional(), // propertyがreferenceToを持っており、かつこれがtrueの時は、参照先のオブジェクトのname要素に対してフィルターを行う
        shouldConsiderCase: z.boolean().optional(), // valueが文字列の場合に、大文字と小文字を区別するかどうか。これがtrueの時は、大文字と小文字を区別する
      })
      .optional(),
  }),
)

export const viewConfigFieldFilterSchema = viewConfigFilterCommonSchema.merge(
  z.object({
    type: z.literal(`field`),
    fieldName: z.string(),
  }),
)
export const viewConfigDimensionFilterSchema = viewConfigFilterCommonSchema.merge(
  z.object({
    type: z.literal(`dimension`),
    dimension: viewConfigDimensionSchema,
    option: z
      .object({
        isDrillDownFilter: z.boolean().optional(), // ドリルダウンで付与されたフィルターかどうか
      })
      .optional(),
  }),
)
export const viewConfigCustomFilterSchema = viewConfigFilterCommonSchema.partial().merge(
  z.object({
    type: z.literal(`custom`),
    label: z.string().optional(),
    filterValue: z.string(), // 任意のSQLを指定する。
  }),
)

export const viewConfigFilterSchema = z.union([
  viewConfigPropertyFilterSchema,
  viewConfigFieldFilterSchema,
  viewConfigDimensionFilterSchema,
  viewConfigCustomFilterSchema,
])

export type ViewConfigPropertyFilter = z.infer<typeof viewConfigPropertyFilterSchema>
export type ViewConfigFieldFilter = z.infer<typeof viewConfigFieldFilterSchema>
export type ViewConfigDimensionFilter = z.infer<typeof viewConfigDimensionFilterSchema>
export type ViewConfigCustomFilter = z.infer<typeof viewConfigCustomFilterSchema>
export type ViewConfigFilter = z.infer<typeof viewConfigFilterSchema>

export interface ViewConfigFilterNode {
  logicalOperator: 'and' | 'or'
  leafs: ViewConfigFilter[]
  children: ViewConfigFilterNode[]
}

export const viewConfigFilterNodeSchema: z.ZodSchema<ViewConfigFilterNode> = z.lazy(() =>
  z.object({
    logicalOperator: z.enum(['and', 'or']),
    leafs: viewConfigFilterSchema.array(),
    children: viewConfigFilterNodeSchema.array(),
  }),
)

export interface ViewConfigFilterNodeForJoinOn {
  logicalOperator: 'and' | 'or'
  leafs: ViewConfigPropertyFilter[]
  children: ViewConfigFilterNodeForJoinOn[]
}

export const viewConfigFilterNodeForJoinOnSchema: z.ZodSchema<ViewConfigFilterNodeForJoinOn> = z.lazy(() =>
  z.object({
    logicalOperator: z.enum(['and', 'or']),
    leafs: viewConfigPropertyFilterSchema.array(),
    children: viewConfigFilterNodeForJoinOnSchema.array(),
  }),
)

import { Divider } from 'antd'
import { useEffect, useState } from 'react'

import { useHover } from '../../hooks/useHover'
import { MyDropdown, type MyDropdownProperties } from './MyDropdown'

type MenuItem =
  | {
      key: string
      type: 'label'
      label: string | JSX.Element
      onClick?: () => void
      children?: MenuItem[]
    }
  | {
      key: string
      type: 'render'
      render: (argument: { hide: () => void }) => JSX.Element
      children?: MenuItem[]
    }
  | {
      key?: never
      type: 'divider'
      children?: never
    }

export const MyDropdownMenu = (
  argument: {
    items: Array<MenuItem | undefined>
  } & Omit<MyDropdownProperties, 'overlay'>,
) => {
  const { onHide } = argument
  const [keys, setKeys] = useState<string[]>([])

  return (
    <MyDropdown
      {...argument}
      onHide={() => {
        setKeys([])
      }}
      overlay={({ hide }) => (
        <div className="my-dropdown-menu-overlay">
          {argument.items.compact().map((item, index) => (
            <DropdownMenu
              key={index}
              item={item}
              hideDropdown={() => {
                setKeys([])
                hide()
                onHide?.()
              }}
              childrenVisibility={keys.includes(item.key ?? '')}
              setChildrenVisibility={(visibility) => {
                if (visibility) {
                  setKeys([item.key ?? ''])
                } else {
                  setKeys([])
                }
              }}
            />
          ))}
        </div>
      )}
    />
  )
}

interface MenuProperties {
  item: MenuItem
  hideDropdown: () => void
  childrenVisibility: boolean
  setChildrenVisibility: (visibility: boolean) => void
}

function DropdownMenu(properties: MenuProperties) {
  const element = useHover((hovered) => (
    <div>
      <DropdownMenuBody {...properties} hovered={hovered} />
    </div>
  ))
  return element
}

function DropdownMenuBody(properties: MenuProperties & { hovered: boolean }) {
  const { item, childrenVisibility, setChildrenVisibility, hovered, hideDropdown } = properties
  useEffect(() => {
    if (hovered && item.children !== undefined) {
      setChildrenVisibility(true)
    }
  }, [hovered])

  if (item.children === undefined) {
    return <DropdownMenuBodyItem {...properties} />
  }

  return (
    <MyDropdownMenu
      position="right"
      items={item.children}
      className="w-full"
      style={{ padding: 0 }}
      visibility={childrenVisibility}
      setVisibility={(v) => {
        setChildrenVisibility(v)
      }}
      onHide={() => {
        hideDropdown()
      }}
    >
      <DropdownMenuBodyItem {...properties} />
    </MyDropdownMenu>
  )
}

function DropdownMenuBodyItem({ item, hideDropdown }: MenuProperties) {
  if (item.type === 'label') {
    return (
      <div
        className="w-full cursor-pointer px-3 py-2 hover:bg-gray-50 hover:opacity-80"
        onClick={() => {
          if (item.onClick === undefined) {
            return
          }
          item.onClick()
          hideDropdown()
        }}
      >
        {item.label}
      </div>
    )
  }

  if (item.type === 'render') {
    return item.render({ hide: hideDropdown })
  }

  return <Divider style={{ margin: 0 }} />
}

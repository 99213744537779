import { z } from 'zod'

import {
  type ViewUIComponent,
  viewUiComponentSchema,
  type ViewUIComponentType,
  viewUiComponentTypeSchema,
} from '../ui/ui'
import { type NodePropertyName, nodePropertyNameSchema } from './common'
import { queryFieldSchemaForOverride } from './field'
import { viewConfigFilterNodeSchema } from './filter'
import { viewConfigTreeNodeSchema } from './tree'

export interface ViewConfigUiComponent {
  componentType?: ViewUIComponentType
  property?: NodePropertyName
  nodeName?: string // ChildRecordsFormでnodeNameが必要なので…
  override?: {
    component?: ViewUIComponent
    queryField?: z.infer<typeof queryFieldSchemaForOverride>
  }
  children?: ViewConfigUiComponent[]
}
export const viewConfigUiComponentSchema: z.ZodSchema<ViewConfigUiComponent> = z.lazy(() =>
  z.object({
    componentType: viewUiComponentTypeSchema.optional(), // propertyがあれば、自動推論する。それ以外で必須
    property: nodePropertyNameSchema.optional(), // 必ずしもpropertyをもたない
    children: viewConfigUiComponentSchema.array().optional(),
    override: z
      .object({
        component: viewUiComponentSchema.optional(),
        queryField: queryFieldSchemaForOverride.optional(),
      })
      .optional(),
  }),
)

export const ViewConfigFormSchema = z.object({
  type: z.literal(`form`),
  tree: viewConfigTreeNodeSchema.optional(), // 未設定のとき、プロパティは存在しない。設定済みの時は必須
  filterTree: viewConfigFilterNodeSchema.optional(),
  components: viewConfigUiComponentSchema.array().optional(), // 同上
})
export type ViewConfigForm = z.infer<typeof ViewConfigFormSchema>

import { range } from '@salescore/buff-common'
import { t } from 'i18next'

import type { CoreModel } from '../../schemas/model/model'
import type { ModelProperty } from '../../schemas/model/modelProperty'

export const SALESCORE_USER_GROUPS_WITH_PARENTS_MODEL: CoreModel = {
  icon: 'faUsers',
  name: 'salescore_user_groups_with_parents',
  label: 'グループ',
  deletable: false,
  properties: [
    {
      name: `id`,
      type: 'string' as const,
      label: `ID`,
      meta: `id` as const,
    },
    ...range(1, 5).flatMap((index): ModelProperty[] => [
      {
        name: `user_group_d${index}_id`,
        type: 'string' as const,
        label: `グループID(階層${index})`, // 上書きしたい
        // meta: 'id', // idにしたいが、idではない
      },
      {
        name: `user_group_d${index}_name`,
        type: 'string' as const,
        label: `グループ名(階層${index})`, // 上書きしたい
        // meta: '', // nameにしたいが、nameではない
      },
    ]),
  ],
}

export function getSalescoreUserGroupsWithParentsModel(userGroupCategoryNames: string[]): CoreModel {
  return {
    ...SALESCORE_USER_GROUPS_WITH_PARENTS_MODEL,
    properties: [
      {
        name: `id`,
        type: 'string' as const,
        label: `ID`,
        meta: `id` as const,
      },
      ...range(0, 4).flatMap((index): ModelProperty[] => [
        {
          name: `user_group_d${index + 1}_id`,
          type: 'string' as const,
          label: userGroupCategoryNames[index] ?? t(`グループ${index + 1}`),
          // meta: 'id', // idにしたいが、idではない
        },
        {
          name: `user_group_d${index + 1}_name`,
          type: 'string' as const,
          label: userGroupCategoryNames[index] ?? t(`グループ${index + 1}`),
          // meta: '', // nameにしたいが、nameではない
        },
      ]),
    ],
  }
}

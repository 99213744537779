import { PlusOutlined } from '@ant-design/icons'
import { useSuspenseQuery } from '@apollo/client'
import { FetchAdminOrganizationsDocument } from '@salescore/admin-client-api'
import { App, Button, Card, Tabs } from 'antd'
import type { ReactNode } from 'react'

import { HandleQuery } from '../presentation/common/HandleQuery'
import { CreateOrganizationForm } from '../presentation/components/organizations/CreateOrganizationForm'
import { DeletedOrganizationsTable } from '../presentation/components/organizations/DeletedOrganizationsTable'
import { OrganizationsTable } from '../presentation/components/organizations/OrganizationsTable'

const { TabPane } = Tabs

const Page = (): ReactNode => {
  const { modal } = App.useApp()
  const { data, refetch } = useSuspenseQuery(FetchAdminOrganizationsDocument, {
    variables: {
      shouldFetchDeleted: false,
    },
    fetchPolicy: 'cache-and-network',
  })
  const { data: dataDeleted, refetch: refetchDeleted } = useSuspenseQuery(FetchAdminOrganizationsDocument, {
    variables: {
      shouldFetchDeleted: true,
    },
    fetchPolicy: 'cache-and-network',
  })

  return (
    <Card
      className="m-5"
      title="組織一覧"
      extra={[
        <Button
          key="button-create"
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => {
            const m = modal.info({
              width: 'min(80%, 1200px)',
              okText: '閉じる',
              okType: 'default',
              title: '新規組織作成',
              content: (
                <CreateOrganizationForm
                  onAfterFinish={() => {
                    void refetch()
                    m.destroy()
                  }}
                />
              ),
            })
          }}
        >
          新規作成
        </Button>,
      ]}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="一覧" key="1">
          <HandleQuery>
            <OrganizationsTable
              organizations={data.adminOrganizations}
              refetch={() => {
                void refetch()
                void refetchDeleted()
              }}
            />
          </HandleQuery>
        </TabPane>
        <TabPane tab="削除済み一覧" key="2">
          <HandleQuery>
            <DeletedOrganizationsTable
              organizations={dataDeleted.adminOrganizations}
              refetch={() => {
                void refetch
                void refetchDeleted()
              }}
            />
          </HandleQuery>
        </TabPane>
      </Tabs>
    </Card>
  )
}

export default Page

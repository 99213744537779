// exportしたくないが、しないとtreeUtil側でエラーになる
export interface ItemNode<T> {
  id: string
  key: string // idのみとしたいが、antdとの互換を保つために必要
  item: T
  children: Array<ItemNode<T>>
}

export const createTreesFromItems = <T>({
  items,
  getId,
  getParentId,
}: {
  items: T[]
  getId: (item: T) => string
  getParentId: (item: T) => string | undefined
}): Array<ItemNode<T>> => {
  const nodes: Array<ItemNode<T>> = items.map((item) => ({
    id: getId(item),
    key: getId(item),
    children: [],
    item,
  }))
  const allKeys = new Set(nodes.map((node) => node.id))
  const rootNodes = nodes.filter((node) => !allKeys.has(getParentId(node.item) ?? ''))
  const childNodes = nodes.filter((node) => allKeys.has(getParentId(node.item) ?? ''))
  return createTree(rootNodes, childNodes, getParentId)
}

function createTree<T>(
  rootNodes: Array<ItemNode<T>>,
  candidateNodes: Array<ItemNode<T>>,
  getParentId: (item: T) => string | undefined,
): Array<ItemNode<T>> {
  if (candidateNodes.length === 0) {
    return rootNodes
  }

  const restNodes = []
  for (const candidateNode of candidateNodes) {
    const parentId = getParentId(candidateNode.item)
    if (parentId === undefined) {
      continue
    }

    const parentNode = dfs(rootNodes, parentId)
    if (parentNode === undefined) {
      // 元のノードがおかしくなければ、常にparentNodeはあるはず
      restNodes.push(candidateNode)
    } else {
      parentNode.children.push(candidateNode)
    }
  }
  if (candidateNodes.length === restNodes.length) {
    return rootNodes
  }
  return createTree(rootNodes, restNodes, getParentId)
}

function dfs<T>(nodes: Array<ItemNode<T>>, id: string): ItemNode<T> | undefined {
  // 全ての子ツリーについて探索
  for (const node of nodes) {
    // 対象のキーだったらcallbackを発火
    if (node.id === id) {
      return node
    }
    const children = node.children

    const result = dfs(children, id)
    if (result !== undefined) {
      return result
    }
  }
}

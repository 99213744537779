import { DeleteOutlined, HolderOutlined, PlusOutlined } from '@ant-design/icons'
import { rectSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Button, Space, Typography } from 'antd'
import type { CSSProperties } from 'react'

import { useHover } from '../../hooks/useHover'
import { type KanbanItem, KanbanItemComponent } from './KanbanItem'

const draggingStyle: CSSProperties = {
  cursor: 'grabbing',
  // backgroundColor: `red`,
  opacity: 0.5,
  boxShadow: '-1px 0 15px 5px rgba(34, 33, 81, 0.3)',
  // TODO: ソート中の要素が、他要素の後ろに隠れてしまうのを防げなかった。別途absoluteな要素を作るしかないのだろうか？
  // XXX: absoluteにしない限りzIndex指定は意味がない上に、なんか表示がバグるのでやらないこと
  // zIndex: 1000,
}

export const KANBAN_GROUP_WIDTH = 300

export interface KanbanGroup<Group, Item> {
  id: string
  label: string
  group: Group
  items: Array<KanbanItem<Item>>
}

export function KanbanGroupComponent<G, I>({
  group,
  onDeleteGroup,
  onDeleteItem,
  addItem,
  addItemLabel,
  onEditGroup,
  onCheckItem,
}: {
  group: KanbanGroup<G, I>
  addItem: (groupId: string) => void
  addItemLabel: string
  onDeleteItem: (id: string) => void
  onDeleteGroup: (id: string) => void
  onEditGroup: (id: string, name: string) => void
  onCheckItem?: (id: string, checked: boolean) => void
}) {
  const { active, attributes, isDragging, listeners, over, setNodeRef, transition, transform } = useSortable({
    id: group.id,
    data: {
      type: 'container',
      children: group.items.map((x) => x.id),
    },
    // animateLayoutChanges,
  })
  // const isOverContainer = over
  //   ? (id === over.id && active?.data.current?.type !== 'container') ||
  //   items.includes(over.id)
  //   : false;
  const element = useHover((hovered) => (
    <div
      key={group.id}
      ref={setNodeRef}
      style={{
        transition,
        transform: CSS.Translate.toString(transform),
        width: KANBAN_GROUP_WIDTH,
        // ...defaultStyle,
        ...(isDragging ? draggingStyle : {}),
      }}
      className={`mx-4 flex flex-col divide-x-0 divide-y divide-solid divide-gray-200 rounded border border-solid border-gray-200 bg-white transition-shadow hover:shadow-md hover:shadow-slate-100`}
    >
      <div className="flex items-center justify-between px-2 py-1 align-middle">
        <div className="flex w-full items-center align-middle font-bold">
          <HolderOutlined className="cursor-grab pr-3" {...attributes} {...listeners} style={{ cursor: `grab` }} />
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
            editable={{
              onChange: (text) => {
                onEditGroup(group.id, text)
              },
            }}
          >
            {group.label}
          </Typography.Paragraph>
        </div>
        <Space
          style={{
            opacity: hovered ? 1 : 0,
            transition: `opacity 200ms ease`,
          }}
        >
          <Button
            type="text"
            icon={<DeleteOutlined />}
            onClick={() => {
              onDeleteGroup(group.id)
            }}
          />
        </Space>
      </div>
      <SortableContext items={group.items.map((x) => x.id)} strategy={rectSortingStrategy}>
        {group.items.map((item) => (
          <KanbanItemComponent
            item={item}
            key={item.id}
            onDelete={onDeleteItem}
            onCheck={
              onCheckItem === undefined
                ? undefined
                : (checked) => {
                    onCheckItem(item.id, checked)
                  }
            }
          />
        ))}
      </SortableContext>
      <div>
        <Button
          type="text"
          className="my-1 w-full text-center text-blue-500"
          icon={<PlusOutlined />}
          onClick={() => {
            addItem(group.id)
          }}
        >
          {addItemLabel}
        </Button>
      </div>
    </div>
  ))
  return element
}

// TODO: Monacoに型がつかないため、anyを許している

import { FunctionOutlined, PlayCircleOutlined } from '@ant-design/icons'
import Editor, { useMonaco } from '@monaco-editor/react'
import { abbreviateText, isNull, isSome } from '@salescore/buff-common'
import { Button, Col, Divider, Dropdown, Form, Menu, Row } from 'antd'
// @monaco-editor/reactがexportするtypeがなぜかanyになるので、自前で同等の型を付与したかったが、compileでエラーになるのでボツ
// import { editor, Range } from 'monaco-editor'
import { t } from 'i18next'
import { type CSSProperties, useEffect, useRef } from 'react'
import { format } from 'sql-formatter'

const POSTGRES_SQL_STATEMENTS = [`SELECT`, `FROM`, `JOIN`, `WHERE`, `AND`, `LIMIT`, `OFFSET`]
const POSTGRES_SQL_FUNCTIONS = [
  { label: `カウント`, value: `COUNT(*)` },
  { label: `合計`, value: `SUM()` },
  { label: `平均`, value: `AVG()` },
  { label: `割合`, value: `COUNT(CASE WHEN flag = 1 THEN 1 ELSE NULL END)::NUMERIC / NULLIF(COUNT(*), 0)` },
  { label: `最小`, value: `MIN()` },
  { label: `最大`, value: `MAX()` },
]

interface Suggest {
  label: string
  detail: string
  kind: string
  insertText: string
}

export function SqlEditor({ suggests, onExecute }: { suggests?: Suggest[]; onExecute?: () => Promise<void> }) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const monaco = useMonaco()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editorReference = useRef<any>(null)
  // const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null)
  // const propertyModal = useModal() // TODO

  useEffect(() => {
    if (isSome(monaco)) {
      const suggestions = [
        ...POSTGRES_SQL_STATEMENTS.map((command) => ({
          label: command,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: `${command} `,
        })),
        ...POSTGRES_SQL_FUNCTIONS.map((command) => ({
          label: command.value,
          detail: command.label,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: `${command.value} `,
        })),
        ...(suggests ?? []),
      ]
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      monaco.languages.registerCompletionItemProvider('sql', {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        provideCompletionItems(model: any, position: any) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
          const word = model.getWordUntilPosition(position)
          return {
            suggestions: suggestions
              .uniqueBy((x) => x.insertText)
              .map((x) => ({
                ...x,
                range: {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
                  startLineNumber: position.lineNumber,
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
                  endLineNumber: position.lineNumber,
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
                  startColumn: word.startColumn,
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
                  endColumn: word.endColumn,
                },
              })),
          }
        },
      })
    }
  }, [monaco])

  const insert = (text: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const editor = editorReference.current
    if (editor === null) {
      return
    }
    // // https://microsoft.github.io/monaco-editor/api/interfaces/monaco.editor.IEditor.html#getPosition
    // const position = editor.getPosition()
    // // https://microsoft.github.io/monaco-editor/api/interfaces/monaco.editor.ITextModel.html#getValue
    // const sql = editor.getModel()?.getValue()
    // if (isNull(sql) || isNull(position)) {
    //   return
    // }
    // form.setFieldsValue({ sql: format(sql, { language: 'postgresql' }) })

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    const selection = editor.getSelection()
    // https://qiita.com/lumis/items/311b8c39d61312957195#%E3%82%A8%E3%83%87%E3%82%A3%E3%82%BF%E3%81%A7%E7%B7%A8%E9%9B%86%E3%81%99%E3%82%8B
    // editor.executeEdits(``, [{ range: selection ?? new Range(1, 1, 1, 1), text }])
    if (isSome(selection)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      editor.executeEdits(``, [{ range: selection, text }])
    }
  }

  return (
    <div
      className="overflow-hidden"
      onKeyDown={async (e) => {
        if (onExecute !== undefined && e.code === 'Enter' && (e.metaKey || e.shiftKey)) {
          await onExecute()
          e.preventDefault()
          e.stopPropagation()
        }
      }}
    >
      <Row>
        <Col span={24}>
          <MonacoEditorNavigationHeader title={t(`SQLエディタ`)}>
            <>
              {/* <MonacoEditorNavigationButton icon={<UnorderedListOutlined />} onClick={propertyModal.showModal}>
                項目
              </MonacoEditorNavigationButton> */}
              <SqlFunctionDropdown
                onFinish={(text) => {
                  insert(text)
                }}
              >
                <MonacoEditorNavigationButton icon={<FunctionOutlined />}>{t(`関数`)}</MonacoEditorNavigationButton>
              </SqlFunctionDropdown>
              <MonacoEditorNavigationButton
                icon={<FunctionOutlined />}
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  const editor = editorReference.current
                  if (editor === null) {
                    return
                  }
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-type-assertion
                  const sql = editor.getModel()?.getValue() as string | null
                  if (isNull(sql)) {
                    return
                  }
                  const formatted = format(sql, { language: 'postgresql' })
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                  editor.getModel()?.setValue(formatted)
                }}
              >
                {t(`フォーマット`)}
              </MonacoEditorNavigationButton>
              {onExecute !== undefined && (
                <MonacoEditorNavigationButton
                  icon={<PlayCircleOutlined />}
                  onClick={async () => {
                    await onExecute()
                  }}
                >
                  {`${t(`実行`)}(Shift+Enter)`}
                </MonacoEditorNavigationButton>
              )}
            </>
          </MonacoEditorNavigationHeader>
          <Form.Item name="sql" rules={[{ required: true, message: t(`SQLを入力してください`) }]}>
            <Editor
              height={540}
              theme="vs-dark"
              defaultLanguage="sql"
              defaultValue={`COUNT(*)`}
              onMount={(editor, monaco) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                editorReference.current = editor
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      {/* TODO: 項目の部分もいい感じに親から渡せるようにする */}
      {/* <Modal
        open={propertyModal.isModalVisible}
        onCancel={propertyModal.hideModal}
        width={700}
        cancelText={'閉じる'}
        okButtonProps={{ style: { display: 'none' } }}
        title={<div></div>}
        style={{ top: '3%' }}
      >
        <NodePropertyPickerModal
          propertiesWithNode={propertiesWithNode}
          onFinish={(nodePropertyName) => {
            // onFinish(nodePropertyName)
            insert(`"${nodePropertyName.nodeName}"."${nodePropertyName.propertyName}"`)
            propertyModal.hideModal()
          }}
        />
      </Modal> */}
    </div>
  )
}

function SqlFunctionDropdown({ onFinish, children }: { onFinish: (text: string) => void; children: JSX.Element }) {
  const menu = (
    <Menu
      items={POSTGRES_SQL_FUNCTIONS.map(({ value, label }) => ({
        key: value,
        label: (
          <div>
            {label}
            <span className="ml-2 opacity-60">{abbreviateText(value, 15)}</span>
          </div>
        ),
        onClick: () => {
          onFinish(value)
        },
      }))}
    />
  )

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      {children}
    </Dropdown>
  )
}

const navigationStyle: CSSProperties = {
  backgroundColor: `#1E1E1E`,
  color: `white`,
}

function MonacoEditorNavigationButton({
  children,
  icon,
  onClick,
}: {
  children: string
  icon: JSX.Element
  onClick?: () => void
}) {
  return (
    <>
      <Button
        style={{
          ...navigationStyle,
        }}
        className="opacity-80 hover:bg-gray-500  hover:opacity-100"
        type="text"
        icon={icon}
        onClick={onClick}
      >
        {children}
      </Button>
      <Divider
        type="vertical"
        style={{
          borderColor: '#444',
          height: 30,
          top: 0,
        }}
      />
    </>
  )
}

function MonacoEditorNavigationHeader({ title, children }: { title: string; children: JSX.Element }) {
  return (
    <div
      className="flex items-center px-4 align-middle"
      style={{
        ...navigationStyle,
        // backgroundColor: `#050505`,
        borderBottom: `1px solid #444`,
      }}
    >
      <div className="opacity-80">{title}</div>
      <Divider
        type="vertical"
        style={{
          borderColor: '#444',
          height: 30,
          top: 0,
        }}
      />
      {children}
    </div>
  )
}

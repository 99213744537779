import { Modal } from 'antd'
import { t } from 'i18next'

import { JsonSyntaxHighlighter } from './JsonSyntaxHighlighter'

export function SyntaxHighlightedJsonModal({
  jsonStrings,
  modal,
}: {
  modal: {
    isModalVisible: boolean
    hideModal: () => void
  }
  jsonStrings: string[]
}) {
  return (
    <Modal
      visible={modal.isModalVisible}
      onCancel={modal.hideModal}
      width={'60%'}
      cancelText={t(`閉じる`)}
      okButtonProps={{ style: { display: 'none' } }}
      title={<div></div>}
      style={{ top: '3%' }}
    >
      {/* JSON.stringify()がundefinedを返しうるため、compact()する（型情報が間違っている） */}
      {jsonStrings.compact().map((json, index) => (
        <div key={`json-string-${index}`} className="mb-4">
          <JsonSyntaxHighlighter jsonString={json} />
        </div>
      ))}
    </Modal>
  )
}

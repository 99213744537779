import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter'
import { monokaiSublime } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { format } from 'sql-formatter'

export function SqlSyntaxHighlighter({
  sql,
  language,
  onClick,
}: {
  sql: string
  language?: 'postgresql' | 'sql'
  onClick?: () => void
}) {
  return (
    <div className={onClick === undefined ? `` : `cursor-pointer hover:opacity-90`} onClick={onClick}>
      <SyntaxHighlighter language="sql" style={monokaiSublime} customStyle={{ padding: 10 }} wrapLongLines={true}>
        {format(sql, {
          language: language ?? 'postgresql',
        })}
      </SyntaxHighlighter>
    </div>
  )
}

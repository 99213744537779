import { RightOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useState } from 'react'

export function ToggleText({ text, children }: { text: string; children: JSX.Element }) {
  const [visible, setVisible] = useState(false)
  return (
    <>
      <Button
        icon={<RightOutlined rotate={visible ? 90 : 0} />}
        onClick={() => {
          setVisible((x) => !x)
        }}
        type="text"
      >
        {text}
      </Button>
      {visible && <div>{children}</div>}
    </>
  )
}

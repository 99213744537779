import { isNull } from '@salescore/buff-common'

import { CORE_CONSTANT } from '../../../../../constant'
import type { ViewQueryMeasure, ViewQueryNode } from '../../../../../schemas/query'
import type {
  ViewConfigCustomKpiMeasure,
  ViewConfigKpiTimeSeries,
  ViewConfigPresetKpiMeasure,
} from '../../../../../schemas/view_config'
import { quote } from '../../../../query/executeViewQuery/generateSql'
import { flatNodes } from '../../../../query/executeViewQuery/util'
import type { CompileContext } from '../../../common'
import { generatePropertySql } from '../../sheet/compileSheetViewConfigFields'
import { generateFieldName } from '../../sheet/generateFieldName'
import {
  type CompileFilterArgument,
  compileMeasurePreset,
  generateAggregationArgument,
} from '../../sheet/measure/compileMeasure'
import { coalesce } from './generatePrioritizedSql'
import { quotedFieldPattern } from './quotedFieldPattern'
import { prioritizedTableName } from './utils'

export function generateWaterfallMeasure({
  config,
  queryNode,
  context,
}: {
  config: ViewConfigKpiTimeSeries
  queryNode: ViewQueryNode
  context: CompileContext
}): ViewQueryMeasure | undefined {
  const measure = config.kpiFragment?.measure
  if (isNull(measure)) {
    return
  }

  const sheet = config.kpiFragment?.sheet
  if (isNull(sheet) || sheet.type !== 'sheet') {
    return
  }

  const queryNodes = flatNodes(queryNode)
  const configNodes = flatNodes(sheet.tree)
  const presetCompileArgument = {
    queryNodes,
    configNodes,
    fields: [],
  }

  switch (measure.type) {
    case 'kpiPreset': {
      return generateKpiPresetMeasure({
        measure,
        argument: presetCompileArgument,
        context,
      })
    }
    case 'kpiCustom': {
      return generateKpiCustomMeasure({ measure })
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const x: never = measure
      throw new Error(`Unexpected measure type`)
    }
  }
}

function generateKpiPresetMeasure({
  measure,
  argument,
  context,
}: {
  measure: ViewConfigPresetKpiMeasure
  argument: CompileFilterArgument
  context: CompileContext
}): ViewQueryMeasure | undefined {
  const p1MeasureSql = generateMeasurePresetSqlForWaterfall({
    measure,
    argument,
    context,
    periodSuffix: CORE_CONSTANT.WATERFALL_FIRST_PERIOD_NODE_SUFFIX,
  })

  const p2MeasureSql = generateMeasurePresetSqlForWaterfall({
    measure,
    argument,
    context,
    periodSuffix: CORE_CONSTANT.WATERFALL_SECOND_PERIOD_NODE_SUFFIX,
  })

  if (isNull(p1MeasureSql) || isNull(p2MeasureSql)) {
    return
  }

  return {
    // 2つの期間の差分を集計する
    sql: `${p2MeasureSql} - ${p1MeasureSql}`,
    label: 'value',
    name: CORE_CONSTANT.KPI_PIVOT_VALUE_COLUMN_NAME,
  }
}

function generateMeasurePresetSqlForWaterfall({
  measure,
  argument,
  context,
  periodSuffix,
}: {
  measure: ViewConfigPresetKpiMeasure
  argument: CompileFilterArgument
  context: CompileContext
  periodSuffix: string
}): string | undefined {
  const property = {
    ...measure.property,
    nodeName: prioritizedTableName,
    propertyName: `${generateFieldName(measure.property)}_${periodSuffix}`,
  }
  const propertySql = generatePropertySql({ ...property })

  // COUNT系関数の場合は普通にcompileするとスナップショット比較オブジェクト自体のidがカウントされてしまうので、期間毎のidを個別にカウントするSQLを生成する
  if (measure.function === 'count') {
    return `COUNT(${generateAggregationArgument(propertySql, measure, argument, context)})::numeric`
  }
  if (measure.function === 'countUnique') {
    return `COUNT(DISTINCT ${generateAggregationArgument(propertySql, measure, argument, context)})::numeric`
  }
  // SUM関数の場合は片一方がNULLの場合でも2地点間の差分を出すために、NULLを0に変換する
  if (measure.function === 'sum') {
    const aggregationSql = generateAggregationArgument(propertySql, measure, argument, context)
    return `SUM(${coalesce(aggregationSql, '0')})::numeric`
  }

  // その他はcompileに任せる
  const compiled = compileMeasurePreset(measure, property, argument, context)
  return compiled?.sql
}

function generateKpiCustomMeasure({ measure }: { measure: ViewConfigCustomKpiMeasure }): ViewQueryMeasure | undefined {
  // SQL文字列の中からダブルクォートで囲まれたテーブル名・カラム名を抽出して置換する
  const p1MeasureSql = replaceQuotedTableAndColumnName(measure.sql, CORE_CONSTANT.WATERFALL_FIRST_PERIOD_NODE_SUFFIX)
  const p2MeasureSql = replaceQuotedTableAndColumnName(measure.sql, CORE_CONSTANT.WATERFALL_SECOND_PERIOD_NODE_SUFFIX)

  return {
    // 2つの期間の差分を集計する
    sql: `${p2MeasureSql} - ${p1MeasureSql}`,
    label: 'value',
    name: CORE_CONSTANT.KPI_PIVOT_VALUE_COLUMN_NAME,
  }
}

function replaceQuotedTableAndColumnName(sql: string, suffix: string): string {
  return sql.replaceAll(quotedFieldPattern, (match, nodeName: string | undefined, propertyName: string | undefined) => {
    if (isNull(nodeName) || isNull(propertyName)) {
      return match
    }

    const columnName = `${generateFieldName({
      nodeName: nodeName.replaceAll('"', ''),
      propertyName: propertyName.replaceAll('"', ''),
    })}_${suffix}`
    return `${quote(prioritizedTableName)}.${quote(columnName)}`
  })
}

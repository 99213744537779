// XXX: SwitchTransitionを使っている関係で、コンポーネントがunmountされるときもコンポーネントのレンダリングが走る。
//      このタイミングではコンポーネントに渡されるrouterの情報は次のページ用のrouterになっているため、普通にやるとエラーになる。
//      これを防ぐため、初回のレンダリング時のrouter情報をstateに保持する実装にしている。

import { type Dispatch, type SetStateAction, useState } from 'react'
import type { ZodSchema } from 'zod'

import { logger } from '../misc/logtail'

// TODO: valueのtypeが変わってエラーが起きないよう注意が必要。その際に自動でクリアするようなロジックが欲しいが可能か？

export const useStateWithLocalStorage = <T>(
  key: string,
  initialValue: T,
  schema?: ZodSchema<T>,
  options?: {
    forceInitialize: boolean
  },
): [T, Dispatch<SetStateAction<T>>, () => void] => {
  // eslint-disable-next-line @typescript-eslint/init-declarations
  let restoredValue: unknown
  try {
    const x = localStorage.getItem(key)
    restoredValue = x === null ? undefined : JSON.parse(x)
  } catch {
    // TODO: 握り潰す形で良いか？
  }

  // validationに通らなかったらデフォルトの値は使わない
  if (schema !== undefined) {
    const result = schema.safeParse(restoredValue)
    if (!result.success) {
      restoredValue = undefined
      localStorage.removeItem(key)
    }
  }

  // オプション池があればデフォルト値は使わない
  if (options?.forceInitialize === true) {
    restoredValue = undefined
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  const [value, setValue] = useState<T>((restoredValue ?? initialValue) as T)
  const setValueAndSetItem: Dispatch<SetStateAction<T>> = (setStateActionOrValue) => {
    // eslint-disable-next-line @typescript-eslint/init-declarations
    let newValue
    if (setStateActionOrValue instanceof Function) {
      setValue((oldValue) => {
        const newValue = setStateActionOrValue(oldValue)
        localStorage.setItem(key, JSON.stringify(newValue))
        return newValue
      })
    } else {
      newValue = setStateActionOrValue
      setValue(newValue)
      try {
        localStorage.setItem(key, JSON.stringify(newValue))
      } catch (error: unknown) {
        logger.debug(error)
      }
    }
  }

  const resetValueAndStorage = () => {
    setValue(initialValue)
    localStorage.setItem(key, JSON.stringify(initialValue))
  }

  return [value, setValueAndSetItem, resetValueAndStorage]
}

import { CloseOutlined, CopyOutlined, GitlabFilled } from '@ant-design/icons'
import { VERSION } from '@salescore/buff-common'
import { Button, Space, Tooltip, type TooltipProps } from 'antd'
import { type ReactNode, useState } from 'react'

const environmentColor = (): TooltipProps['color'] => {
  switch (process.env.APP_ENV) {
    case 'stg': {
      return 'blue'
    }
    case 'dev1':
    case 'dev2':
    case 'dev': {
      return 'red'
    }
    default: {
      return 'purple'
    }
  }
}
/**
 * 気の効いたカスタマイズがあったらやって下さい
 */
export const EnvironmentTooltip = (): ReactNode => {
  const [showTooltip, setShowTooltip] = useState(true)
  const hide = (): void => {
    setShowTooltip(false)
  }
  return process.env.APP_ENV === 'prod' ? (
    <></>
  ) : (
    <Tooltip
      title={
        <Space>
          <a className="text-white" onClick={hide}>
            <CloseOutlined />
          </a>

          <Tooltip
            title={
              <>
                <Button
                  onClick={() => {
                    void navigator.clipboard.writeText(process.env.CODEBUILD_RESOLVED_SOURCE_VERSION ?? '')
                  }}
                >
                  <CopyOutlined />
                  {process.env.CODEBUILD_RESOLVED_SOURCE_VERSION}
                </Button>
                <Button
                  onClick={() => {
                    void navigator.clipboard.writeText(VERSION)
                  }}
                >
                  <CopyOutlined />
                  {VERSION}
                </Button>
              </>
            }
          >
            <Space>
              <span>{process.env.APP_ENV}</span>

              {process.env.PR_NUMBER !== undefined && (
                <a
                  className="text-white"
                  href={`https://gitlab.com/salescore-inc/dev/salescore/-/merge_requests/${process.env.PR_NUMBER}/diffs?commit_id=${process.env.CODEBUILD_RESOLVED_SOURCE_VERSION}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <GitlabFilled />!{process.env.PR_NUMBER}
                </a>
              )}
            </Space>
          </Tooltip>
        </Space>
      }
      open={showTooltip}
      placement={'left'}
      color={environmentColor()}
    />
  )
}

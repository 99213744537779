import dayjs from 'dayjs'

import { detect } from './detect'
import type { Timeframe } from './timeframe'
//
// 2021-01のような文字列を解析して、startAt, endAtを返す関数
// 例えば、2021-01に対しては、startAt: 2021-01-01, endAt: 2021-01-31の値を返す（実際の値はdayjsのインスタンス）
// 2021-1Q, 2021-01-01Wのような四半期、週のような特殊な日付け意識に対応しているのが特徴
//
// eslint-disable-next-line complexity
export function parse(
  dateString: string,
  option?: { accountClosingMonth?: number; weekOffset?: number; timeframe?: Timeframe },
) {
  const detected = detect(dateString)
  if (detected === undefined) {
    return
  }

  switch (detected.timeframe) {
    case 'day': {
      const startAt = dayjs(dateString) // YYYY-MM-DDを期待
      return {
        startAt,
        endAt: startAt,
      }
    }
    case 'week': {
      const startAt = detected.date.startOf('week').add(option?.weekOffset ?? 0, 'day')
      return {
        startAt,
        endAt: startAt.add(6, 'day'),
      }
    }
    case 'month': {
      const startAt = dayjs(`${dateString}-01`) // YYYY-MMを期待
      return {
        startAt,
        endAt: startAt.endOf('month'),
      }
    }
    case 'fiscal_half': {
      const result = /(\d{4})-(上半期|下半期)/.exec(dateString)
      const year = result?.[1]
      const half = result?.[2]
      if (year === undefined || half === undefined) {
        return
      }
      const halfStartAt = dayjs(`${year}-01-01`).add((option?.accountClosingMonth ?? 0) % 12, 'month')
      const monthOffset = half === '上半期' ? 0 : 6
      const startAt = halfStartAt.add(monthOffset, 'month')
      return {
        startAt,
        endAt: startAt.add(5, 'month').endOf('month'),
      }
    }
    case 'fiscal_quarter': {
      const result = /(\d{4})-(\d)Q/.exec(dateString)
      const year = result?.[1]
      const quarter = result?.[2]
      if (year === undefined || quarter === undefined) {
        return
      }
      if (!['1', '2', '3', '4'].includes(quarter)) {
        return
      }
      // 決算月が3月のとき、3ヶ月加えて4月始まりが正しい
      // yearが2022、決算月が12月のとき、2022-1Qは1月。
      const quarterStartAt = dayjs(`${year}-01-01`).add((option?.accountClosingMonth ?? 0) % 12, 'month')
      const startAt = quarterStartAt.add((Number.parseInt(quarter) - 1) * 3, 'month')
      return {
        startAt,
        endAt: startAt.add(2, 'month').endOf('month'),
      }
    }
    case 'year': {
      const yearStartAt = dayjs(`${dateString}-01-01`) // YYYYを期待
      if (option?.timeframe === 'fiscal_year' && option.accountClosingMonth !== undefined) {
        const startAt = yearStartAt.add(option.accountClosingMonth % 12, 'month')
        return {
          startAt,
          endAt: startAt.add(11, 'month').endOf('month'),
        }
      }
      const startAt = yearStartAt
      return {
        startAt,
        endAt: startAt.endOf('year'),
      }
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const x: never = detected
    }
  }
}

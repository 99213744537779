import { type AlwaysFilter, stringFilterTypes } from '../../../../../../schemas/query/filterTypes'
import { NotImplementedError } from '../../../../../errors'
import { blankFilter, stringEqualFilter, stringIncludeFilter, stringInFilter, stringStartsWithFilter } from './filters'
import { validateFilterType } from './validateFilterType'

export const generateStringFilter = (filter: AlwaysFilter, shouldConsiderCase?: boolean): string => {
  const type = validateFilterType(filter.filter_type, stringFilterTypes)

  switch (type) {
    // NOTE: transform処理で空文字をNULL変換する処理をパフォーマンス問題で一時的に止めているため、
    // null/not_nullに関しては空文字も含めるようにnullFilterではなくblankFilterを使用している
    case 'null': {
      return blankFilter(filter, false)
    }
    case 'not_null': {
      return blankFilter(filter, true)
    }
    case 'blank': {
      return blankFilter(filter, false)
    }
    case 'present': {
      return blankFilter(filter, true)
    }
    case 'in': {
      return stringInFilter(filter, false)
    }
    case 'not_in': {
      return stringInFilter(filter, true)
    }
    case 'equal': {
      return stringEqualFilter(filter, false, shouldConsiderCase)
    }
    case 'not_equal': {
      return stringEqualFilter(filter, true, shouldConsiderCase)
    }
    case 'include': {
      return stringIncludeFilter(filter, false, shouldConsiderCase)
    }
    case 'not_include': {
      return stringIncludeFilter(filter, true, shouldConsiderCase)
    }
    case 'starts_with': {
      return stringStartsWithFilter(filter, false, shouldConsiderCase)
    }
    case 'not_starts_with': {
      return stringStartsWithFilter(filter, true, shouldConsiderCase)
    }
    case 'equal_me': {
      return stringEqualFilter(filter, false)
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const x: never = type
      throw new NotImplementedError()
    }
  }
}

import { z } from 'zod'

export const iconSchema = z.enum([
  'faClock',
  'faAddressCard',
  'faGift',
  'faAngry',
  'faBells',
  'faBuilding',
  'faCalendar',
  'faCalendarPlus',
  'faChartArea',
  'faChartBar',
  'faChartPie',
  'faCheck',
  'faCheckCircle',
  'faCheckSquare',
  'faChessKing',
  'faChessQueen',
  'faChild',
  'faCoins',
  'faComment',
  'faCommentAlt',
  'faComments',
  'faDatabase',
  'faDesktop',
  'faDoorClosed',
  'faDoorOpen',
  'faDownload',
  'faEnvelope',
  'faEnvelopeOpen',
  'faEnvelopeOpenDollar',
  'faEnvelopeOpenText',
  'faExclamationTriangle',
  'faFileContract',
  'faFlag',
  'faFlagCheckered',
  'faHandshake',
  'faHome',
  'faHotel',
  'faHouse',
  'faHouseLeave',
  'faHouseReturn',
  'faHouseUser',
  'faIdCard',
  'faIdCardAlt',
  'faIndustry',
  'faMailBulk',
  'faMailbox',
  'faMoneyBill',
  'faMoneyCheckEdit',
  'faPhone',
  'faPhonePlus',
  'faPhoneVolume',
  'faSitemap',
  'faStamp',
  'faStar',
  'faToolbox',
  'faTools',
  'faUser',
  'faUserEdit',
  'faUserFriends',
  'faUsers',
  'faUserChart',
  'faWalking',
  'faYenSign',
])

export type CoreIcon = z.infer<typeof iconSchema>

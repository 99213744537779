import { parseJsonIfValid } from '@salescore/buff-common'
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter'
import { monokaiSublime } from 'react-syntax-highlighter/dist/esm/styles/hljs'

export function JsonSyntaxHighlighter({ jsonString }: { jsonString: string }) {
  const parsedJson = parseJsonIfValid(jsonString)

  return (
    <SyntaxHighlighter language="json" style={monokaiSublime} customStyle={{ padding: 10 }} wrapLongLines={true}>
      {parsedJson === undefined ? jsonString : JSON.stringify(parsedJson, null, 2)}
    </SyntaxHighlighter>
  )
}

import { message } from 'antd'
import type { JointContent } from 'antd/es/message/interface'
import { useState } from 'react'

/**
 * @deprecated App.useApp() に移行しましょう https://ant.design/components/app
 */
export function useMessage() {
  const [visibleObject, setVisibleObject] = useState<Record<string, boolean>>({})
  function showMessage(content: JointContent, type: 'success' | 'error' | 'info' | 'warning') {
    const key = JSON.stringify(content)
    if (visibleObject[key] === true) {
      return
    }
    void message[type](content, undefined, () => {
      setVisibleObject((previous) => ({ ...previous, [key]: false }))
    })
    setVisibleObject((previous) => ({ ...previous, [key]: true }))
  }
  return {
    success: (content: JointContent) => {
      showMessage(content, 'success')
    },
    error: (content: JointContent) => {
      showMessage(content, 'error')
    },
    info: (content: JointContent) => {
      showMessage(content, 'info')
    },
    warning: (content: JointContent) => {
      showMessage(content, 'warning')
    },
    destroy: (key: React.Key) => {
      message.destroy(key)
    },
  }
}

import {
  BankOutlined,
  CloudServerOutlined,
  DashboardOutlined,
  FlagOutlined,
  LogoutOutlined,
  ToolOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { useSubscription } from '@apollo/client'
import { SubscribeTimestampDocument } from '@salescore/admin-client-api'
import { Divider, Layout, Menu, Tooltip } from 'antd'
import Link from 'next/link'
import { useRouter } from 'next/router'
import type { ReactNode } from 'react'

import { useRedirect } from '../../hooks/useRedirect'
import { Logo } from '../shared/Logo'

export const Sider = (): ReactNode => {
  const { data } = useSubscription(SubscribeTimestampDocument)
  const redirect = useRedirect()
  const router = useRouter()

  const items = [
    {
      label: '組織一覧',
      key: '/',
      icon: <BankOutlined />,
      onClick: () => {
        redirect('/')
      },
    },
    {
      label: 'スクリプト実行',
      key: '/tools',
      icon: <ToolOutlined />,
      onClick: () => {
        redirect('/tools')
      },
    },
    {
      label: 'キュー処理状況',
      key: '/workers',
      icon: <CloudServerOutlined />,
      onClick: () => {
        redirect('/workers')
      },
    },
    {
      label: 'ダッシュボード一覧',
      key: '/dashboards',
      icon: <DashboardOutlined />,
      onClick: () => {
        redirect('/dashboards')
      },
    },
    {
      label: '機能フラグ有効一覧',
      key: '/feature_flags',
      icon: <FlagOutlined />,
      onClick: () => {
        redirect('/feature_flags')
      },
    },
    {
      label: '管理ユーザー',
      key: '/admin_users',
      icon: <UserOutlined />,
      onClick: () => {
        redirect('/admin_users')
      },
    },
    {
      label: 'ログアウト',
      key: '/sign_out',
      icon: <LogoutOutlined />,
      onClick: () => {
        redirect('/sign_out')
      },
    },
  ]

  return (
    <Layout.Sider
      theme="light"
      className="dispaly-none-print fixed inset-y-0 left-0 h-screen overflow-auto  font-bold"
      // 以下、モバイル対応
      breakpoint="lg"
      collapsedWidth={0}
      zeroWidthTriggerStyle={{}}
      width={200}
    >
      <div className="px-4 pt-5 text-center">
        <Link href="/" legacyBehavior>
          <Logo />
        </Link>
      </div>
      <Divider />
      <Menu
        defaultSelectedKeys={[router.pathname]}
        selectedKeys={[router.pathname]}
        mode="inline"
        theme="light"
        items={items}
      />
      <Divider />
      <div className="flex justify-center">
        <Tooltip placement="top" title={'Subscriptionのデモです'}>
          {data?.timestamp ?? '-'}
        </Tooltip>
      </div>
    </Layout.Sider>
  )
}

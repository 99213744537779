import { isNull, isSome } from '@salescore/buff-common'

import { CORE_CONSTANT } from '../../../../../constant'
import type { ViewQueryList } from '../../../../../schemas/query'
import type { ViewConfigFilter, ViewConfigKpiTimeSeries, ViewConfigSheet } from '../../../../../schemas/view_config'
import { generateSql } from '../../../../query/executeViewQuery/generateSql'
import { flatNodes } from '../../../../query/executeViewQuery/util'
import { defaultCursor } from '../../../../query/executeViewQueryWithApplicationJoin'
import type { CompileContext } from '../../../common'
import { compileSheetViewConfig } from '../../sheet/compileSheetViewConfig'
import { generateSheetWithAdditionalFields } from './generateSheetWithAdditionalFields'

/**
 * スナップショットオブジェクトに対してSequenceオブジェクトをjoinしたSQLを生成する
 */
export function generateSequenceJoinedSnapshotSql({
  config,
  context,
  sequenceCreatedAt,
}: {
  config: ViewConfigKpiTimeSeries
  context: CompileContext
  sequenceCreatedAt: string
}): string | undefined {
  const sheetWithSequenceFilter = generateSheetWithSequenceFilter(config, sequenceCreatedAt)
  if (isNull(sheetWithSequenceFilter)) {
    return
  }

  // シートにはスナップショットオブジェクトとそのシーケンスオブジェクトのjoinが指定されていることが前提
  const result = compileSheetViewConfig(sheetWithSequenceFilter, context)
  if (isNull(result) || result.query.type !== 'list') {
    return
  }

  const subQuery: ViewQueryList = {
    ...result.query,
    extra: { asSubQuery: true },
  }
  return generateSql(subQuery, defaultCursor, {})
}

export function generateSheetWithSequenceFilter(
  config: ViewConfigKpiTimeSeries,
  sequenceCreatedAt: string,
): ViewConfigSheet | undefined {
  const sheet = config.kpiFragment?.sheet
  if (sheet?.type !== 'sheet' || isNull(sheet.tree)) {
    return
  }

  const treeNodes = flatNodes(sheet.tree)

  const sequenceFilterLeafs = treeNodes
    .filter(
      (node) =>
        // モデルがスナップショットモデルの時のみ作成日のフィルタを追加する
        node.modelName.startsWith(CORE_CONSTANT.SNAPSHOT_MODEL_PREFIX) &&
        !node.modelName.endsWith(CORE_CONSTANT.SNAPSHOT_SEQUENCE_TABLE_SUFFIX),
    )
    .map(
      (node): ViewConfigFilter => ({
        type: 'property',
        property: {
          nodeName: `${node.modelName}_${CORE_CONSTANT.SNAPSHOT_SEQUENCE_TABLE_SUFFIX}`,
          modelName: `${node.modelName}_${CORE_CONSTANT.SNAPSHOT_SEQUENCE_TABLE_SUFFIX}`,
          propertyName: 'created_at',
        },
        filterType: 'equal',
        filterDateTimeType: 'date',
        filterValue: sequenceCreatedAt,
      }),
    )
    .compact()

  const sheetWithAdditionalFields = generateSheetWithAdditionalFields({
    sheet,
    config,
    options: { shouldAddIdField: true },
  })

  const sheetWithSequenceFilter: ViewConfigSheet = {
    ...sheetWithAdditionalFields,
    filterTree: isSome(sheetWithAdditionalFields.filterTree)
      ? {
          ...sheetWithAdditionalFields.filterTree,
          leafs: [...sheetWithAdditionalFields.filterTree.leafs, ...sequenceFilterLeafs],
        }
      : {
          logicalOperator: 'and',
          leafs: [...sequenceFilterLeafs],
          children: [],
        },
  }

  return sheetWithSequenceFilter
}

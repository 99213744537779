import type { z } from 'zod'

import { viewUiDimensionCandidateSchema } from '../ui/ui'

export const viewConfigDimensionSchema = viewUiDimensionCandidateSchema
// export const viewConfigDimensionSchema = viewUiDimensionCandidateSchema.merge(z.object({
//   dateSpan: timeframeSchema.optional(), // 日付のフィールドをどうグルーピングするか
//   // numericSpan: z.number().array().optional(), // 数値のフィールドをどうグルーピングするか
// }))
export type ViewConfigDimension = z.infer<typeof viewConfigDimensionSchema>

//
// CORE言語のフォームで使うスキーマ。
// 本来はUI側の話になるが、ast <=> フォームASTを相互変換すると大変なので、
// fieldに直接このフォームのastを持たせており、ここで定義している。
//

import { z } from 'zod'

import { modelPropertySchema } from '../../schemas/model/modelProperty'

// viewConfigを参照すると循環参照になるので、一旦ここで定義
const nodePropertyNameSchema = z.object({
  nodeName: z.string(),
  modelName: z.string(),
  propertyName: z.string(),
})

const coreDslFormLiteralSchema = z.union([
  z.object({
    type: z.literal(`literal`),
    literalType: z.literal(`string`),
    value: z.string(),
  }),
  z.object({
    type: z.literal(`literal`),
    literalType: z.literal(`number`),
    value: z.number(),
  }),
  z.object({
    type: z.literal(`literal`),
    literalType: z.literal(`boolean`),
    value: z.boolean(),
  }),
  z.object({
    type: z.literal(`literal`),
    literalType: z.literal(`array`),
    value: z.string().array(),
  }),
  z.object({
    type: z.literal(`literal`),
    literalType: z.literal(`date`),
    dateTimeType: z.enum(['date', 'week', 'month', 'year', 'relative']).optional(),
    value: z.string(),
  }),
])
export type CoreDslFormLiteral = z.infer<typeof coreDslFormLiteralSchema>

const coreDslFormSnapshotVariableSchema = z.object({
  type: z.literal(`snapshotVariable`),
  dateSpan: z.enum(['day', 'week', 'month', 'year']),
  before: z.number(),
  property: modelPropertySchema,
  nodePropertyName: nodePropertyNameSchema,
})
export type CoreDslFormSnapshotVariable = z.infer<typeof coreDslFormSnapshotVariableSchema>

const coreDslFormRecordNodeVariableSchema = z.object({
  type: z.literal(`recordNodeVariable`),
  property: modelPropertySchema,
  nodePropertyName: nodePropertyNameSchema,
})

const coreDslFormTermSchema = z.union([
  coreDslFormLiteralSchema,
  coreDslFormSnapshotVariableSchema,
  coreDslFormRecordNodeVariableSchema,
])
export type CoreDslFormTerm = z.infer<typeof coreDslFormTermSchema>

const coreDslFormOperatorSchema = z.enum([
  '=',
  '!=',
  '>',
  '<',
  '>=',
  '<=',
  'null',
  'not_null',
  'present',
  'blank',
  'in',
  'not_in',
  'include',
  'not_include',
  'starts_with',
  'not_starts_with',
  'overlap',
  'not_overlap',
])
export type CoreDslFormOperator = z.infer<typeof coreDslFormOperatorSchema>

const coreDslFormLeafSchema = z.object({
  left: coreDslFormTermSchema,
  operator: coreDslFormOperatorSchema,
  right: coreDslFormTermSchema.optional(),
  extra: z
    .object({
      sortedValues: z.string().array().optional(), // 選択肢の「より上位」の判定のための値
      shouldConsiderCase: z.boolean().optional(), // 文字列の比較で大文字小文字を区別するかどうか。trueなら区別する
    })
    .optional(),
})
export type CoreDslFormLeaf = z.infer<typeof coreDslFormLeafSchema>
export type CoreDslFormLeafPartial = Partial<CoreDslFormLeaf>

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type CoreDslFormNodeState = {
  logicalOperator: 'and' | 'or'
  leafs: CoreDslFormLeaf[]
  children: CoreDslFormNodeState[]
}

export const coreDslFormNodeSchema: z.ZodSchema<CoreDslFormNodeState> = z.lazy(() =>
  z.object({
    logicalOperator: z.enum(['and', 'or']),
    leafs: coreDslFormLeafSchema.array(),
    children: coreDslFormNodeSchema.array(),
  }),
)

export interface CoreDslFormNodeStatePartial {
  logicalOperator: 'and' | 'or'
  leafs: CoreDslFormLeafPartial[]
  children: CoreDslFormNodeStatePartial[]
}

import { Spin } from 'antd'
import Layout from 'antd/es/layout/layout'

export const Loading = ({ message }: { message?: string }) => (
  <Layout className="h-full">
    <div className="flex size-full flex-col items-center justify-center text-center align-middle text-xs text-gray-500">
      <Spin size="large" />
      <div className="blink-slow mt-4">{message}</div>
    </div>
  </Layout>
)

export function flatNodesWithParents<T extends { children?: T[] }>(node: T | undefined): Array<T & { parents: T[] }> {
  if (node === undefined) {
    return []
  }
  const nodesWithParent = flatNodesWithParent(node)
  return nodesWithParent.map((node) => ({ ...node, parents: dfsParent(node) }))
}

function dfsParent<T extends { children?: T[]; parent?: T }>(node: T): T[] {
  if (node.parent === undefined) {
    return []
  }
  return [node.parent, ...dfsParent(node.parent)]
}

// オブジェクト構造は崩れるので注意
function flatNodesWithParent<T extends { children?: T[] }>(node: T | undefined, parent?: T): Array<T & { parent?: T }> {
  if (node === undefined) {
    return []
  }
  const nodeWithParent = {
    ...node,
    parent,
  }

  return [
    nodeWithParent,
    ...(node.children?.flatMap((childNode) => flatNodesWithParent(childNode, nodeWithParent)) ?? []),
  ]
}

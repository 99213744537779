import { z } from 'zod'

import { viewQueryFilterNodeSchema } from './filter'
import { viewQuerySorterSchema } from './sorter'
import { viewQueryNodeSchema } from './tree'

export const viewQueryMeasureSchema = z.object({
  name: z.string(),
  label: z.string(),
  sql: z.string(),
})
export type ViewQueryMeasure = z.infer<typeof viewQueryMeasureSchema>

export const viewQueryDimensionFieldSchema = z.object({
  type: z.literal(`field`),
  name: z.string(),
  sql: z.string(),
  fieldSql: z.string(),
  labelSql: z.string().optional(),
  isScalar: z.boolean().optional(),
  fixedValues: z.string().array().optional(), // クエリの結果に依存せず、常に一定のキーを使うケース。KPIと選択肢のみで利用予定。
  sortedValues: z.string().array().optional(), // 並び替えの順序(UIで指定)
})
export type ViewQueryDimensionField = z.infer<typeof viewQueryDimensionFieldSchema>

const viewQueryPivotSchema = z.object({
  rows: viewQueryDimensionFieldSchema.array(),
  columns: viewQueryDimensionFieldSchema.array(),
  dimensionsWithoutRollup: viewQueryDimensionFieldSchema.array().optional(),
})
export type ViewQueryPivot = z.infer<typeof viewQueryPivotSchema>

export const viewQueryAggregationSchema = z.object({
  type: z.literal('aggregation'),
  tree: viewQueryNodeSchema,
  measures: viewQueryMeasureSchema.array(),
  filterTree: viewQueryFilterNodeSchema,
  sorters: viewQuerySorterSchema.array(),
  // NOTE: もともとpivotではなくdimensionsとして定義していたが、
  //        recordNodeの組み立て時にpivotであることを前提に組み立てを行うので、もはやpivotとして定義する。
  pivot: viewQueryPivotSchema.optional(),
})
export type ViewQueryAggregation = z.infer<typeof viewQueryAggregationSchema>

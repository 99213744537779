import { z } from 'zod'

export const propertyTypeSchema = z.enum([
  'array',
  'boolean',
  'integer',
  // 'bigint',
  'numeric',
  // 'null',
  'object',
  'string',
  'date',
  'datetime',
  'time',
])
export type PropertyType = z.infer<typeof propertyTypeSchema>

export const propertyMetaSchema = z.enum([
  'id',
  'select',
  'multi_select',
  'text',
  'relation',
  'phone_number',
  'address',
  'currency',
  'url',
  'email',
  'percent',
  'polymorphic_type',
  'name',
  'record_url',
  'html',
])
export type PropertyMeta = z.infer<typeof propertyMetaSchema>

export const selectOptionSchema = z.object({
  label: z.string(),
  value: z.union([z.string(), z.number()]),
  color: z.string().optional().nullable(),
  icon: z.string().optional().nullable(),
  // ruleにはExpressionNodeWithDeprecated相当を指定したいが、この型はzodでは表現できないため、zodのschemaが存在しない
  // 代わりにunknownを使って誤魔化していたが、こうすると今度はrecoilのレイヤーで使えなくなる（unknownはserializableではないため）
  // よって、上記のようにtoJSON関数をわざわざ無理やりはやして、serializableにした上で使っている
  rule: z.unknown().optional().nullable(),
})
export type SelectOption = z.infer<typeof selectOptionSchema>
const propertyNameWithStreamNameSchema = z.object({
  streamName: z.string(),
  propertyName: z.string(),
})
type propertyNameWithStreamName = z.infer<typeof propertyNameWithStreamNameSchema>

// ここで指定することでfunctionをassertionしようとしたが、無理だった
// export type ValidationRule = {
//   function: ExpressionNodeWithDeprecated
//   message: string
//   feedbackProperties: propertyNameWithStreamName[]
// }
export const validationRuleSchema = z.object({
  function: z.unknown(),
  message: z.string(),
  feedbackProperties: propertyNameWithStreamNameSchema.array(),
})
export type ValidationRule = z.infer<typeof validationRuleSchema>

const propertySourceSchema = z.object({
  name: z.string(),
  meta: z
    .union([
      z.object({
        type: z.union([z.string(), z.null()]).optional(),
        meta: z.union([z.string(), z.null()]).optional(),
        referenceTo: z.union([z.array(z.string()), z.null()]).optional(),
      }),
      z.null(),
    ])
    .optional(),
})

const propertyDestinationSchema = z.object({
  name: z.string(),
  type: propertyTypeSchema,
  sql: z.string(),
})

const propertyMetadataSchema = z.object({
  label: z.string(),
  description: z.string().optional(),
  meta: propertyMetaSchema.optional().nullable(),
  typeForMigration: z
    .union([z.literal('selectTypeWithRawValue'), z.literal('selectTypeWithConvertedValue'), z.null()])
    .optional(),
  referenceTo: z.union([z.array(z.string()), z.null()]).optional(),
  selectOptions: z.union([z.array(selectOptionSchema), z.null()]).optional(),
  customized: z.boolean().optional(),
  // NOTE: validationはpropertyにもstreamにも記述できる。validation時はどちらのvalidationも全て実行される。
  //       システム的には使い分けには意味がなく、可読性のためのみで使い分ける。（単一のpropertyに対するvalidationはproperty側に、複数のproerptyのときにstream側）
  validations: validationRuleSchema.array().optional(),
  creatable: z.boolean().optional().nullable(), // デフォルトでtrue
  updatable: z.boolean().optional().nullable(), // デフォルトでtrue
  trackable: z.boolean().nullish(), // 変更履歴追跡ができるかどうか
})

export const propertySchema = z.object({
  name: z.string(),
  source: z.union([propertySourceSchema, z.null()]).optional(),
  destination: propertyDestinationSchema,
  meta: propertyMetadataSchema,
})

const referenceToSchema = z.object({
  modelName: z.string(),
  key: z.string().optional(), // デフォルトでid
  // TODO: ここでこのrelationを指定するべきかかなり悩ましい。いったん、カスタム項目のときしか使わない想定。
  // また、polymorphicなときにそれぞれの参照先ごとにこのrelationが異なる形は想定していない。compileSheetViewConfigTree参照
  relation: z.enum(['one_to_one', 'many_to_one']).optional(),
})
export type ReferenceTo = z.infer<typeof referenceToSchema>

export const modelPropertySchema = z.object({
  name: z.string(),
  label: z.string(),
  description: z.string().optional(), // labelよりも長めな説明
  type: propertyTypeSchema,
  meta: propertyMetaSchema.optional().nullable(),
  // TODO: 必要?
  // typeForMigration: z
  //   .union([z.literal('selectTypeWithRawValue'), z.literal('selectTypeWithConvertedValue'), z.null()])
  //   .optional(),
  referenceTo: referenceToSchema.array().optional(),
  selectOptions: selectOptionSchema.array().optional().nullable(),
  // NOTE: validationはpropertyにもstreamにも記述できる。validation時はどちらのvalidationも全て実行される。
  //       システム的には使い分けには意味がなく、可読性のためのみで使い分ける。（単一のpropertyに対するvalidationはproperty側に、複数のproerptyのときにstream側）
  validations: validationRuleSchema.array().optional(),
  creatable: z.boolean().optional().nullable(), // デフォルトでtrue
  updatable: z.boolean().optional().nullable(), // デフォルトでtrue
  write: z
    .object({
      sourcePropertyName: z.string(),
    })
    .optional()
    .nullable(),
  trackable: z.boolean().nullish(), // 変更履歴追跡ができるかどうか
})

export type ModelProperty = z.infer<typeof modelPropertySchema>
